import { MachineCounterTracking, MachineCounterTrackingSource } from "@farmact/model/src/model/MachineCounterTracking";
import { Order } from "@farmact/model/src/model/Order";
import { getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { v4 } from "uuid";
import { Firebase } from "../../../../../firebase";
import { recordError } from "../../../../../util/recordError";
import { useOrganizationContext } from "../../../../organization/context/OrganizationContext";
import { MachineCounterTrigger } from "../../MachineCounters/MachineCounterTrigger";
import { MachineCounterValue } from "../../MachineCounters/useMachineCounterGroups";

interface UseInitializeStopOrderContextMachineCountersParams {
    enabled: boolean;
    setMachineCounterValues: (machineCounterValues: MachineCounterValue[]) => void;
}

interface UseInitializeStopOrderContextMachineCountersReturn {
    loading: boolean;
    error: InitializeStopOrderContextMachineCountersError | null;
}

export function useInitializeStopOrderContextMachineCounters(
    order: Order | null,
    params: UseInitializeStopOrderContextMachineCountersParams
): UseInitializeStopOrderContextMachineCountersReturn {
    const { enabled, setMachineCounterValues } = params;

    const { machines } = useOrganizationContext();

    const [previousEnabled, setPreviousEnabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<InitializeStopOrderContextMachineCountersError | null>(null);

    if (previousEnabled && !params.enabled) {
        setLoading(false);
        setError(null);
        setPreviousEnabled(false);
    }
    if (!previousEnabled && params.enabled) {
        setLoading(true);
        setPreviousEnabled(true);
    }

    useEffect(() => {
        if (!enabled) {
            return;
        }
        if (!order) {
            return;
        }

        const createMachineCounterValues = async (): Promise<MachineCounterValue[]> => {
            const orderMachineCounterTrackings = await loadOrderMachineCounterTrackings(order.id);

            const machineCounterValues: MachineCounterValue[] = [];

            const incompleteTrackings = orderMachineCounterTrackings.filter(tracking => {
                return tracking.endValue === null || tracking.endDate === null;
            });
            const incompleteTrackingMachineIds = incompleteTrackings.map(tracking => {
                return tracking.source.machineId;
            });
            const incompleteMachines = machines.filter(machine => {
                return incompleteTrackingMachineIds.includes(machine.id);
            });

            for (const machine of incompleteMachines) {
                const incompleteTrackingsByMachine = incompleteTrackings.filter(tracking => {
                    return tracking.source.machineId === machine.id;
                });

                for (const machineCounterTracking of incompleteTrackingsByMachine) {
                    machineCounterValues.push({
                        id: v4(),
                        source: {
                            type: MachineCounterTrackingSource.MACHINE,
                            machineId: machine.id,
                        },
                        type: machineCounterTracking.type,
                        value: null,
                        previous: machineCounterTracking.startValue,
                        trigger: MachineCounterTrigger.STOP,
                    });
                }
            }

            const incompleteTrackingsWithoutMachine = incompleteTrackings.filter(
                tracking => tracking.source.type === MachineCounterTrackingSource.PRICE_BLOCK
            );
            for (const machineCounterTracking of incompleteTrackingsWithoutMachine) {
                machineCounterValues.push({
                    id: v4(),
                    source: {
                        type: MachineCounterTrackingSource.PRICE_BLOCK,
                        machineId: null,
                    },
                    type: machineCounterTracking.type,
                    value: null,
                    previous: machineCounterTracking.startValue,
                    trigger: MachineCounterTrigger.STOP,
                });
            }

            return machineCounterValues;
        };

        createMachineCounterValues()
            .then(machineCounterValues => {
                setMachineCounterValues(machineCounterValues);
            })
            .catch(error => {
                recordError("Failed to create machine counter values to stop order", {
                    order,
                    error,
                });
                setError(InitializeStopOrderContextMachineCountersError.UNKNOWN);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [enabled, setMachineCounterValues, order, machines]);

    return {
        loading,
        error,
    };
}

export enum InitializeStopOrderContextMachineCountersError {
    UNKNOWN,
}

async function loadOrderMachineCounterTrackings(orderId: Order["id"]): Promise<MachineCounterTracking[]> {
    const snapshots = await getDocs(Firebase.instance().getAllMachineCounterTrackingsByOrder(orderId));
    return snapshots.docs.map(doc => doc.data());
}
