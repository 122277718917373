import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonBackButton, IonButton, IonButtons, IonHeader, IonTitle, IonToolbar } from "@ionic/react";
import { useLocation } from "react-router";
import { getBackLinkForDetailPage, getPageTitleFromRoute, isRouteTopLevel } from "../../../util/headerHelpers";
import { HelpButton } from "./help/HelpButton/HelpButton";
import { UpdateInfoButton } from "./updateInfo/UpdateInfoButton/UpdateInfoButton";
import { routesWithoutMenu } from "../utils/routesWithoutMenu";

interface IStandardHeaderProps {
    onMenuToggleClicked: () => void;
}

export const StandardHeader = (props: IStandardHeaderProps) => {
    const reactRouterLocation = useLocation();

    return (
        <>
            {!routesWithoutMenu.includes(reactRouterLocation.pathname) && (
                <IonHeader>
                    <IonToolbar color="primary">
                        <IonButtons slot="start">
                            {isRouteTopLevel(reactRouterLocation.pathname) ? (
                                <IonButton style={{ marginLeft: "10px" }} onClick={props.onMenuToggleClicked}>
                                    <FontAwesomeIcon icon={faBars} size="lg" />
                                </IonButton>
                            ) : (
                                <IonBackButton
                                    text="Zurück"
                                    defaultHref={getBackLinkForDetailPage(reactRouterLocation.pathname)}
                                />
                            )}
                        </IonButtons>
                        <IonTitle>{getPageTitleFromRoute(reactRouterLocation.pathname)}</IonTitle>
                        <IonButtons slot="end">
                            <HelpButton />
                            <UpdateInfoButton />
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
            )}
        </>
    );
};
