import { lazy } from "react";
import { DefaultPage } from "../../structure/Page/DefaultPage/DefaultPage";
import { withAuthorization } from "../../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../../authentication/authorizations";
import { Role } from "@farmact/model/src/model/Role";

const CompanyPageContent = lazy(() => import("./CompanyPageContent"));

function CompanyPageInternal() {
    return (
        <DefaultPage>
            <CompanyPageContent />
        </DefaultPage>
    );
}

export const CompanyPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN))(CompanyPageInternal);
