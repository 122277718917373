import { withAuthorization } from "../../../../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../../../../authentication/authorizations";
import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { DefaultPage } from "../../../../structure/Page/DefaultPage/DefaultPage";

const BillReminderDetailsPageContent = lazy(() => import("./BillReminderDetailsPageContent"));

function BillReminderDetailsPageInternal() {
    return (
        <DefaultPage>
            <BillReminderDetailsPageContent />
        </DefaultPage>
    );
}

export const BillReminderDetailsPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN))(
    BillReminderDetailsPageInternal
);
