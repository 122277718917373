import { IonButton, IonButtons, IonContent, IonFooter, IonToolbar } from "@ionic/react";
import React from "react";
import { FarmActModal } from "../../../../FarmActModal/FarmActModal";
import { useControlWorkday } from "../../../../Home/WorkdayWidget/useControlWorkday";
import { InternalWorkNoteModal } from "../../../../internalWork/InternalWorkGrid/InternalWorkCard/InternalWorkNoteModal/InternalWorkNoteModal";
import { StopOrderModal } from "../../../../orders/Tasks/StopOrderModal/StopOrderModal";
import { StandardModalHeader } from "../../../StandardModalHeader/StandardModalHeader";
import "./askToStopWorkdayModal.scss";

interface IAskToStopWorkdayModalProps {
    isOpen: boolean;
    onDismiss: () => void;
    handleLogout: () => void;
    onClick?: React.MouseEventHandler<HTMLIonModalElement>;
}

export function AskToStopWorkdayModal(props: IAskToStopWorkdayModalProps) {
    const controlWorkday = useControlWorkday({ waitForDatabaseCommit: true });

    const handleStop = async () => {
        await controlWorkday.stop();
        props.handleLogout();
        props.onDismiss();
    };

    return (
        <>
            <FarmActModal className="ask-to-stop-workday-modal" isOpen={props.isOpen} onDidDismiss={props.onDismiss}>
                <StandardModalHeader title="Arbeitstag beenden" onCloseClick={props.onDismiss} />

                <IonContent>
                    <p className="ask-to-stop-workday-modal__text">
                        Dein Arbeitstag läuft noch, möchtest du ihn jetzt beenden?
                    </p>
                </IonContent>

                <IonFooter>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton color="secondary" onClick={handleStop}>
                                Beenden und Abmelden
                            </IonButton>
                        </IonButtons>

                        <IonButtons slot="end">
                            <IonButton
                                color="primary"
                                onClick={() => {
                                    props.handleLogout();
                                    props.onDismiss();
                                }}>
                                Abmelden
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonFooter>
            </FarmActModal>

            <StopOrderModal {...controlWorkday.stopOrderModalProps} />
            <InternalWorkNoteModal {...controlWorkday.stopInternalWorkModalProps} />
        </>
    );
}
