import { withAuthorization } from "../authentication/Session/withAuthorization";
import { loggedIn } from "../authentication/authorizations";
import { lazy } from "react";
import { DefaultPage } from "../structure/Page/DefaultPage/DefaultPage";

const NotFoundPageContent = lazy(() => import("./NotFoundPageContent"));

function NotFoundPageInternal() {
    return (
        <DefaultPage>
            <NotFoundPageContent />
        </DefaultPage>
    );
}

export const NotFoundPage = withAuthorization(loggedIn)(NotFoundPageInternal);
