import { lazy } from "react";
import { DefaultPage } from "../structure/Page/DefaultPage/DefaultPage";

const MapStructuresSharingPageContent = lazy(() => import("./MapStructuresSharingPageContent"));

export function MapStructuresSharingPage() {
    return (
        <DefaultPage>
            <MapStructuresSharingPageContent />
        </DefaultPage>
    );
}
