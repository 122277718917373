import { LiquidMixture } from "@farmact/model/src/model/LiquidMixture";
import { Resource } from "@farmact/model/src/model/Resource";
import { ResourceVariantIdentifier } from "@farmact/model/src/model/ResourcePrice";
import { ValidationError } from "../../../../util/customHooks/validation/useValidation";
import { SingleSelect } from "../../SingleSelect/SingleSelect";
import { SelectVariantModal } from "../SelectVariantModal/SelectVariantModal";
import { useResourceSelectItems } from "../utils/useResourceSelectItems";
import { useResourceSelection } from "../utils/useResourceSelection";
import { constructResourceVariantIdentifier } from "../utils/utils";

interface ISingleResourceInputProps {
    selectedResource: ResourceVariantIdentifier | null;
    availableResources: Resource[];
    availableLiquidMixtures?: LiquidMixture[];
    onChange: (updatedResource: ResourceVariantIdentifier | null) => void;
    labelMinWidth?: number;
    label?: string;
    error?: ValidationError;
    allowSelectionOfBaseVariant?: boolean;
    allowCreatingLiquidMixture?: boolean;
    clearable?: boolean;
    placeholder?: string;

    className?: string;
}

export const SingleResourceInput = (props: ISingleResourceInputProps) => {
    const {
        modal: selectVariantModal,
        dismissModal: dismissSelectVariantModal,
        onChange: handleSelectionChange,
    } = useResourceSelection({
        availableResources: props.availableResources,
        availableLiquidMixtures: props.availableLiquidMixtures ?? [],
        onResourceSelect: props.onChange,
    });

    const resourceOptionsToDisplay = useResourceSelectItems({
        availableResources: props.availableResources,
        selectedResource: props.selectedResource,
        allowCreatingLiquidMixture: props.allowCreatingLiquidMixture,
        availableLiquidMixtures: props.availableLiquidMixtures,
    });

    const currentSelectedOption = props.selectedResource
        ? constructResourceVariantIdentifier(props.selectedResource.resourceId, props.selectedResource.variantId)
        : undefined;

    return (
        <>
            <SingleSelect
                data={resourceOptionsToDisplay}
                value={currentSelectedOption}
                label={props.label}
                onChange={value => handleSelectionChange(value ?? undefined)}
                error={props.error}
                clearable={props.clearable}
                placeholder={props.placeholder ?? "Artikel"}
                className={props.className}
            />
            <SelectVariantModal
                isOpen={selectVariantModal.show}
                onDismiss={dismissSelectVariantModal}
                resource={selectVariantModal.resource}
                onVariantSelect={props.onChange}
                allowSelectionOfBaseVariant={props.allowSelectionOfBaseVariant}
            />
        </>
    );
};
