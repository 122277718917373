import { DriverQueryResourceOnly } from "@farmact/model/src/model/Order";
import { Resource } from "@farmact/model/src/model/Resource";
import { ResourcePrice, ResourcePriceCalculationType } from "@farmact/model/src/model/ResourcePrice";
import { DriverQueryResourceRestriction, ResourceRestrictionType } from "@farmact/model/src/model/services/DriverQuery";
import { useMemo } from "react";
import { isVariantsResourcePrice } from "../../../../../util/resourceUtils";
import { useOrganizationContext } from "../../../../organization/context/OrganizationContext";

export function useDriverQueryResourceOnlyResources(driverQuery: DriverQueryResourceOnly): Resource[] {
    const { resources } = useOrganizationContext();

    const restriction = driverQuery.restriction;
    return useMemo(() => {
        if (!restriction) {
            return resources;
        }

        switch (restriction.type) {
            case ResourceRestrictionType.CATEGORY:
                return resources.filter(resource => resource.category === restriction.category);
            case ResourceRestrictionType.RESOURCE: {
                const resource = resources.find(resource => resource.id === restriction.resourceId);
                if (!resource) {
                    return resources;
                }

                const variantForRestriction = findVariantForRestriction(resource.price.variants ?? [], restriction);
                if (!variantForRestriction) {
                    return [resource];
                }

                return [new Resource({ ...resource, price: { ...resource.price, variants: [variantForRestriction] } })];
            }
        }
    }, [restriction, resources]);
}

function findVariantForRestriction(
    variants: ResourcePrice<ResourcePriceCalculationType>[],
    restriction: Extract<DriverQueryResourceRestriction, { type: ResourceRestrictionType.RESOURCE }>
): ResourcePrice<ResourcePriceCalculationType> | undefined {
    for (const variant of variants) {
        const found = variant.id === restriction.resourceVariantId;
        if (found) {
            return variant;
        }

        if (!isVariantsResourcePrice(variant)) {
            continue;
        }

        const childVariant = findVariantForRestriction(variant.variants, restriction);
        if (childVariant) {
            return variant;
        }
    }
}
