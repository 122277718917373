import React from "react";

export interface DeviceSettings {
    trackBackgroundGeolocation?: boolean;
}

interface IDeviceSettingsContext {
    deviceSettings: Partial<DeviceSettings>;
    setDeviceSettings: React.Dispatch<React.SetStateAction<DeviceSettings>>;
}

export const DeviceSettingsContext: React.Context<IDeviceSettingsContext> = React.createContext<IDeviceSettingsContext>(
    {
        deviceSettings: {},
        setDeviceSettings: () => { },
    }
);
