import { useContext } from "react";
import * as Routes from "@farmact/model/src/constants/farmActAppRoutes";
import { Variant } from "@farmact/model/src/model/CompanyMeta";
import { Role } from "@farmact/model/src/model/Role";
import { useRole } from "../../../../../../../util/customHooks/useRole";
import AppCompanyContext from "../../../../../../authentication/AppCompanyContext";
import { SubMenu } from "../SubMenu/SubMenu";
import { SubMenuItem } from "../SubMenuItem/SubMenuItem";
import "./organizationMenu.scss";

interface IOrganizationMenuProps {
    isOpen?: boolean;
    isInSplitPane: boolean;
}

export const OrganizationMenu = (props: IOrganizationMenuProps) => {
    const { companyMeta } = useContext(AppCompanyContext);
    const { only } = useRole();

    const isAdminOrOwner = only(Role.OWNER, Role.ADMIN);
    const isAtLeastPlanner = only(Role.OWNER, Role.ADMIN, Role.PLANNER);

    return (
        <SubMenu isOpen={props.isOpen} cssClass="organization-menu">
            {isAdminOrOwner && (
                <SubMenuItem
                    isInSplitPane={props.isInSplitPane}
                    name="Mitarbeiter"
                    route={Routes.ORGANIZATION_EMPLOYEES}
                />
            )}
            <SubMenuItem isInSplitPane={props.isInSplitPane} name="Maschinen" route={Routes.ORGANIZATION_MACHINES} />
            {companyMeta?.variant === Variant.LOGISTICS && (
                <SubMenuItem
                    isInSplitPane={props.isInSplitPane}
                    name="Be-/Entladestellen"
                    route={Routes.ORGANIZATION_LOADING_POINTS}
                />
            )}
            <SubMenuItem isInSplitPane={props.isInSplitPane} name="Kunden" route={Routes.ORGANIZATION_CUSTOMERS} />
            {isAtLeastPlanner && (
                <SubMenuItem
                    isInSplitPane={props.isInSplitPane}
                    name="Dienstleistungen"
                    route={Routes.ORGANIZATION_SERVICES}
                />
            )}
            <SubMenuItem isInSplitPane={props.isInSplitPane} name="Artikel" route={Routes.ORGANIZATION_RESOURCES} />
            {isAtLeastPlanner && (
                <SubMenuItem
                    isInSplitPane={props.isInSplitPane}
                    name="Tankstellen"
                    route={Routes.ORGANIZATION_GASSTATIONS}
                />
            )}
            {isAdminOrOwner && (
                <SubMenuItem
                    isInSplitPane={props.isInSplitPane}
                    name="Mein Betrieb"
                    route={Routes.ORGANIZATION_COMPANY}
                />
            )}
        </SubMenu>
    );
};
