import { IonItemDivider } from "@ionic/react";
import React from "react";
import { MachineCounterValueError } from "./MachineCounterValueError";
import { MachineCountersGroup, MachineCounterValue } from "../useMachineCounterGroups";
import { MachineCounterInputItem } from "./MachineCounterInputItem/MachineCounterInputItem";
import { FaItemGroup } from "../../../../FaItemGroup/FaItemGroup";

interface IMachineCounterQueriesProps {
    groups: MachineCountersGroup[];
    onChange: (valueId: string, updatedValue: number | null) => void;
    errors: MachineCounterValueError[];
}

export const MachineCounterQueries = (props: IMachineCounterQueriesProps) => {
    const getError = (forValue: MachineCounterValue["id"]): MachineCounterValueError | null => {
        return props.errors.find(error => error.valueId === forValue) ?? null;
    };

    return (
        <>
            {props.groups.map(group => (
                <React.Fragment key={group.id}>
                    <IonItemDivider color="dark">{group.groupName}</IonItemDivider>
                    <FaItemGroup>
                        {group.values.map(value => (
                            <MachineCounterInputItem
                                key={value.id}
                                onChange={props.onChange}
                                value={value}
                                error={getError(value.id) ?? undefined}
                            />
                        ))}
                    </FaItemGroup>
                </React.Fragment>
            ))}
        </>
    );
};
