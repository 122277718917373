import { useContext } from "react";
import { Redirect } from "react-router-dom";
import * as ROUTES from "@farmact/model/src/constants/farmActAppRoutes";
import { UserContext } from "./UserContext";
import AppCompanyContext from "../AppCompanyContext";
import { Firebase } from "../../../firebase";
import { useRole } from "../../../util/customHooks/useRole";
import { Role } from "@farmact/model/src/model/Role";
import { User } from "firebase/auth";

export type AuthorizationCheck = (authUser: User, role: Role) => boolean;

export function withAuthorization(condition: AuthorizationCheck) {
    return (Component: any) => {
        return (props: any) => {
            const authUser = useContext(UserContext);
            const { companyMeta } = useContext(AppCompanyContext);
            const { role } = useRole();

            if (authUser.authUser === undefined) {
                return null;
            }

            if (authUser.authUser === null) {
                return <Redirect push to={ROUTES.SIGNIN} />;
            }

            if (companyMeta?.isSuspended) {
                Firebase.instance().doSignOut();
                location.reload();
            }

            if (!role) {
                return null;
            }

            if (!condition(authUser.authUser, role)) {
                return <Redirect push to={ROUTES.HOME} />;
            }

            return <Component {...props} />;
        };
    };
}
