import { useState } from "react";
import { Resource } from "@farmact/model/src/model/Resource";
import { ResourceVariantIdentifier } from "@farmact/model/src/model/ResourcePrice";
import { isSimpleResourcePrice, isVariantsResourcePrice } from "../../../../util/resourceUtils";
import { ADD_NEW_LIQUID_MIXTURE_OPTION_VALUE } from "./useResourceSelectItems";
import { LiquidMixture } from "@farmact/model/src/model/LiquidMixture";
import { LIQUID_MIXTURE_VARIANT_ID } from "./utils";

interface UseResourceSelectionOptions {
    onResourceSelect: ResourceSelectedHandler;
    availableResources: Resource[];
    availableLiquidMixtures: LiquidMixture[];
}

interface UseResourceSelectionReturn {
    onChange: ResourceSelectionChangeHandler;
    modal: VariantModalState;
    dismissModal: () => void;
}

export function useResourceSelection(options: UseResourceSelectionOptions): UseResourceSelectionReturn {
    const [modal, setModal] = useState<VariantModalState>(INITIAL_MODAL_STATE);

    const onChange = (resourceId: string | undefined) => {
        if (!resourceId) {
            options.onResourceSelect(null);
            return;
        }

        if (resourceId === ADD_NEW_LIQUID_MIXTURE_OPTION_VALUE) {
            options.onResourceSelect({
                resourceId: ADD_NEW_LIQUID_MIXTURE_OPTION_VALUE,
                variantId: ADD_NEW_LIQUID_MIXTURE_OPTION_VALUE,
            });
            return;
        }

        const selectedLiquidMixture = options.availableLiquidMixtures.find(mixture =>
            resourceId.startsWith(mixture.id)
        );
        if (selectedLiquidMixture) {
            options.onResourceSelect({
                resourceId: selectedLiquidMixture.id,
                variantId: LIQUID_MIXTURE_VARIANT_ID,
            });
            return;
        }

        const selectedResource = options.availableResources.find(resource => resourceId.startsWith(resource.id));
        if (!selectedResource) {
            return;
        }

        if (isSimpleResourcePrice(selectedResource.price)) {
            options.onResourceSelect({
                resourceId: selectedResource.id,
                variantId: selectedResource.price.id,
            });
            return;
        }
        if (isVariantsResourcePrice(selectedResource.price)) {
            setModal({
                show: true,
                resource: selectedResource,
            });
        }
    };

    return {
        onChange,
        modal,
        dismissModal: () => setModal(INITIAL_MODAL_STATE),
    };
}

const INITIAL_MODAL_STATE: VariantModalState = {
    show: false,
    resource: new Resource(),
};

type ResourceSelectionChangeHandler = (resourceId: string | undefined) => void;
type ResourceSelectedHandler = (updatedResource: ResourceVariantIdentifier | null) => void;

interface VariantModalState {
    show: boolean;
    resource: Resource;
}
