import { useEffect, useState } from "react";
import { OrderFlowReadyState } from "../orderFlowReadyState";
import { AnyDriverQuery, Order, TaskRecord } from "@farmact/model/src/model/Order";
import { MachineCounterValue } from "../MachineCounters/useMachineCounterGroups";
import { useLatestOrder } from "../StartOrderModal/StartOrderContext/useLatestOrder";
import { useInitializeStopOrderContextDriverQueries } from "./StopOrderContext/useInitializeStopOrderContextDriverQueries";
import { useInitializeStopOrderContextMachineCounters } from "./StopOrderContext/useInitializeStopOrderContextMachineCounters";
import { useInitializeStopOrderContextTaskRecords } from "./StopOrderContext/useInitializeStopOrderContextTaskRecords";

interface IStopOrderConteztValuesProps {
    orderId: Order["id"];
    enabled: boolean;
}

export function useStopOrderContextValues(props: IStopOrderConteztValuesProps) {
    const [previousEnabled, setPreviousEnabled] = useState(false);
    const [readyState, setReadyState] = useState(OrderFlowReadyState.INACTIVE);

    const [driverQueries, setDriverQueries] = useState<AnyDriverQuery[]>([]);
    const [note, setNote] = useState("");
    const [machineCounterValues, setMachineCounterValues] = useState<MachineCounterValue[]>([]);
    const [taskRecords, setTaskRecords] = useState<TaskRecord[]>([]);

    // Start initialization
    if (props.enabled && !previousEnabled) {
        setPreviousEnabled(true);
        setReadyState(OrderFlowReadyState.INITIALIZING);
    }

    // Reset internal state and stop initialization
    if (!props.enabled && previousEnabled) {
        setDriverQueries([]);
        setMachineCounterValues([]);
        setTaskRecords([]);

        setPreviousEnabled(false);
        setReadyState(OrderFlowReadyState.INACTIVE);
    }

    const latestOrder = useLatestOrder(props.orderId, { enabled: props.enabled });

    const initializeDriverQueries = useInitializeStopOrderContextDriverQueries(latestOrder.order, {
        enabled: props.enabled,
        setDriverQueries,
        setNote,
    });

    const initializeMachineCounters = useInitializeStopOrderContextMachineCounters(latestOrder.order, {
        enabled: props.enabled,
        setMachineCounterValues,
    });

    const initializeTaskRecords = useInitializeStopOrderContextTaskRecords(latestOrder.order, {
        enabled: props.enabled,
        setTaskRecords,
    });

    const isInitializing =
        latestOrder.loading ||
        initializeDriverQueries.loading ||
        initializeMachineCounters.loading ||
        initializeTaskRecords.loading;

    const initializationError = initializeMachineCounters.error || initializeTaskRecords.error;

    useEffect(() => {
        if (readyState !== OrderFlowReadyState.INITIALIZING || isInitializing) {
            return;
        }

        if (initializationError) {
            setReadyState(OrderFlowReadyState.ERROR);
        } else {
            setReadyState(OrderFlowReadyState.READY);
        }
    }, [readyState, isInitializing, initializationError]);

    return {
        order: latestOrder.order,
        readyState,
        initializationError,
        //
        driverQueries,
        setDriverQueries,
        //
        note,
        setNote,
        //
        machineCounterValues,
        setMachineCounterValues,
        //
        taskRecords,
        setTaskRecords,
    };
}
