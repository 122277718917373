import { lazy } from "react";
import { DefaultPage } from "../structure/Page/DefaultPage/DefaultPage";
import { withAuthorization } from "../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../authentication/authorizations";
import { Role } from "@farmact/model/src/model/Role";

const InternalWorkPageContent = lazy(() => import("./InternalWorkPageContent"));

function InternalWorkPageInternal() {
    return (
        <DefaultPage>
            <InternalWorkPageContent />
        </DefaultPage>
    );
}

export const InternalWorkPage = withAuthorization(
    getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.PLANNER, Role.EMPLOYEE, Role.TEMPORARY_WORKER)
)(InternalWorkPageInternal);
