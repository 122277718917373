import { ResourceCategory, ResourceUnit } from "@farmact/model/src/model/Resource";
import { getSingularPluralTextFormatter } from "../formatters";

export const getResourceCostUnitName = (costUnit: ResourceUnit, count = 0) => {
    switch (costUnit) {
        case ResourceUnit.KILOMETER:
            return "Kilometer";
        case ResourceUnit.LEGACY_LITER:
            return "Liter";
        case ResourceUnit.LEGACY_KILOGRAM:
            return "Kilogramm";
        case ResourceUnit.LEGACY_PIECE:
            return "Stück";
        case ResourceUnit.LEGACY_CARTRIDGE:
            return getSingularPluralTextFormatter("Kartusche", "Kartuschen")(count);
        case ResourceUnit.LITER:
            return "Liter";
        case ResourceUnit.MILLILITER:
            return "Milliliter";
        case ResourceUnit.KILOGRAM:
            return "Kilogramm";
        case ResourceUnit.GRAM:
            return "Gramm";
        case ResourceUnit.PIECE:
            return "Stück";
        case ResourceUnit.CARTRIDGE:
            return getSingularPluralTextFormatter("Kartusche", "Kartuschen")(count);
        case ResourceUnit.METER:
            return "Meter";
        case ResourceUnit.SEED:
            return "Körner";
        case ResourceUnit.TON:
            return getSingularPluralTextFormatter("Tonne", "Tonnen")(count);
        case ResourceUnit.CUBIC_METER:
            return "Kubikmeter";
        case ResourceUnit.SPACE_CUBIC_METER:
            return "Raummeter";
        case ResourceUnit.BULK_CUBIC_METER:
            return "Schüttraummeter";
        case ResourceUnit.HOUR:
            return getSingularPluralTextFormatter("Stunde", "Stunden")(count);
        case ResourceUnit.DAY:
            return getSingularPluralTextFormatter("Tag", "Tage")(count);
        case ResourceUnit.HECTARE:
            return "Hektar";
        case ResourceUnit.SQUARE_METER:
            return "Quadratmeter";
        case ResourceUnit.QUINTAL:
            return getSingularPluralTextFormatter("Dezitonne", "Dezitonne")(count);
        case ResourceUnit.KILO_WATT_HOUR:
            return getSingularPluralTextFormatter("Kilowattstunde", "Kilowattstunden")(count);
        case ResourceUnit.UNIT:
            return getSingularPluralTextFormatter("Einheit", "Einheiten")(count);
        case ResourceUnit.FLATRATE:
            return getSingularPluralTextFormatter("Pauschale", "Pauschalen")(count);
    }
};

// TODO: move to core / utils package
export function getResourceShortCostUnit(costUnit: ResourceUnit) {
    switch (costUnit) {
        case ResourceUnit.KILOMETER:
            return "km";
        case ResourceUnit.LEGACY_LITER:
            return "l";
        case ResourceUnit.LEGACY_KILOGRAM:
            return "kg";
        case ResourceUnit.LEGACY_PIECE:
            return "Stk.";
        case ResourceUnit.LEGACY_CARTRIDGE:
            return "Ku";
        case ResourceUnit.LITER:
            return "l";
        case ResourceUnit.MILLILITER:
            return "ml";
        case ResourceUnit.KILOGRAM:
            return "kg";
        case ResourceUnit.GRAM:
            return "g";
        case ResourceUnit.PIECE:
            return "Stk.";
        case ResourceUnit.CARTRIDGE:
            return "Ku";
        case ResourceUnit.TON:
            return "t";
        case ResourceUnit.SEED:
            return "Kn";
        case ResourceUnit.CUBIC_METER:
            return "m³";
        case ResourceUnit.SPACE_CUBIC_METER:
            return "Rm";
        case ResourceUnit.BULK_CUBIC_METER:
            return "SRm";
        case ResourceUnit.HOUR:
            return "h";
        case ResourceUnit.DAY:
            return "d";
        case ResourceUnit.METER:
            return "m";
        case ResourceUnit.HECTARE:
            return "ha";
        case ResourceUnit.SQUARE_METER:
            return "qm";
        case ResourceUnit.QUINTAL:
            return "dt";
        case ResourceUnit.KILO_WATT_HOUR:
            return "kWh";
        case ResourceUnit.UNIT:
            return "Einh.";
        case ResourceUnit.FLATRATE:
            return "pauschal";
    }
}

export function getResourceCategoryName(category: ResourceCategory) {
    switch (category) {
        case ResourceCategory.SPARE_PARTS:
            return "Ersatzteile";
        case ResourceCategory.FEED:
            return "Futtermittel";
        case ResourceCategory.TRADING_GOODS:
            return "Handelswaren";
        case ResourceCategory.LUBRICANTS_AND_FUELS:
            return "Schmier- / Treibstoffe";
        case ResourceCategory.SEEDS_AND_FERTILIZERS:
            return "Saatgut, Pflanzenschutz, Dünger";
        case ResourceCategory.OTHER:
            return "Sonstiges";
        case ResourceCategory.RECEIPT_POSITIONS:
            return "Belegpositionen";
    }
}
