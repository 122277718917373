import { useEffect, useRef, useState } from "react";
import { ActionPerformed, PushNotifications, Token } from "@capacitor/push-notifications";
import { useHistory } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import { LANDING, SIGNIN, SIGNUP, STRIPE_ERROR } from "@farmact/model/src/constants/farmActAppRoutes";
import { useToastContext } from "../../../components/ToastContext";
import { RecipientsService } from "@farmact/notification-app-client";
import { useUserContext } from "../../../components/authentication/Session/UserContext";
import { recordError } from "../../recordError";
import { Device, DeviceInfo } from "@capacitor/device";
import { handlePushNotification } from "./utils/handlePushNotification";
import { PUSH_TOKEN_LOCAL_STORAGE_KEY } from "../../../constants/localStorageKeys";

export function useMessaging() {
    const { onMessage } = useToastContext();
    const history = useHistory();
    const currentPath = history.location.pathname;
    const { authUser } = useUserContext();
    const [deviceInfo, setDeviceInfo] = useState<DeviceInfo | null>();
    const hasTriedRegistering = useRef(false);
    const latestRecipientCombination = useRef<{ token: string; authUserId: string } | null>(null);

    useEffect(() => {
        (async function () {
            try {
                setDeviceInfo(await Device.getInfo());
            } catch (error: any) {
                recordError("Could not get device info", error);
                setDeviceInfo(null);
            }
        })();
    }, []);

    useEffect(() => {
        if (
            !Capacitor.isNativePlatform() ||
            [SIGNUP, SIGNIN, LANDING, STRIPE_ERROR].includes(currentPath) ||
            !authUser?.uid ||
            deviceInfo === undefined
        ) {
            return;
        }
        PushNotifications.addListener("registration", (token: Token) => {
            if (
                latestRecipientCombination.current?.token === token.value &&
                latestRecipientCombination.current?.authUserId === authUser.uid
            ) {
                return;
            }
            latestRecipientCombination.current = {
                token: token.value,
                authUserId: authUser.uid,
            };
            RecipientsService.registerRecipient({
                requestBody: {
                    registrationToken: token.value,
                    authUserId: authUser.uid,
                    platform: deviceInfo?.platform ?? "unknown",
                    model: deviceInfo?.model ?? "unknown",
                },
            })
                .then(() => {
                    localStorage.setItem(PUSH_TOKEN_LOCAL_STORAGE_KEY, token.value);
                })
                .catch(error => {
                    recordError("Could not register for push notifications", { error });
                });
        });

        PushNotifications.addListener("registrationError", (error: any) => {
            recordError("Error on push notification registration.", { error });
        });

        // Show us the notification payload if the app is open on our device
        // PushNotifications.addListener("pushNotificationReceived", (notification: PushNotificationSchema) => {
        // TODO TBD: should we somehow react to push notifications when app is open?
        // onMessage(notification.body ?? notification.title ?? "Neue Benachrichtigung");
        // });

        // Method called when tapping on a notification
        PushNotifications.addListener("pushNotificationActionPerformed", (notification: ActionPerformed) => {
            handlePushNotification(notification.notification, history);
        });
        return () => {
            PushNotifications.removeAllListeners();
        };
    }, [authUser?.uid, currentPath, deviceInfo, history, onMessage]);

    useEffect(() => {
        if (!Capacitor.isNativePlatform() || [SIGNUP, SIGNIN, LANDING, STRIPE_ERROR].includes(currentPath)) {
            return;
        }
        (async function checkPermission() {
            const res = await PushNotifications.checkPermissions();
            if (res.receive === "denied") {
                return;
            }
            if (res.receive === "prompt" || res.receive === "prompt-with-rationale") {
                const result = await PushNotifications.requestPermissions();
                if (result.receive !== "granted") {
                    return;
                }
            }
            if (!hasTriedRegistering.current) {
                PushNotifications.register();
                hasTriedRegistering.current = true;
            }
        })();
    }, [currentPath]);
}
