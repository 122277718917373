import Field from "@farmact/model/src/model/Field";
import { Order, TaskRecord } from "@farmact/model/src/model/Order";
import { Track } from "@farmact/model/src/model/Track";
import { ServicePriceUnit } from "@farmact/model/src/model/services/Service";
import { TaskRecordType } from "@farmact/model/src/model/services/TaskRecords";
import { query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { computeLength } from "spherical-geometry-js";
import { v4 } from "uuid";
import { Firebase } from "../../../../../firebase";
import { mergeQueryData } from "../../../../../firebase/helpers";
import { round } from "../../../../../util/numberUtils";
import { getServiceTaskRecordTypes } from "../../../../../util/orderUtils";
import { recordError } from "../../../../../util/recordError";
import { getAreaUnit, getTracksUnit } from "../../TaskRecord/taskRecordModalContentUtils";
import { getCurrentlyActiveCustomerId } from "../../../utils/orderUtils";

interface UseInitializeStopOrderContextTaskRecordsParams {
    enabled: boolean;
    setTaskRecords: (taskRecords: TaskRecord[]) => void;
}

interface UseInitializeStopOrderContextTaskRecordsReturn {
    loading: boolean;
    error: InitializeStopOrderContextTaskRecordsError | null;
}

export function useInitializeStopOrderContextTaskRecords(
    order: Order | null,
    params: UseInitializeStopOrderContextTaskRecordsParams
): UseInitializeStopOrderContextTaskRecordsReturn {
    const { enabled, setTaskRecords } = params;

    const [previousEnabled, setPreviousEnabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<InitializeStopOrderContextTaskRecordsError | null>(null);

    if (previousEnabled && !params.enabled) {
        setLoading(false);
        setError(null);
        setPreviousEnabled(false);
    }
    if (!previousEnabled && params.enabled) {
        setLoading(true);
        setError(null);
        setPreviousEnabled(true);
    }

    useEffect(() => {
        if (!enabled || !order) {
            return;
        }

        if (!order.serviceSnapshot) {
            setLoading(false);
            setError(InitializeStopOrderContextTaskRecordsError.NO_SERVICE_SNAPSHOT);
            return;
        }

        const createTaskRecords = async (): Promise<TaskRecord[]> => {
            const orderTaskRecordTypes = getServiceTaskRecordTypes(order.serviceSnapshot);

            const taskRecords: TaskRecord[] = [];
            if (orderTaskRecordTypes.includes(TaskRecordType.AREA)) {
                taskRecords.push(await createAreaTaskRecord(order));
            }
            if (orderTaskRecordTypes.includes(TaskRecordType.TRACKS)) {
                taskRecords.push(await createTracksTaskRecord(order));
            }
            if (orderTaskRecordTypes.includes(TaskRecordType.TONS)) {
                taskRecords.push(createTonsTaskRecord(order));
            }
            if (orderTaskRecordTypes.includes(TaskRecordType.CUBIC_METERS)) {
                taskRecords.push(createCubicMetersTaskRecord(order));
            }
            if (orderTaskRecordTypes.includes(TaskRecordType.LITER)) {
                taskRecords.push(createLiterTaskRecord(order));
            }

            return taskRecords;
        };

        createTaskRecords()
            .then(taskRecords => {
                setTaskRecords(taskRecords);
            })
            .catch(error => {
                recordError("Failed to create task records to stop order", {
                    order,
                    error,
                });
                setError(InitializeStopOrderContextTaskRecordsError.UNKNOWN);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [enabled, setTaskRecords, order]);

    return {
        loading,
        error,
    };
}

export enum InitializeStopOrderContextTaskRecordsError {
    NO_SERVICE_SNAPSHOT,
    UNKNOWN,
}

async function loadOrderFields(order: Order): Promise<Field[]> {
    return mergeQueryData(order.fieldIds, ids => {
        return query(Firebase.instance().getAllFields(), where("id", "in", ids));
    });
}

async function loadOrderTracks(order: Order): Promise<Track[]> {
    return mergeQueryData(order.trackIds, ids => {
        return query(Firebase.instance().getAllTracks(), where("id", "in", ids));
    });
}

async function createAreaTaskRecord(order: Order): Promise<TaskRecord> {
    const fields = await loadOrderFields(order);
    const areaUnit = getAreaUnit(order.serviceSnapshot);
    const currentlyActiveCustomerId = getCurrentlyActiveCustomerId(order);

    const calculatedArea = fields
        .filter(field => field.customerId === currentlyActiveCustomerId)
        .reduce((acc, cur) => {
            return acc + cur.areaHa;
        }, 0);

    return {
        id: v4(),
        record: round(areaUnit === ServicePriceUnit.HECTARE ? calculatedArea : calculatedArea * 10000, {
            decimalDigits: 2,
        }),
        timeStamp: new Date().toISOString(),
        unit: areaUnit ?? ServicePriceUnit.HECTARE,
        type: TaskRecordType.AREA,
        customerId: currentlyActiveCustomerId,
    };
}

async function createTracksTaskRecord(order: Order): Promise<TaskRecord> {
    const tracks = await loadOrderTracks(order);
    const tracksUnit = getTracksUnit(order.serviceSnapshot);
    const currentlyActiveCustomerId = getCurrentlyActiveCustomerId(order);

    const calculatedTracksLengthInMeters = tracks
        .filter(track => track.customerId === currentlyActiveCustomerId)
        .reduce((acc, cur) => {
            return acc + computeLength(cur.shape);
        }, 0);

    return {
        id: v4(),
        record: round(
            tracksUnit === ServicePriceUnit.KILOMETER
                ? calculatedTracksLengthInMeters / 1000
                : calculatedTracksLengthInMeters,
            { decimalDigits: 2 }
        ),
        timeStamp: new Date().toISOString(),
        unit: tracksUnit ?? ServicePriceUnit.KILOMETER,
        type: TaskRecordType.TRACKS,
        customerId: currentlyActiveCustomerId,
    };
}

function createTonsTaskRecord(order: Order): TaskRecord {
    const currentlyActiveCustomerId = getCurrentlyActiveCustomerId(order);

    return {
        id: v4(),
        record: null,
        timeStamp: new Date().toISOString(),
        unit: ServicePriceUnit.TONS,
        type: TaskRecordType.TONS,
        customerId: currentlyActiveCustomerId,
    };
}

function createCubicMetersTaskRecord(order: Order): TaskRecord {
    const currentlyActiveCustomerId = getCurrentlyActiveCustomerId(order);

    return {
        id: v4(),
        record: null,
        timeStamp: new Date().toISOString(),
        unit: ServicePriceUnit.CUBIC_METERS,
        type: TaskRecordType.CUBIC_METERS,
        customerId: currentlyActiveCustomerId,
    };
}

function createLiterTaskRecord(order: Order): TaskRecord {
    const currentlyActiveCustomerId = getCurrentlyActiveCustomerId(order);

    return {
        id: v4(),
        record: null,
        timeStamp: new Date().toISOString(),
        unit: ServicePriceUnit.LITER,
        type: TaskRecordType.LITER,
        customerId: currentlyActiveCustomerId,
    };
}
