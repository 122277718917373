import { DriverQueryResourceWithAmount, DriverQueryResourceWithAmountValue } from "@farmact/model/src/model/Order";
import { ResourceUsageSource } from "@farmact/model/src/model/Refuel";
import { Resource } from "@farmact/model/src/model/Resource";
import { ResourcePriceVariantsType, ResourceVariantIdentifier } from "@farmact/model/src/model/ResourcePrice";
import { ResourceUsage } from "@farmact/model/src/model/ResourceUsage";
import { ResourceRestrictionType } from "@farmact/model/src/model/services/DriverQuery";
import { useEffect, useRef, useState } from "react";
import { getResourceCostUnitName } from "../../../../../util/nameUtils/resourceNameUtils";
import {
    constructResourceName,
    getResourceCostPerUnit,
    getResourcePricePerUnit,
    isSimpleResourcePrice,
} from "../../../../../util/resourceUtils";
import * as Item from "../../../../FaItem";
import { useAppCompanyContext } from "../../../../authentication/AppCompanyContext";
import { NumberInput } from "../../../../inputs/NumberInput/NumberInput";
import { SingleResourceInput } from "../../../../inputs/resourceInputs/SingleResourceInput/SingleResourceInput";
import { useDriverQueryResourceWithAmountResources } from "./useDriverQueryResourceWithAmountResources";

interface IDriverQueryResourceInputItemProps {
    label?: string;
    driverQuery: DriverQueryResourceWithAmount;
    onChange: (updateData: Partial<DriverQueryResourceWithAmount>) => void;
}

export const DriverQueryResourceInputItem = (props: IDriverQueryResourceInputItemProps) => {
    const { defaultOperatingUnit } = useAppCompanyContext();
    const resources = useDriverQueryResourceWithAmountResources(props.driverQuery);

    const [controlledResource, setControlledResource] = useState<ResourceVariantIdentifier | null>(
        resources.length === 1 && resources[0].price.variantsType === ResourcePriceVariantsType.SIMPLE
            ? { resourceId: resources[0].id, variantId: resources[0].price.id }
            : null
    );
    const [controlledAmount, setControlledAmount] = useState<number | null>(
        props.driverQuery.value.usage?.amount ?? null
    );

    const { resourceId, resourceVariantId } = {
        resourceId: props.driverQuery.value.usage?.resourceId ?? null,
        resourceVariantId: props.driverQuery.value.usage?.resourceVariantId ?? null,
    };

    useEffect(() => {
        if (controlledResource) {
            return;
        }

        if (resourceId && resourceVariantId) {
            setControlledResource({
                resourceId,
                variantId: resourceVariantId,
            });
            return;
        }

        const driverQuery = props.driverQuery;
        const restriction = driverQuery.restriction;
        if (restriction?.type !== ResourceRestrictionType.RESOURCE) {
            return;
        }

        if (restriction.resourceVariantId !== null) {
            setControlledResource({
                resourceId: restriction.resourceId,
                variantId: restriction.resourceVariantId,
            });
            return;
        }

        const resource = resources.find(resource => {
            return resource.id === restriction.resourceId;
        });

        // should not happen but still does, so this is a safeguard
        if (resource && isSimpleResourcePrice(resource.price)) {
            setControlledResource({
                resourceId: resource.id,
                variantId: resource.price.id,
            });
            return;
        }
        setControlledResource(null);
    }, [controlledResource, props.driverQuery, resourceId, resourceVariantId, resources]);

    const updateDriverQueryValue = (updateData: Partial<DriverQueryResourceWithAmountValue>) => {
        props.onChange({
            value: {
                ...props.driverQuery.value,
                ...updateData,
            },
        });
    };
    const latestOnChange = useRef(updateDriverQueryValue);
    latestOnChange.current = updateDriverQueryValue;

    useEffect(() => {
        if (controlledResource === null || controlledAmount === null) {
            latestOnChange.current({
                usage: null,
            });
            return;
        }

        const { resourceId, variantId } = controlledResource;
        const resource = resources.find(resource => resource.id === resourceId);
        if (!resource) {
            return;
        }

        latestOnChange.current({
            usage: new ResourceUsage({
                //TODO Sammel: customerId depending on Start / Stop / Edit
                resourceId: resourceId,
                resourceVariantId: variantId,
                resourceName: constructResourceName(resource, variantId),
                resourceCostsPerUnit: getResourceCostPerUnit(resource.price, variantId),
                resourcePricePerUnit: getResourcePricePerUnit(resource.price, variantId),
                resourceUnit: resource.unit,
                source: ResourceUsageSource.COMPANY,
                vatPercentPoints: resource.vatPercentPoints ?? defaultOperatingUnit?.vat,
                amount: controlledAmount,
                vskz_mr: resource.vskz_mr,
            }),
        });
    }, [controlledResource, controlledAmount, resources, defaultOperatingUnit?.vat]);

    const resourceVariantRestriction =
        (function computeResourceVariantRestriction(): ResourceVariantRestriction | null {
            const restriction = props.driverQuery.restriction;
            if (restriction?.type === ResourceRestrictionType.RESOURCE) {
                const resource = resources.find(resource => resource.id === restriction.resourceId) ?? null;
                if (!resource) {
                    return null;
                }

                if (restriction.resourceVariantId) {
                    return { resource, variantId: restriction.resourceVariantId };
                }
                if (isSimpleResourcePrice(resource.price)) {
                    return { resource, variantId: resource.price.id };
                }
            }
            return null;
        })();

    const selectedResource = resources.find(resource => resource.id === controlledResource?.resourceId) ?? null;

    const handleResourceChange = (updatedValue: ResourceVariantIdentifier | null) => {
        if (!updatedValue) {
            setControlledResource(null);
            return;
        }

        if (updatedValue) {
            const resource = resources.find(resource => resource.id === updatedValue.resourceId);

            if (resource) {
                setControlledResource({
                    resourceId: resource.id,
                    variantId: updatedValue.variantId,
                });
            }
        }
    };

    const handleAmountChange = (updatedAmount: number | null) => {
        setControlledAmount(updatedAmount);
    };

    const getInputLabel = () => {
        if (props.label) {
            return props.label;
        }

        if (selectedResource) {
            return `Wert (${getResourceCostUnitName(selectedResource.unit)})`;
        }

        return "Wert";
    };

    return (
        <>
            {!resourceVariantRestriction && (
                <Item.Root>
                    <Item.Label>Artikel</Item.Label>
                    <Item.Content>
                        <SingleResourceInput
                            selectedResource={controlledResource}
                            availableResources={resources}
                            onChange={handleResourceChange}
                        />
                    </Item.Content>
                </Item.Root>
            )}
            {resourceVariantRestriction && (
                <Item.Root>
                    <Item.Label>Artikel</Item.Label>
                    <Item.Content>
                        <span>
                            {constructResourceName(
                                resourceVariantRestriction.resource,
                                resourceVariantRestriction.variantId
                            )}
                        </span>
                    </Item.Content>
                </Item.Root>
            )}
            <Item.Root>
                <Item.Label>{getInputLabel()}</Item.Label>
                <Item.Content>
                    <NumberInput
                        placeholder={getInputLabel()}
                        step={0.1}
                        currentValue={controlledAmount}
                        onChange={handleAmountChange}
                    />
                </Item.Content>
            </Item.Root>
        </>
    );
};

interface ResourceVariantRestriction {
    resource: Resource;
    variantId: string;
}
