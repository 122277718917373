import { lazy } from "react";
import { withAuthorization } from "../authentication/Session/withAuthorization";
import { loggedIn } from "../authentication/authorizations";
import { DefaultPage } from "../structure/Page/DefaultPage/DefaultPage";

const HomePageContent = lazy(() => import("./HomePageContent"));

function HomePageInternal() {
    return (
        <DefaultPage>
            <HomePageContent />
        </DefaultPage>
    );
}

export const HomePage = withAuthorization(loggedIn)(HomePageInternal);
