import { DriverQueryInputTime } from "@farmact/model/src/model/services/DriverQuery";
import { useState } from "react";
import { filterDriverQueriesByInputTime } from "../../../../../util/orderUtils";
import { OrderFlowReadyState } from "../../orderFlowReadyState";
import { useStopOrderContext } from "../StopOrderContext/StopOrderContext";

export interface UseStopOrderViewsReturn {
    active: StopOrderView | null;

    previous: () => void;
    next: () => void;
    set: (view: StopOrderView) => void;

    empty: boolean;
    isFirst: boolean;
    isLast: boolean;
}

export function useStopOrderViews(askForConfirmation: boolean): UseStopOrderViewsReturn {
    const { order, readyState, driverQueries, machineCounterValues, taskRecords } = useStopOrderContext();

    const [previousReadyState, setPreviousReadyState] = useState(OrderFlowReadyState.INACTIVE);
    const [activeViewIndex, setActiveViewIndex] = useState(-1);

    if (previousReadyState !== readyState && readyState === OrderFlowReadyState.READY) {
        setActiveViewIndex(-1);
        setPreviousReadyState(readyState);
    }

    const views = ((): StopOrderView[] => {
        const views: StopOrderView[] = [];

        const includeIf = (view: StopOrderView, condition: boolean) => {
            if (condition) {
                views.push(view);
            }
        };

        const includeDriverQueriesView =
            order.serviceSnapshot?.queryNote ||
            filterDriverQueriesByInputTime(driverQueries, DriverQueryInputTime.STOP).length > 0;

        includeIf(StopOrderView.CONFIRMATION, askForConfirmation);
        includeIf(StopOrderView.DRIVER_QUERIES, includeDriverQueriesView);
        includeIf(StopOrderView.MACHINE_COUNTERS, machineCounterValues.length > 0);
        includeIf(StopOrderView.TASK_RECORDS, taskRecords.length > 0);

        return views;
    })();

    if (views.length === 0 && activeViewIndex !== -1) {
        setActiveViewIndex(-1);
    }
    if (views.length > 0 && activeViewIndex < 0) {
        setActiveViewIndex(0);
    }
    if (views.length > 0 && activeViewIndex > views.length - 1) {
        setActiveViewIndex(views.length - 1);
    }

    const previous = () => {
        if (views.length === 0) {
            return;
        }

        setActiveViewIndex(prev => {
            return Math.max(0, prev - 1);
        });
    };

    const next = () => {
        if (views.length === 0) {
            return;
        }

        setActiveViewIndex(prev => {
            return Math.min(views.length - 1, prev + 1);
        });
    };

    const set = (view: StopOrderView) => {
        if (!views.includes(view)) {
            return;
        }

        setActiveViewIndex(prev => {
            const nextIndex = views.indexOf(view);
            return nextIndex === -1 ? prev : nextIndex;
        });
    };

    const activeView = views[activeViewIndex] ?? null;

    return {
        active: activeView,
        //
        previous,
        next,
        set,
        //
        empty: views.length === 0,
        isFirst: views.length > 0 && activeViewIndex === 0,
        isLast: views.length > 0 && activeViewIndex === views.length - 1,
    };
}

export enum StopOrderView {
    CONFIRMATION = "CONFIRMATION",
    DRIVER_QUERIES = "DRIVER_QUERIES",
    MACHINE_COUNTERS = "MACHINE_COUNTERS",
    TASK_RECORDS = "TASK_RECORDS",
}
