import { IonButton, IonContent, IonHeader, IonTitle, IonToolbar, isPlatform } from "@ionic/react";
import { openLink } from "../../util/openLink";
import "./unsupportedVersionModal.scss";
import { faExternalLink } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { resetCacheAndReloadPage } from "../../util/resetCacheAndReloadPage";
import { FarmActModal } from "../FarmActModal/FarmActModal";
import { getAppBaseUrl } from "../../util/getAppBaseUrl";
import { useDocumentData } from "../../firebase/dataHooks";
import { Firebase } from "../../firebase";
import { useAppCompanyContext } from "../authentication/AppCompanyContext";
import { appVersion } from "../../../config/config";
import { appStoreLinks } from "../../constants/links";
import { compareVersions } from "../../util/version";

export const UnsupportedVersionModal = () => {
    const { appCompany } = useAppCompanyContext();
    const [appMeta] = useDocumentData(Firebase.instance().getAppMeta());

    const showUnsupportedVersionModal = !!(
        appMeta &&
        appCompany?.id &&
        appCompany.id !== APPLE_TEST_ACCOUNT_ID &&
        compareVersions(appVersion, appMeta.minimumSupportedVersion) < 0
    );

    const handleUpdateClick = () => {
        openLink(isPlatform("ios") ? appStoreLinks.ios : appStoreLinks.android);
    };

    return (
        <FarmActModal isOpen={false} backdropDismiss={false}>
            <div className="default-modal-container">
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Veraltete Version</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <div className="unsupported-version">
                        {isPlatform("mobileweb") || isPlatform("desktop") ? (
                            <div className="unsupported-version__reload">
                                Deine App-Version wird nicht mehr unterstützt. Bitte lade die Seite neu (Strg / Cmd +
                                R).
                                <IonButton className="update-button" onClick={() => location.reload()}>
                                    Jetzt neu laden
                                </IonButton>
                            </div>
                        ) : (
                            <>
                                <div className="unsupported-version__reload">
                                    Deine App-Version wird nicht mehr unterstützt. Bitte lade die aktuellste Version
                                    herunter.
                                    <IonButton className="update-button" onClick={handleUpdateClick}>
                                        Jetzt aktualisieren{" "}
                                        <FontAwesomeIcon icon={faExternalLink} className="update-button__icon" />
                                    </IonButton>
                                </div>
                                <div className="unsupported-version__guide-to-solution">
                                    Falls in dem Store kein Update zu finden ist, gibt es{" "}
                                    <a
                                        onClick={() => {
                                            openLink(
                                                "https://helpdesk.farmact.de/portal/de/kb/articles/farmact-app-kann-nicht-aktualisiert-werden"
                                            );
                                        }}>
                                        hier
                                    </a>{" "}
                                    eine mögliche Lösung für das Problem.
                                </div>
                            </>
                        )}
                        <div className="unsupported-version__reset-cache">
                            Update geladen, aber du siehst immer noch dieses Fenster?
                            <IonButton onClick={() => resetCacheAndReloadPage()}>Nochmal versuchen</IonButton>
                        </div>
                        {isPlatform("mobile") && !isPlatform("mobileweb") && (
                            <div className="unsupported-version__link-to-web-app">
                                Du kannst die App auch vorübergehend über die Web-Version nutzen.
                                <IonButton onClick={() => openLink(getAppBaseUrl())}>Zur Web-Version</IonButton>
                            </div>
                        )}
                    </div>
                </IonContent>
            </div>
        </FarmActModal>
    );
};

const APPLE_TEST_ACCOUNT_ID = "2mNMxZb8k6Zgp9HJfGTN";
