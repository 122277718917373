import { withAuthorization } from "../../authentication/Session/withAuthorization";
import { loggedIn } from "../../authentication/authorizations";
import { lazy } from "react";
import { DefaultPage } from "../../structure/Page/DefaultPage/DefaultPage";

const UpdateInfoPageContent = lazy(() => import("./UpdateInfoPageContent"));

function UpdateInfoPageInternal() {
    return (
        <DefaultPage>
            <UpdateInfoPageContent />
        </DefaultPage>
    );
}

export const UpdateInfoPage = withAuthorization(loggedIn)(UpdateInfoPageInternal);
