import React, { useContext, useState } from "react";
import { Firebase } from "../../../firebase";
import { ToastContext } from "../../ToastContext";
import { useHistory } from "react-router-dom";
import * as ROUTES from "@farmact/model/src/constants/farmActAppRoutes";
import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCheckbox,
    IonFooter,
    IonLabel,
    IonSpinner,
    IonText,
    IonToolbar,
} from "@ionic/react";
import { openLink } from "../../../util/openLink";
import "./signUpForm.scss";
import { SplashScreen } from "@capacitor/splash-screen";
import { SubmitFormOnEnter } from "../../inputs/SubmitFormOnEnter";
import { recordError } from "../../../util/recordError";
import { FaItemGroup } from "../../FaItemGroup/FaItemGroup";
import * as Item from "../../FaItem";
import { Input } from "../../inputs/Input/Input";

interface SignUpFormProps {
    email: string;
    token: string;
    company: string;
}

export const SignUpForm = (props: SignUpFormProps) => {
    const { onMessage } = useContext(ToastContext);
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [agbChecked, setAgbChecked] = useState(false);
    const [privacyChecked, setPrivacyChecked] = useState(false);
    const history = useHistory();
    const [error, setError] = useState<string | null>(null);

    SplashScreen.hide();

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (password1 !== password2) {
            handleSubmitError("Passwörter stimmen nicht überein.");
            return;
        }

        if (password1.length < 6) {
            handleSubmitError("Das Passwort muss mindestens 6 Zeichen lang sein.");
            return;
        }

        if (!agbChecked) {
            handleSubmitError("Bitte akzeptiere die AGB, um fortzufahren.");
            return;
        }

        if (!privacyChecked) {
            handleSubmitError("Bitte akzeptiere die Datenschutzerklärung, um fortzufahren.");
            return;
        }
        setError(null);
        register();
    };

    const handleSubmitError = (errorString: string) => {
        setError(errorString);
        onMessage(errorString);
    };

    const register = async () => {
        try {
            setIsLoading(true);
            const response = await Firebase.instance().acceptMailInvitation(props.token, password1);
            if (response.data.newCompanyId) {
                await Firebase.instance().doSignInWithEmailAndPassword(props.email.trim(), password1);
                history.push(ROUTES.HOME);
            } else {
                recordError("Server response incomplete (no newCompanyId)", { responseData: response.data });
                onMessage("Fehler beim Registrieren. Bitte versuche es später erneut.");
            }
        } catch (error: any) {
            if (error && error.code === "already-exists") {
                onMessage("Es existiert bereits ein Zugang mit dieser Mailadresse.", "warning");
            }
            recordError("Error during signup", error);
            onMessage("Fehler beim Registrieren. Bitte versuche es später erneut.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>Account erstellen</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <form onSubmit={handleSubmit}>
                    <SubmitFormOnEnter />
                    <FaItemGroup>
                        <Item.Root>
                            <Item.Label>Betrieb</Item.Label>
                            <Item.Content>
                                <span>{props.company}</span>
                            </Item.Content>
                        </Item.Root>
                        <Item.Root>
                            <Item.Label>E-Mail</Item.Label>
                            <Item.Content>
                                <span>{props.email}</span>
                            </Item.Content>
                        </Item.Root>
                        <Item.Root>
                            <Item.Label>Passwort</Item.Label>
                            <Item.Content>
                                <Input
                                    type="password"
                                    placeholder="Passwort"
                                    value={password1}
                                    onChange={event => {
                                        setPassword1(event.currentTarget.value);
                                    }}
                                />
                            </Item.Content>
                        </Item.Root>
                        <Item.Root>
                            <Item.Label>Passwort wiederholen</Item.Label>
                            <Item.Content>
                                <Input
                                    type="password"
                                    placeholder="Passwort wiederholen"
                                    value={password2}
                                    onChange={event => {
                                        setPassword2(event.currentTarget.value);
                                    }}
                                />
                            </Item.Content>
                        </Item.Root>
                    </FaItemGroup>

                    <div className={"signup-form__checkbox-input ion-margin"}>
                        <IonCheckbox checked={agbChecked} onIonChange={event => setAgbChecked(event.detail.checked)} />
                        <IonLabel>
                            Ich habe die{" "}
                            <span
                                className={"link"}
                                onClick={() => openLink("https://www.farmact.de/vertragsbedingungen")}>
                                allgemeinen Geschäftsbedingungen
                            </span>{" "}
                            gelesen und erkläre mich damit einverstanden.
                        </IonLabel>
                    </div>
                    <div className={"signup-form__checkbox-input ion-margin"}>
                        <IonCheckbox
                            checked={privacyChecked}
                            onIonChange={event => setPrivacyChecked(event.detail.checked)}
                        />
                        <IonLabel>
                            Ich habe die{" "}
                            <span
                                className={"link"}
                                onClick={() => openLink("https://www.farmact.de/datenschutzerklaerung")}>
                                Datenschutzerklärung
                            </span>{" "}
                            gelesen und erkläre mich damit einverstanden.
                        </IonLabel>
                    </div>
                    {error && (
                        <IonText color={"secondary"} className={"ion-margin"}>
                            {error}
                        </IonText>
                    )}
                </form>
            </IonCardContent>

            <IonFooter>
                <IonToolbar>
                    <IonButtons slot="end">
                        {isLoading && <IonSpinner color="primary" />}
                        <IonButton color="primary" type="submit" onClick={handleSubmit} disabled={isLoading}>
                            Registrieren
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>
        </IonCard>
    );
};
