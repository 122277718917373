import { lazy } from "react";
import { DefaultPage } from "../../structure/Page/DefaultPage/DefaultPage";
import { withAuthorization } from "../../authentication/Session/withAuthorization";
import { loggedIn } from "../../authentication/authorizations";

const AccountPageContent = lazy(() => import("./AccountPageContent"));

function AccountPageInternal() {
    return (
        <DefaultPage>
            <AccountPageContent />
        </DefaultPage>
    );
}

export const AccountPage = withAuthorization(loggedIn)(AccountPageInternal);
