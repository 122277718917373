import { IonButton, IonButtons, IonContent, IonFooter, IonToolbar } from "@ionic/react";
import React from "react";
import { StandardModalHeader } from "../../../../structure/StandardModalHeader/StandardModalHeader";
import { StopOrderDriverQueriesModalContent } from "../../OrderDriverQueriesModalContent/OrderDriverQueriesModalContent";
import { OrderMachineCountersModalContent } from "../../OrderMachineCountersModalContent/OrderMachineCountersModalContent";
import { OrderStopModalContent } from "../../OrderStopModalContent/OrderStopModalContent";
import { OrderTaskRecordsModalContent } from "../../OrderTaskRecordsModalContent/OrderTaskRecordsModalContent";
import { useStopOrderContext } from "../StopOrderContext/StopOrderContext";
import { StopOrderPayload } from "../useStopOrder";
import { AskForConfirmationSlide } from "./AskForConfirmationSlide/AskForConfirmationSlide";
import "./stopOrderModalContent.scss";
import { StopOrderPayloadCandidate, useStopOrderValidation } from "./useStopOrderValidation";
import { StopOrderView, UseStopOrderViewsReturn, useStopOrderViews } from "./useStopOrderViews";

export interface IStopOrderModalContentProps {
    onDismiss: () => void;
    onStop: (payload: StopOrderPayload) => void;
    askForConfirmation: boolean;

    /**
     * @default "Auftrag stoppen"
     */
    title?: string;
}

export function StopOrderModalContent(props: IStopOrderModalContentProps) {
    const { title = "Auftrag stoppen" } = props;

    const {
        order,
        driverQueries,
        setDriverQueries,
        note,
        setNote,
        machineCounterValues,
        setMachineCounterValues,
        taskRecords,
        setTaskRecords,
    } = useStopOrderContext();

    const views = useStopOrderViews(props.askForConfirmation);
    const validation = useStopOrderValidation(views);

    const previousOrDismiss = () => {
        if (views.empty) {
            props.onDismiss();
        } else if (views.isFirst) {
            props.onDismiss();
        } else {
            views.previous();
        }
    };
    const nextOrStop = () => {
        const payloadCandidate: StopOrderPayloadCandidate = {
            driverQueries,
            note: order.serviceSnapshot?.queryNote ? note : null,
            taskRecords,
            machineCounterValues,
        };

        if (views.empty || views.isLast) {
            stopOrder(payloadCandidate);
        } else {
            if (!validation.validateView(views.active!, payloadCandidate)) {
                return;
            }

            views.next();
        }
    };

    const stopOrder = (payloadCandidate: StopOrderPayloadCandidate) => {
        const payload = validation.validateAll(payloadCandidate);
        if (!payload) {
            return;
        }

        props.onStop(payload);
    };

    return (
        <>
            <StandardModalHeader title={formatViewModalTitle(title, views.active)} onCloseClick={props.onDismiss} />

            <IonContent>
                {views.empty && views.active === null && <OrderStopModalContent onStop={props.onStop} />}

                {views.active === StopOrderView.CONFIRMATION && <AskForConfirmationSlide />}

                {views.active === StopOrderView.DRIVER_QUERIES && (
                    <StopOrderDriverQueriesModalContent
                        allDriverQueries={driverQueries}
                        onDriverQueriesChange={setDriverQueries}
                        queryNote={!!order.serviceSnapshot?.queryNote}
                        note={note}
                        onNoteChange={setNote}
                    />
                )}

                {views.active === StopOrderView.MACHINE_COUNTERS && (
                    <OrderMachineCountersModalContent
                        machineCounters={machineCounterValues}
                        onMachineCountersChange={setMachineCounterValues}
                    />
                )}

                {views.active === StopOrderView.TASK_RECORDS && (
                    <OrderTaskRecordsModalContent taskRecords={taskRecords} onTaskRecordsChange={setTaskRecords} />
                )}
            </IonContent>

            <IonFooter>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="dark" onClick={previousOrDismiss}>
                            {getPreviousButtonText(views)}
                        </IonButton>
                    </IonButtons>

                    <IonButtons slot="end">
                        <IonButton
                            color="primary"
                            onClick={() => {
                                nextOrStop();
                            }}>
                            {getNextButtonText(views)}
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>
        </>
    );
}

function formatViewModalTitle(title: string, view: StopOrderView | null): string {
    switch (view) {
        case StopOrderView.DRIVER_QUERIES:
            return `${title} - Fahrerabfragen`;
        case StopOrderView.MACHINE_COUNTERS:
            return `${title} - Maschinenzähler`;
        case StopOrderView.TASK_RECORDS:
            return `${title} - Zusammenfassung`;
        default:
            return title;
    }
}

function getPreviousButtonText(views: UseStopOrderViewsReturn): React.ReactNode {
    if (views.empty) {
        return "Abbrechen";
    }
    if (views.isFirst) {
        return "Abbrechen";
    }

    return "Zurück";
}

function getNextButtonText(views: UseStopOrderViewsReturn): React.ReactNode {
    if (views.empty) {
        return "Stoppen";
    }
    if (views.isLast) {
        return "Stoppen";
    }

    return "Weiter";
}
