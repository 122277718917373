import { useMemo } from "react";
import { IAppCompanyContext } from "../../components/authentication/AppCompanyContext";
import { Firebase } from "../../firebase";
import { useCollectionData, useDocumentData } from "../../firebase/dataHooks";
import { useCompanyId } from "./useCompanyId";

export function useFetchAppCompanyContextData(): {
    appCompanyContext: IAppCompanyContext;
    loading: boolean;
    error: boolean;
    } {
    const companyId = useCompanyId();

    const [operatingUnitsIncludingArchived, operatingUnitsLoading, operatingUnitsError] = useCollectionData(
        companyId ? Firebase.instance().getAllOperatingUnits(true) : undefined,
        [companyId]
    );

    const operatingUnits = useMemo(() => {
        return operatingUnitsIncludingArchived.filter(unit => unit.archived === false);
    }, [operatingUnitsIncludingArchived]);

    const [appCompany, appCompanyLoading, appCompanyError] = useDocumentData(
        companyId ? Firebase.instance().getAppCompanyRef(companyId) : undefined,
        [companyId]
    );

    const [companyMeta, companyMetaLoading, companyMetaError] = useDocumentData(
        companyId ? Firebase.instance().getCompanyMeta(companyId) : undefined,
        [companyId]
    );

    const defaultOperatingUnit = operatingUnits.find(unit => unit.id === appCompany?.defaultUnitId);

    const anythingLoading = companyMetaLoading || appCompanyLoading || operatingUnitsLoading;
    const error = !!appCompanyError || !!operatingUnitsError || !!companyMetaError;

    return {
        appCompanyContext: {
            appCompany,
            companyMeta,
            operatingUnits,
            operatingUnitsIncludingArchived,
            defaultOperatingUnit,
        },
        loading: anythingLoading,
        error,
    };
}
