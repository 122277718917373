type Libraries = ("drawing" | "geometry" | "localContext" | "places" | "visualization")[];

export const mapsLibraries: Libraries = ["drawing", "places"];

export const defaultMapStyles: google.maps.MapTypeStyle[] = [
    {
        featureType: "poi",
        stylers: [{visibility: "off"}]
    },
    {
        featureType: "water",
        elementType: "labels",
        stylers: [{visibility: "off"}]
    },
    {
        featureType: "transit",
        stylers: [{visibility: "off"}]
    }
];

export const MAX_ZOOM_LEVEL_WHEN_FOCUSING = 16;

export const MAX_ZOOM_LEVEL_FOR_CLUSTERING = 13;

export const DEFAULT_ZOOM_LEVEL = 12;