import React, { createContext, useContext } from "react";
import { AnyDriverQuery, Order, TaskRecord } from "@farmact/model/src/model/Order";
import { MachineCounterValue } from "../../MachineCounters/useMachineCounterGroups";
import { InitializeStopOrderContextMachineCountersError } from "./useInitializeStopOrderContextMachineCounters";
import { InitializeStopOrderContextTaskRecordsError } from "./useInitializeStopOrderContextTaskRecords";
import { OrderFlowReadyState } from "../../orderFlowReadyState";
import { useStopOrderContextValues } from "../useStopOrderContextValues";

interface IStopOrderContextProps {
    /**
     * Order that should be started
     */
    order: Order;
    //
    readyState: OrderFlowReadyState;
    //
    driverQueries: AnyDriverQuery[];
    setDriverQueries: (driverQueries: AnyDriverQuery[]) => void;
    note: string;
    setNote: (newNote: string) => void;
    //
    machineCounterValues: MachineCounterValue[];
    setMachineCounterValues: (machineCounterValues: MachineCounterValue[]) => void;
    //
    taskRecords: TaskRecord[];
    setTaskRecords: (taskRecords: TaskRecord[]) => void;
}

export const _StopOrderContext = createContext<IStopOrderContextProps | null>(null);

export function useStopOrderContext(): IStopOrderContextProps {
    const context = useContext(_StopOrderContext);

    if (!context) {
        throw new Error("useStopOrderContext must be used inside a <StopOrderContext.Provider>");
    }

    return context;
}

interface IProvideStopOrderContextProps {
    orderId: Order["id"];
    enabled: boolean;

    switch?: ProvideStopOrderContextSwitch;

    children: React.ReactNode;
}

export const ProvideStopOrderContext = (props: IProvideStopOrderContextProps) => {
    const {
        driverQueries,
        setDriverQueries,
        note,
        setNote,
        machineCounterValues,
        setMachineCounterValues,
        taskRecords,
        setTaskRecords,
        order,
        readyState,
        initializationError,
    } = useStopOrderContextValues({ orderId: props.orderId, enabled: props.enabled });

    if (OrderFlowReadyState.ERROR && initializationError) {
        return <>{props.switch?.[OrderFlowReadyState.ERROR]?.(initializationError) ?? null}</>;
    }

    if (readyState === OrderFlowReadyState.INITIALIZING) {
        return <>{props.switch?.[OrderFlowReadyState.INITIALIZING] ?? null}</>;
    }

    if (readyState === OrderFlowReadyState.READY && order) {
        return (
            <_StopOrderContext.Provider
                value={{
                    order,
                    readyState,
                    //
                    driverQueries,
                    setDriverQueries,
                    note,
                    setNote,
                    //
                    machineCounterValues,
                    setMachineCounterValues,
                    //
                    taskRecords,
                    setTaskRecords,
                }}>
                {props.children}
            </_StopOrderContext.Provider>
        );
    }

    return null;
};

interface ProvideStopOrderContextSwitch {
    [OrderFlowReadyState.INITIALIZING]?: React.ReactNode;
    [OrderFlowReadyState.ERROR]?: (error: StopOrderContextInitializationError) => React.ReactNode;
}

type StopOrderContextInitializationError =
    | InitializeStopOrderContextMachineCountersError
    | InitializeStopOrderContextTaskRecordsError;

export const defaultStopOrderContext: IStopOrderContextProps = {
    readyState: OrderFlowReadyState.READY,
    order: new Order(),
    driverQueries: [],
    setDriverQueries: () => { },
    note: "",
    setNote: () => { },
    machineCounterValues: [],
    setMachineCounterValues: () => { },
    taskRecords: [],
    setTaskRecords: () => { },
};
