import { getCountFromServer, Query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { usePageIsVisible } from "../usePageIsVisible";

export function useCount(query?: Query, deps: React.DependencyList = []) {
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(null);

    const isPageVisible = usePageIsVisible();

    useEffect(() => {
        if (!isPageVisible) {
            return;
        }
        if (!query) {
            setCount(0);
            setLoading(false);
            return;
        }
        (async function countDocuments() {
            try {
                setLoading(true);
                const countResult = await getCountFromServer(query);
                setCount(countResult.data().count);
            } catch (e) {
                setError(e);
                setCount(0);
            } finally {
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps, isPageVisible]);

    return [count, loading, error] as const;
}
