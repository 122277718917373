// rewrite OpenAPI BASE URL during development
import { OpenAPI as TrackerApi } from "@farmact/tracker-api-client";
import { environment } from "../main/config/config";

// must be equal to the value in vite.config.ts
const localTrackerApiPath = "/localApi/trackers";

export function setupTrackerApi() {
    // set installed client version as default header for every request
    // this can be used on API side to allow or reject deprecated clients

    if (environment == "local") {
        TrackerApi.BASE = localTrackerApiPath;
    }

    if (environment === "dev" || environment === "alpha") {
        TrackerApi.BASE = "https://dev.api.telemetry.farmact.de";
    }

    if (environment === "beta") {
        TrackerApi.BASE = "https://beta.api.telemetry.farmact.de";
    }

    if (environment === "prod") {
        TrackerApi.BASE = "https://api.telemetry.farmact.de";
    }
}
