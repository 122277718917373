import React from "react";
import { PositionCallback } from "../../util/customHooks/geolocationHooks/useGeolocation";

interface IGeolocationContext {
    watchGeolocation: (positionCallback: PositionCallback, continueInBackground?: boolean) => () => void;
    openDeviceGeolocationSettings: () => void;
}

export const GeolocationContext: React.Context<IGeolocationContext> = React.createContext<IGeolocationContext>({
    watchGeolocation: () => () => { },
    openDeviceGeolocationSettings: () => { },
});