import { ServiceSnapshot } from "@farmact/model/src/model/Order";
import { ServicePriceUnit } from "@farmact/model/src/model/services/Service";

export function getAreaUnit(serviceSnapshot: ServiceSnapshot | null) {
    if (serviceSnapshot) {
        let value: ServicePriceUnit | undefined = undefined;
        for (const priceBlock of serviceSnapshot.calculatedServicePriceBlocks) {
            if (priceBlock.basis === ServicePriceUnit.HECTARE) {
                return ServicePriceUnit.HECTARE;
            } else if (priceBlock.basis === ServicePriceUnit.SQUAREMETER) {
                value = ServicePriceUnit.SQUAREMETER;
            }
        }
        for (const priceBlock of serviceSnapshot.calculatedResourcePriceBlocks) {
            if (priceBlock.basis === ServicePriceUnit.HECTARE) {
                return ServicePriceUnit.HECTARE;
            } else if (priceBlock.basis === ServicePriceUnit.SQUAREMETER) {
                value = ServicePriceUnit.SQUAREMETER;
            }
        }
        return value;
    }
}

export function getTracksUnit(serviceSnapshot: ServiceSnapshot | null) {
    if (serviceSnapshot) {
        for (const priceBlock of serviceSnapshot.calculatedServicePriceBlocks) {
            if (priceBlock.basis === ServicePriceUnit.KILOMETER) {
                return ServicePriceUnit.KILOMETER;
            } else if (priceBlock.basis === ServicePriceUnit.METER) {
                return ServicePriceUnit.METER;
            }
        }
        for (const priceBlock of serviceSnapshot.calculatedResourcePriceBlocks) {
            if (priceBlock.basis === ServicePriceUnit.KILOMETER) {
                return ServicePriceUnit.KILOMETER;
            } else if (priceBlock.basis === ServicePriceUnit.METER) {
                return ServicePriceUnit.METER;
            }
        }
    }
}
