import { IonButton, IonButtons, IonContent, IonFooter, IonToolbar } from "@ionic/react";
import React, { useContext, useState } from "react";
import { StandardModalHeader } from "../../../../structure/StandardModalHeader/StandardModalHeader";
import "./internalWorkNoteModal.scss";
import { useValidation } from "../../../../../util/customHooks/validation/useValidation";
import { ToastContext } from "../../../../ToastContext";
import { FarmActModal } from "../../../../FarmActModal/FarmActModal";
import * as FormControl from "../../../../inputs/FormControl";
import { Textarea } from "../../../../inputs/Textarea/Textarea";

export interface IInternalWorkNoteModalProps {
    isOpen: boolean;
    onSubmit: (note: string) => void;
    onDismiss: () => void;
    onClick?: React.MouseEventHandler<HTMLIonModalElement>;
}

export const InternalWorkNoteModal = (props: IInternalWorkNoteModalProps) => {
    const [note, setNote] = useState("");
    const { onMessage } = useContext(ToastContext);

    const noteValidation = useValidation(note, note => {
        if (!note) {
            return "invalid";
        }
    });

    const handleSubmit = () => {
        if (!noteValidation.validate()) {
            onMessage("Trage bitte eine Notiz ein.", "warning");
            return;
        }
        props.onSubmit(note);
        setNote("");
    };

    const handleDismiss = () => {
        props.onDismiss();
        setNote("");
    };

    return (
        <FarmActModal
            isOpen={props.isOpen}
            onDidDismiss={handleDismiss}
            className="internal-work-note-modal"
            onClick={props.onClick}>
            <StandardModalHeader title="Interne Arbeit stoppen" onCloseClick={props.onDismiss} />
            <IonContent>
                <div className="internal-work-note-modal__container">
                    <div className="internal-work-note-modal__text">
                        Bitte mache Angaben zur Arbeit, die gestoppt werden soll.
                    </div>
                    <FormControl.Root>
                        <FormControl.Label>Notiz</FormControl.Label>
                        <Textarea
                            placeholder="Notiz"
                            value={note}
                            onChange={event => {
                                setNote(event.currentTarget.value);
                            }}
                        />
                    </FormControl.Root>
                </div>
            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={props.onDismiss} color="dark">
                            Abbrechen
                        </IonButton>
                    </IonButtons>
                    <IonButtons slot="end">
                        <IonButton color="primary" onClick={handleSubmit}>
                            Stoppen
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>
        </FarmActModal>
    );
};
