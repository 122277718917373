import { IonAlert, IonContent, IonPage } from "@ionic/react";
import { useState } from "react";
import PasswordForgetModal from "../PasswordForgetModal/PasswordForgetModal";
import "./signInPage.scss";
import { RegistrationModal } from "../RegistrationModal/RegistrationModal";
import { SplashScreen } from "@capacitor/splash-screen";
import { LoginForm } from "./SignInPageForm";
import { SignInDecoration } from "./SignInPageDecoration";


export const SignInPage = () => {
    const [showPasswordForgetModal, setShowPasswordForgetModal] = useState(false);
    const [showRegistrationModal, setShowRegistrationModal] = useState(false);
    const [showSuspendedAlert, setShowSuspendedAlert] = useState(false);
    const [showInactiveAlert, setShowInactiveAlert] = useState(false);

    SplashScreen.hide();

    return (
        <>
            <IonPage>
                <IonContent>
                    <div className="login-page">
                        <div className="login-page__grey-panel" />
                        <div className="login-page__green-panel" />
                        <div className="login-page__container">
                            <div className="login-page__grid">
                                <div className="login-page__row">
                                    <div className="login-page__decoration ">
                                        <SignInDecoration />
                                    </div>
                                    <div className="login-page__form">
                                        <LoginForm
                                            setShowSuspendedAlert={setShowSuspendedAlert}
                                            setShowPasswordForgetModal={setShowPasswordForgetModal}
                                            setShowRegistrationModal={setShowRegistrationModal}
                                            setShowInactiveAlert={setShowInactiveAlert} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
            </IonPage>

            <PasswordForgetModal
                showModal={showPasswordForgetModal}
                onDidDismiss={() => setShowPasswordForgetModal(false)}
            />
            <RegistrationModal isOpen={showRegistrationModal} onDidDismiss={() => setShowRegistrationModal(false)} />

            <IonAlert
                isOpen={showSuspendedAlert}
                onDidDismiss={() => setShowSuspendedAlert(false)}
                header="Zugang gesperrt"
                message="Der Zugang wurde für dein Unternehmen deaktiviert. Bitte wende dich an info@farmact.de, um das Problem zu beheben."
                buttons={[
                    {
                        role: "confirm",
                        text: "Ok",
                        handler: () => {
                            location.reload();
                        },
                    },
                ]}
            />
            <IonAlert
                isOpen={showInactiveAlert}
                onDidDismiss={() => setShowInactiveAlert(false)}
                header="Deaktiviert"
                message="Dein Zugang wurde vom Betriebsleiter deaktiviert."
                buttons={[
                    {
                        role: "confirm",
                        text: "Ok",
                        handler: () => {
                            location.reload();
                        },
                    },
                ]}
            />
        </>
    );
};
