import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { withAuthorization } from "../../../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../../../authentication/authorizations";
import { DefaultPage } from "../../../structure/Page/DefaultPage/DefaultPage";

const DeliveryNotesArchivePageContent = lazy(() => import("./DeliveryNotesArchivePageContent"));

function DeliveryNotesArchivePageInternal() {
    return (
        <DefaultPage>
            <DeliveryNotesArchivePageContent />
        </DefaultPage>
    );
}

export const DeliveryNotesArchivePage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN))(
    DeliveryNotesArchivePageInternal
);
