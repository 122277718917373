import { lazy } from "react";
import { DefaultPage } from "../structure/Page/DefaultPage/DefaultPage";

const OrdersProgressSharingPageContent = lazy(() => import("./OrdersProgressSharingPageContent"));

export function OrdersProgressSharingPage() {
    return (
        <DefaultPage>
            <OrdersProgressSharingPageContent />
        </DefaultPage>
    );
}
