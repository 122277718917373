import { withAuthorization } from "../../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../../authentication/authorizations";
import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { DefaultPage } from "../../structure/Page/DefaultPage/DefaultPage";

const DispositionPageContent = lazy(() => import("./DispositionPageContent"));

function DispositionPageInternal() {
    return (
        <DefaultPage>
            <DispositionPageContent />
        </DefaultPage>
    );
}

export const DispositionPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.PLANNER))(
    DispositionPageInternal
);
