import { AppUser } from "@farmact/model/src/model/AppUser";
import { Firebase } from "../../firebase";
import { appVersion } from "../../../config/config";
import { useEffect } from "react";

export function useReportAppVersion(appUserId: AppUser["id"] | undefined) {
    useEffect(() => {
        if (!appUserId) {
            return;
        }
        Firebase.instance().updateAppUser(appUserId, {
            lastSeenAppVersion: appVersion,
        });
    }, [appUserId]);
}
