import { isPlatform } from "@ionic/react";
import { Browser } from "@capacitor/browser";
import { isIOSApp } from "./isIOSApp";

export function openLink(url: string) {
    if (isIOSApp()) {
        // Apple requires apps to open links in in-app-browsers
        Browser.open({ url });
    } else if (isPlatform("mobile")) {
        window.open(url, "_system", "location=yes");
        return false;
    } else {
        window.open(url, "_blank");
        return false;
    }
}
