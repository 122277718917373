import { appVersion, bugSnagApiKey, environment } from "../main/config/config";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import Firebase from "../main/common/firebase/firebase";
import React from "react";
import { getAuth } from "firebase/auth";

export function setupErrorBoundary() {
    let ErrorBoundary: React.ComponentType<React.PropsWithChildren<unknown>> = (props: { children?: React.ReactNode }) => {
        return <>{props.children}</>;
    };

    if (["beta"].includes(environment) && bugSnagApiKey) {
        Bugsnag.start({
            apiKey: bugSnagApiKey,
            appVersion: `${appVersion.major}.${appVersion.minor}.${appVersion.patch}`,
            releaseStage: environment,
            plugins: [new BugsnagPluginReact()],
            onError: event => {
                if (window.disableBugsnag) {
                    return false;
                }
                event.setUser(
                    getAuth().currentUser?.uid,
                    getAuth().currentUser?.email ?? undefined,
                    getAuth().currentUser?.displayName ?? undefined
                );
                event.addMetadata("companyId", { companyId: Firebase.instance().getUsersCompanyId() });
                console.error("Sending to Bugsnag. Original error:", event.originalError);
            },
        });

        const boundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React);
        if (boundary) {
            ErrorBoundary = boundary;
        }
    }

    return ErrorBoundary;
}