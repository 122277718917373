import { withAuthorization } from "../../../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../../../authentication/authorizations";
import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { DefaultPage } from "../../../structure/Page/DefaultPage/DefaultPage";

const CreateOfferPageContent = lazy(() => import("./CreateOfferPageContent"));

function CreateOfferPageInternal() {
    return (
        <DefaultPage>
            <CreateOfferPageContent />
        </DefaultPage>
    );
}

export const CreateOfferPage = withAuthorization(
    getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.PLANNER, Role.EMPLOYEE, Role.TEMPORARY_WORKER)
)(CreateOfferPageInternal);
