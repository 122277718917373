import {LiquidMixture} from "@farmact/model/src/model/LiquidMixture";

export function constructResourceVariantIdentifier(resourceId: string, variantId: string) {
    return `${resourceId}-${variantId}`;
}

export function constructLiquidMixtureVariantIdentifier(liquidMixtureId: LiquidMixture["id"]) {
    return `${liquidMixtureId}-${LIQUID_MIXTURE_VARIANT_ID}`;
}

export const LIQUID_MIXTURE_VARIANT_ID = "LIQUID_MIXTURE";
