import * as Routes from "@farmact/model/src/constants/farmActAppRoutes";
import { SubMenu } from "../SubMenu/SubMenu";
import { SubMenuItem } from "../SubMenuItem/SubMenuItem";
import "./receiptsMenu.scss";

interface IReceiptsMenuProps {
    isOpen?: boolean;
    isInSplitPane: boolean;
}

export const ReceiptsMenu = (props: IReceiptsMenuProps) => {
    return (
        <SubMenu isOpen={props.isOpen} cssClass="receipts-menu">
            <SubMenuItem isInSplitPane={props.isInSplitPane} name={"Rechnungen"} route={Routes.BILLS} />
            <SubMenuItem isInSplitPane={props.isInSplitPane} name={"Lieferscheine"} route={Routes.DELIVERY_NOTES} />
            <SubMenuItem isInSplitPane={props.isInSplitPane} name={"Angebote"} route={Routes.OFFERS} />
            <SubMenuItem isInSplitPane={props.isInSplitPane} name={"Gutschriften"} route={Routes.CREDIT_NOTES} />
        </SubMenu>
    );
};
