import { withAuthorization } from "../../../../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../../../../authentication/authorizations";
import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { DefaultPage } from "../../../../structure/Page/DefaultPage/DefaultPage";

const EditBillPageContent = lazy(() => import("./EditBillPageContent"));

function EditBillPageInternal() {
    return (
        <DefaultPage>
            <EditBillPageContent />
        </DefaultPage>
    );
}

export const EditBillPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN))(EditBillPageInternal);
