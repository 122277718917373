import { IonButton, IonButtons, IonContent, IonFooter, IonInput, IonText, IonToolbar } from "@ionic/react";
import React, { useState } from "react";
import { Firebase } from "../../../firebase";
import { SubmitFormOnEnter } from "../../inputs/SubmitFormOnEnter";
import { StandardModalHeader } from "../../structure/StandardModalHeader/StandardModalHeader";
import { getMessageForAuthError } from "../SignIn/util/signinUtils";
import "./passwordForgetModal.scss";
import { FarmActModal } from "../../FarmActModal/FarmActModal";

const PasswordForgetModal = (props: PasswordForgetModalProps) => {
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState<any>();
    const [linkSent, setLinkSent] = useState(false);

    const handleDidDismiss = () => {
        setEmail("");
        setLinkSent(false);
        props.onDidDismiss();
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            await Firebase.instance().doPasswordReset(email);
            setLinkSent(true);
        } catch (error: any) {
            setErrorMessage(getMessageForAuthError(error));
        }
    };

    return (
        <FarmActModal isOpen={props.showModal} onDidDismiss={() => handleDidDismiss()} className="password-forget-modal">
            <div className="default-modal-container">
                <StandardModalHeader title={"Passwort zurücksetzen"} onCloseClick={handleDidDismiss} />
                <IonContent>
                    {linkSent ? (
                        <div>
                            <h3 className="ion-padding ion-text-center">
                                Wir haben Dir eine Mail zum Zurücksetzen Deines Passworts an {email} geschickt!
                            </h3>
                            <p className="ion-padding ion-text-center">
                                Falls Du nichts erhalten hast, schau bitte im Spam-Ordner nach und vergewissere Dich,
                                dass Du die richtige Email-Adresse eingegeben hast.
                            </p>
                        </div>
                    ) : (
                        <form className="ion-padding" onSubmit={handleSubmit}>
                            <SubmitFormOnEnter />
                            <h3 className="ion-padding ion-text-center">Du hast Dein Passwort vergessen?</h3>
                            <p className="ion-padding ion-text-center">
                                Kein Problem! Gib einfach Deine Email-Adresse unten ein und wir schicken Dir eine Mail
                                zum Zurücksetzen Deines Passworts.
                            </p>
                            <IonInput
                                className="ion-padding ion-text-center"
                                name="email"
                                onIonInput={e => setEmail(e.detail.value ?? "")}
                                placeholder="Email-Addresse"
                                required={true}
                                type="email"
                                value={email}
                            />
                            {errorMessage && (
                                <IonText className="ion-text-center" color="secondary">
                                    {errorMessage}
                                </IonText>
                            )}
                        </form>
                    )}
                </IonContent>
                <IonFooter>
                    <IonToolbar>
                        {linkSent ? (
                            <IonButtons slot="end">
                                <IonButton color="primary" onClick={() => handleDidDismiss()}>
                                    Alles klar!
                                </IonButton>
                            </IonButtons>
                        ) : (
                            <>
                                <IonButtons slot="start">
                                    <IonButton color="white" onClick={() => props.onDidDismiss()}>
                                        Doch nicht
                                    </IonButton>
                                </IonButtons>
                                <IonButtons slot="end">
                                    <IonButton color="primary" onClick={handleSubmit}>
                                        Passwort zurücksetzen
                                    </IonButton>
                                </IonButtons>
                            </>
                        )}
                    </IonToolbar>
                </IonFooter>
            </div>
        </FarmActModal>
    );
};

interface PasswordForgetModalProps {
    showModal: boolean;
    onDidDismiss: () => void;
}

export default PasswordForgetModal;
