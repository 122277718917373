import { useMemo } from "react";
import { Resource } from "@farmact/model/src/model/Resource";
import { ResourceVariantIdentifier } from "@farmact/model/src/model/ResourcePrice";
import { constructResourceName } from "../../../../util/resourceUtils";
import { constructLiquidMixtureVariantIdentifier, constructResourceVariantIdentifier } from "./utils";
import { SelectItem } from "@mantine/core";
import { LiquidMixture } from "@farmact/model/src/model/LiquidMixture";

interface UseSelectableResourcesOptions {
    availableResources: Resource[];
    selectedResource?: ResourceVariantIdentifier | null;
    availableLiquidMixtures?: LiquidMixture[];
    allowCreatingLiquidMixture?: boolean;
}

export function useResourceSelectItems(options: UseSelectableResourcesOptions): SelectItem[] {
    const { availableResources, selectedResource, availableLiquidMixtures, allowCreatingLiquidMixture } = options;

    return useMemo(() => {
        const resourceOptions: SelectItem[] = availableResources.map(resource => ({
            value: constructResourceVariantIdentifier(resource.id, resource.price.id),
            label: resource.name,
        }));

        const isCurrentSelectable = selectedResource
            ? !!resourceOptions.find(option => {
                return (
                    option.value ===
                      constructResourceVariantIdentifier(selectedResource?.resourceId, selectedResource?.variantId)
                );
            })
            : true;

        if (selectedResource && !isCurrentSelectable) {
            const selectedResourceData = availableResources.find(
                resource => resource.id === selectedResource.resourceId
            );

            if (selectedResourceData && selectedResource) {
                resourceOptions.push({
                    value: constructResourceVariantIdentifier(selectedResourceData.id, selectedResource.variantId),
                    label: constructResourceName(selectedResourceData, selectedResource.variantId),
                });
            }
        }

        const mixtureOptions = (availableLiquidMixtures ?? []).map(mixture => ({
            value: constructLiquidMixtureVariantIdentifier(mixture.id),
            label: mixture.name,
        }));
        resourceOptions.push(...mixtureOptions);

        if (allowCreatingLiquidMixture) {
            resourceOptions.unshift(ADD_NEW_LIQUID_MIXTURE_OPTION);
        }
        return resourceOptions;
    }, [availableResources, allowCreatingLiquidMixture, availableLiquidMixtures, selectedResource]);
}

export const ADD_NEW_LIQUID_MIXTURE_OPTION_VALUE = "ADD_NEW_LIQUID_MIXTURE_OPTION_VALUE";

const ADD_NEW_LIQUID_MIXTURE_OPTION: SelectItem = {
    label: "+ Neue Spritzmischung",
    value: ADD_NEW_LIQUID_MIXTURE_OPTION_VALUE,
};