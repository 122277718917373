import { withAuthorization } from "../../../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../../../authentication/authorizations";
import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { DefaultPage } from "../../../structure/Page/DefaultPage/DefaultPage";

const EmployeesTimeTrackingsContent = lazy(() => import("./EmployeesTimeTrackingsPageContent"));

function EmployeesTimeTrackingsInternal() {
    return (
        <DefaultPage>
            <EmployeesTimeTrackingsContent />
        </DefaultPage>
    );
}

export const EmployeesTimeTrackingsPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN))(
    EmployeesTimeTrackingsInternal
);
