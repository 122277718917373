import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { withAuthorization } from "../../../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../../../authentication/authorizations";
import { DefaultPage } from "../../../structure/Page/DefaultPage/DefaultPage";

const DeliveryNoteSettingsPageContent = lazy(() => import("./DeliveryNoteSettingsPageContent"));

function DeliveryNoteSettingsPageInternal() {
    return (
        <DefaultPage>
            <DeliveryNoteSettingsPageContent />
        </DefaultPage>
    );
}

export const DeliveryNoteSettingsPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN))(
    DeliveryNoteSettingsPageInternal
);
