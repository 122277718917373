import { withAuthorization } from "../../../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../../../authentication/authorizations";
import { Role } from "@farmact/model/src/model/Role";
import { DefaultPage } from "../../../structure/Page/DefaultPage/DefaultPage";
import { lazy } from "react";

const EditCreditNotePageContent = lazy(() => import("./EditCreditNotePageContent"));

function EditCreditNotePageInternal() {
    return (
        <DefaultPage>
            <EditCreditNotePageContent />
        </DefaultPage>
    );
}

export const EditCreditNotePage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN))(
    EditCreditNotePageInternal
);
