import { withAuthorization } from "../../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../../authentication/authorizations";
import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { DefaultPage } from "../../structure/Page/DefaultPage/DefaultPage";

const ReceiptMenuPageContent = lazy(() => import("./ReceiptMenuPageContent"));

function ReceiptMenuPageInternal() {
    return (
        <DefaultPage>
            <ReceiptMenuPageContent />
        </DefaultPage>
    );
}

export const ReceiptMenuPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN))(
    ReceiptMenuPageInternal
);
