import { withAuthorization } from "../../../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../../../authentication/authorizations";
import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { DefaultPage } from "../../../structure/Page/DefaultPage/DefaultPage";

const EditDeliveryNotePageContent = lazy(() => import("./EditDeliveryNotePageContent"));

function EditDeliveryNotePageInternal() {
    return (
        <DefaultPage>
            <EditDeliveryNotePageContent />
        </DefaultPage>
    );
}

export const EditDeliveryNotePage = withAuthorization(
    getAuthorizationGuard(
        Role.OWNER,
        Role.ADMIN,
        Role.PLANNER,
        Role.EMPLOYEE,
        Role.TEMPORARY_WORKER,
        Role.SUBCONTRACTOR
    )
)(EditDeliveryNotePageInternal);
