import { getMachineCounterTypeName } from "../../../../../../util/nameUtils/machineCounterTrackingNameUtils";
import { MachineCounterValueError } from "../MachineCounterValueError";
import { MachineCounterTrigger } from "../../MachineCounterTrigger";
import { MachineCounterValue } from "../../useMachineCounterGroups";
import * as Item from "../../../../../FaItem";
import { NumberInput } from "../../../../../inputs/NumberInput/NumberInput";

interface IMachineCounterInputItem {
    onChange: (valueId: string, updatedValue: number | null) => void;
    value: MachineCounterValue;
    error?: MachineCounterValueError;
}

export const MachineCounterInputItem = (props: IMachineCounterInputItem) => {
    let previousValue = undefined;
    if (props.value.previous) {
        previousValue =
            props.value.trigger === MachineCounterTrigger.START
                ? `Zuletzt: ${props.value.previous}`
                : `Vorher: ${props.value.previous}`;
    }

    return (
        <Item.Root error={props.error?.error}>
            <Item.Label>{getMachineCounterTypeName(props.value.type)}</Item.Label>
            <Item.Content>
                <NumberInput
                    step={0.1}
                    unit={previousValue}
                    currentValue={props.value.value}
                    onChange={updatedValue => props.onChange(props.value.id, updatedValue)}
                />
            </Item.Content>
        </Item.Root>
    );
};
