import { withAuthorization } from "../../../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../../../authentication/authorizations";
import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { DefaultPage } from "../../../structure/Page/DefaultPage/DefaultPage";

const EditOrderPageContent = lazy(() => import("./EditOrderPageContent"));

function EditOrderPageInternal() {
    return (
        <DefaultPage>
            <EditOrderPageContent />
        </DefaultPage>
    );
}

export const EditOrderPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.PLANNER))(
    EditOrderPageInternal
);
