import { withAuthorization } from "../../../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../../../authentication/authorizations";
import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { DefaultPage } from "../../../structure/Page/DefaultPage/DefaultPage";

const CreateCreditNotePageContent = lazy(() => import("./CreateCreditNotePageContent"));

function CreateCreditNotePageInternal() {
    return <DefaultPage>
        <CreateCreditNotePageContent />
    </DefaultPage>;
}

export const CreateCreditNotePage = withAuthorization(
    getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.PLANNER, Role.EMPLOYEE, Role.TEMPORARY_WORKER)
)(CreateCreditNotePageInternal);
