import { RoundingDirection, RoundingPrecision, RoundingSettings } from "@farmact/model/src/model/Rounding";

// TODO move to core / utils package
export function round(value: number, options?: RoundingOptions): number {
    let roundedAmount = value;
    if (options?.roundingSetting) {
        const roundingMethod = getRoundingMethod(options.roundingSetting.roundingDirection);

        switch (options.roundingSetting.roundingPrecision) {
            case RoundingPrecision.FULL:
                roundedAmount = roundingMethod(value);
                break;
            case RoundingPrecision.HALF:
                roundedAmount = roundingMethod(value * 2) / 2;
                break;
            case RoundingPrecision.QUARTER:
                roundedAmount = roundingMethod(value * 4) / 4;
                break;
        }
    }

    /* Why do we need this?
     * Some operations result in numerical errors. For example, if you do 244.54 - 205.5, you get 39.04499999999999 rather than 39.045.
     * If you then try to round 39.04499999999999 to 2 decimals the result would be 39.04
     * which is wrong!
     *
     * In order to prevent these rounding issues you can add a really small number resolves these 999 (period)
     * Some use Number.EPSILON (which is the smallest number technically possible) but it is too small to catch
     * all edge cases. Therefore 1e-10 is used.
     */
    const add = 1e-10;

    const factor = Math.pow(10, options?.decimalDigits ?? 0);

    return Math.round((roundedAmount + add) * factor) / factor;
}

function getRoundingMethod(roundingDirection: RoundingDirection) {
    if (roundingDirection === RoundingDirection.UP) {
        return Math.ceil;
    } else if (roundingDirection === RoundingDirection.DOWN) {
        return Math.floor;
    } else {
        return Math.round;
    }
}

export function roundToClosestMultiple(of: number, value: number): number {
    if (of === 0) {
        return 0;
    }

    const up = Math.ceil(value / of) * of;
    const down = Math.floor(value / of) * of;

    const upDiffToValue = up - value;
    const downDiffToValue = value - down;

    return upDiffToValue < downDiffToValue ? up : down;
}

export function numberRange(from: number, to: number): number[] {
    const values: number[] = [];

    for (let i = from; i <= to; i++) {
        values.push(i);
    }

    return values;
}

export function clamp(min: number, max: number, value: number): number {
    return Math.min(Math.max(min, value), max);
}

interface RoundingOptions {
    decimalDigits?: number;
    roundingSetting?: RoundingSettings;
}

export function randomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(min + Math.random() * (max - min));
}

export function getOrderOfMagnitude(value: number) {
    const betweenZeroAndOne = Math.abs(value) > 0 && Math.abs(value) < 1;
    let valueToCheck = betweenZeroAndOne ? 1 / value : value;

    let orderOfMagnitude = betweenZeroAndOne ? 1 : 0;
    while (Math.abs(valueToCheck) >= 10) {
        orderOfMagnitude++;
        valueToCheck /= 10;
    }
    return orderOfMagnitude * (betweenZeroAndOne ? -1 : 1);
}
