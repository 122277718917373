import { useCallback, useEffect, useRef, useState } from "react";
import { GEOLOCATION_OPTIONS } from "./util";
import { Geolocation, Position } from "@capacitor/geolocation";
import { v4 } from "uuid";
import { isPlatform, useIonAlert } from "@ionic/react";
import { Capacitor, registerPlugin } from "@capacitor/core";
import { BackgroundGeolocationPlugin } from "@capacitor-community/background-geolocation";
import dayjs from "dayjs";
import { useDocumentData } from "../../../firebase/dataHooks";
import { Firebase } from "../../../firebase";
import { recordError } from "../../recordError";

export type PositionCallback = (position: Position | null) => void;
type GeoCallbackMap = Map<string, { callBack: PositionCallback; continueInBackground: boolean }>;

function hasBackgroundListeners(callbackMap: GeoCallbackMap) {
    return (
        Array.from(callbackMap.values())
            .map(value => value.continueInBackground)
            .filter(Boolean).length > 0
    );
}

const BackgroundGeolocation = registerPlugin<BackgroundGeolocationPlugin>("BackgroundGeolocation");

/**
 * This hook is intended to be used only once: top level inside App.tsx. Do not use it anywhere else.
 * The whole point of this hook is to have only one geolocation listener. If you want to use geolocation
 * somewhere in the app, use GeolocationContext#watchGeolocation from the {@link GeolocationContext}.
 */
export function useGeolocation(backgroundGeolocationEnabled: boolean) {
    const [appMeta] = useDocumentData(Firebase.instance().getAppMeta());
    const positionWatcherId = useRef<string>();
    const backgroundGeolocationWatcherId = useRef<string>();
    const callBackMap = useRef<GeoCallbackMap>(
        new Map<string, { callBack: PositionCallback; continueInBackground: boolean }>()
    );
    const [gatherGeolocation, setGatherGeolocation] = useState(false);
    const [continueInBackground, setContinueInBackground] = useState(false);

    const [presentAlert] = useIonAlert();

    const watchGeolocation = useCallback(
        (positionCallback: PositionCallback, continueInBackground: boolean = false) => {
            const id = v4();
            callBackMap.current.set(id, { callBack: positionCallback, continueInBackground: continueInBackground });
            setGatherGeolocation(callBackMap.current.size > 0);
            setContinueInBackground(hasBackgroundListeners(callBackMap.current));

            return () => {
                callBackMap.current.delete(id);
                setGatherGeolocation(callBackMap.current.size > 0);
                setContinueInBackground(hasBackgroundListeners(callBackMap.current));
            };
        },
        []
    );

    useEffect(() => {
        if (!gatherGeolocation) {
            return;
        }

        let watcherId: number;
        if (
            appMeta?.featureFlags.backgroundGeolocation &&
            continueInBackground &&
            backgroundGeolocationEnabled &&
            Capacitor.isNativePlatform()
        ) {
            const setupBackgroundGeolocationWatcher = async () => {
                backgroundGeolocationWatcherId.current &&
                    (await BackgroundGeolocation.removeWatcher({ id: backgroundGeolocationWatcherId.current }));
                backgroundGeolocationWatcherId.current = undefined;
                backgroundGeolocationWatcherId.current = await BackgroundGeolocation.addWatcher(
                    {
                        backgroundMessage: "FarmAct zeichnet Fahrspuren auf",
                        backgroundTitle: "FarmAct GPS",
                        requestPermissions: true,
                        stale: false,
                        distanceFilter: 5,
                    },
                    (location, error) => {
                        if (error) {
                            if (error.code === "NOT_AUTHORIZED") {
                                // TODO: notify user somehow that they need to give permission
                            }
                            if (error.code !== "NOT_AUTHORIZED") {
                                recordError("Could not use background geolocation", { error: error });
                            }
                        }
                        if (location) {
                            Array.from(callBackMap.current.values()).forEach(callback =>
                                callback.callBack({
                                    timestamp: location.time ?? dayjs().unix(),
                                    coords: { ...location, heading: location.bearing },
                                })
                            );
                        }
                    }
                );
            };
            setupBackgroundGeolocationWatcher();
        } else if (isPlatform("android")) {
            watcherId = navigator.geolocation.watchPosition(
                position => {
                    Array.from(callBackMap.current.values()).forEach(callback => callback.callBack(position));
                },
                error => {
                    console.warn("error getting position", error.code, error.message);
                },
                GEOLOCATION_OPTIONS
            );
        } else {
            const setupPositionWatcher = async () => {
                positionWatcherId.current && (await Geolocation.clearWatch({ id: positionWatcherId.current }));
                positionWatcherId.current = await Geolocation.watchPosition(GEOLOCATION_OPTIONS, position => {
                    Array.from(callBackMap.current.values()).forEach(callback => callback.callBack(position));
                });
            };
            setupPositionWatcher();
        }

        return () => {
            if (watcherId) {
                navigator.geolocation.clearWatch(watcherId);
            }
            if (positionWatcherId.current) {
                Geolocation.clearWatch({ id: positionWatcherId.current }).then(() => {
                    positionWatcherId.current = undefined;
                });
            }
            if (backgroundGeolocationWatcherId.current) {
                BackgroundGeolocation.removeWatcher({ id: backgroundGeolocationWatcherId.current }).then(() => {
                    positionWatcherId.current = undefined;
                });
            }
        };
    }, [
        continueInBackground,
        backgroundGeolocationEnabled,
        gatherGeolocation,
        presentAlert,
        appMeta?.featureFlags.backgroundGeolocation,
    ]);

    return { watchGeolocation, openDeviceGeolocationSettings: BackgroundGeolocation.openSettings };
}
