import { withAuthorization } from "../../../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../../../authentication/authorizations";
import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { DefaultPage } from "../../../structure/Page/DefaultPage/DefaultPage";

const ResourceHistoryPageContent = lazy(() => import("./ResourceHistoryPageContent"));

function ResourceHistoryPageInternal() {
    return (
        <DefaultPage>
            <ResourceHistoryPageContent />
        </DefaultPage>
    );
}

export const ResourceHistoryPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN))(
    ResourceHistoryPageInternal
);
