import { IonPage, IonSpinner } from "@ionic/react";
import React, { Suspense } from "react";
import { Capacitor } from "@capacitor/core";
import { Hint } from "../../../hint/Hint";
import { ErrorBoundary } from "react-error-boundary";
import { recordError } from "../../../../util/recordError";
import "./defaultPage.scss";
import { useNetworkStatus } from "../../../../util/customHooks/useNetworkStatus";

interface IPageContentProps {
    children: React.ReactNode;
}

/**
 * Use this component as a root for pages. It handles page transition animations as well as error boundaries for lazily loaded content.
 * @param props
 * @constructor
 */
export function DefaultPage(props: IPageContentProps) {
    return (
        <IonPage>
            <ErrorBoundary
                fallback={<DefaultFallback />}
                onError={(error: any, info: any) => {
                    recordError("Caught error", { error, info });
                }}>
                <Suspense fallback={<IonSpinner />}>{props.children}</Suspense>
            </ErrorBoundary>
        </IonPage>
    );
}

function DefaultFallback() {
    const isOnline = useNetworkStatus();
    const message = (function () {
        if (Capacitor.isNativePlatform()) {
            return "Da hat etwas nicht funktioniert. Bitte starte die App neu.";
        }
        if (!isOnline) {
            return "Du hast gerade keine Internet-Verbindung. Bitte lade die Seite neu, sobald du wieder online bist.";
        }
        return "Da hat etwas nicht funktioniert. Bitte lade die Seite neu.";
    })();
    return (
        <Hint color="danger" severity="error" className="default-page__error-hint">
            {message}
        </Hint>
    );
}
