import { withAuthorization } from "../../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../../authentication/authorizations";
import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { DefaultPage } from "../../structure/Page/DefaultPage/DefaultPage";

const EmployeesPageContent = lazy(() => import("./EmployeesPageContent"));

function EmployeesPageInternal() {
    return <DefaultPage>
        <EmployeesPageContent />
    </DefaultPage>;
}

export const EmployeesPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN))(EmployeesPageInternal);
