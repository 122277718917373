import { useFetchOrganizationContextData } from "./useFetchOrganizationContextData";
import { IAppCompanyContext } from "../../components/authentication/AppCompanyContext";
import { IOrganizationContext } from "../../components/organization/context/OrganizationContext";
import { useFetchAppCompanyContextData } from "./useFetchAppCompanyContextData";

interface IUseLoadOrganizationDataReturn {
    appCompanyContext: IAppCompanyContext;
    organizationContext: IOrganizationContext;
    loading: boolean;
    error: boolean;
}

export function useBaseData(): IUseLoadOrganizationDataReturn {
    const organizationData = useFetchOrganizationContextData();

    const {
        appCompanyContext,
        loading: appCompanyContextLoading,
        error: appCompanyContextError,
    } = useFetchAppCompanyContextData();

    const loading =
        organizationData.machinesLoading ||
        organizationData.servicesLoading ||
        organizationData.numberOfCustomersLoading ||
        organizationData.employeesLoading ||
        organizationData.resourcesLoading ||
        organizationData.gasStationsLoading ||
        organizationData.loadingPointsLoading ||
        appCompanyContextLoading;

    return {
        appCompanyContext: {
            ...appCompanyContext,
        },
        organizationContext: {
            ...organizationData,
        },
        loading,
        error: appCompanyContextError,
    };
}
