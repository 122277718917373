import dayjs from "dayjs";
import { Vertex } from "@farmact/model/src/model/Field";
import { ValidationError } from "./customHooks/validation/useValidation";
import {
    DATEV_MAX_CLIENT_NUMBER,
    DATEV_MAX_CONSULTANT_NUMBER,
    DATEV_MIN_CLIENT_NUMBER,
    DATEV_MIN_CONSULTANT_NUMBER,
    DATEV_MIN_DEBITOR_ACCOUNT_NUMBER,
} from "@farmact/model/src/model/DatevProperties";
import { getOrderOfMagnitude } from "./numberUtils";

const NOT_EMPTY_STRING = "invalid_empty_string";

export function notEmptyString(value?: any) {
    const isValid = !!value && typeof value === "string";
    return isValid ? undefined : NOT_EMPTY_STRING;
}

const NOT_EMPTY_ARRAY = "invalid_empty_array";

export function notEmptyArray(value?: any[]) {
    const isValid = !!value && value.length > 0;
    return isValid ? undefined : NOT_EMPTY_ARRAY;
}

export function getRevenueAccountNumberValidator(currentDebitorAccountNumber: number | null, optional: boolean) {
    const message = `Bitte gib ein gültiges Umsatzkonto ein${optional ? " oder lasse das Feld frei" : ""}.`;
    return (value: number | null) => {
        if (value === null) {
            return optional ? undefined : message;
        }
        if (!Number.isInteger(value)) {
            return message;
        }
        if (
            currentDebitorAccountNumber &&
            Number.isInteger(currentDebitorAccountNumber) &&
            getOrderOfMagnitude(currentDebitorAccountNumber) <= getOrderOfMagnitude(value)
        ) {
            return "Das Debitorenkonto muss mindestens eine Stelle länger sein als das Umsatzkonto.";
        }
    };
}

export function getDebitorAccountNumberValidator(currentRevenueAccountNumber: number | null, optional: boolean) {
    const message = `Bitte gib ein gültiges Debitorenkonto (mindestens fünfstellig) ein${
        optional ? " oder lasse das Feld frei" : ""
    }.`;
    return (value: number | null) => {
        if (value === null) {
            return optional ? undefined : message;
        }
        if (!Number.isInteger(value)) {
            return message;
        }
        if (value < DATEV_MIN_DEBITOR_ACCOUNT_NUMBER) {
            return message;
        }
        if (
            currentRevenueAccountNumber &&
            Number.isInteger(currentRevenueAccountNumber) &&
            getOrderOfMagnitude(currentRevenueAccountNumber) >= getOrderOfMagnitude(value)
        ) {
            return "Das Debitorenkonto muss mindestens eine Stelle länger sein als das Umsatzkonto.";
        }
    };
}

export function getConsultantNumberValidator(optional: boolean) {
    const message = `Bitte gib eine gültige Beraternummer (1001 - 9999999) ein${
        optional ? " oder lasse das Feld frei" : ""
    }.`;
    return (value: number | null) => {
        if (value === null) {
            return optional ? undefined : message;
        }
        if (value < DATEV_MIN_CONSULTANT_NUMBER || value > DATEV_MAX_CONSULTANT_NUMBER || !Number.isInteger(value)) {
            return message;
        }
    };
}

export function getClientNumberValidator(optional: boolean) {
    const message = `Bitte gib eine gültige Mandantennummer (1 - 99999) ein${
        optional ? " oder lasse das Feld frei" : ""
    }.`;
    return (value: number | null) => {
        if (value === null) {
            return optional ? undefined : message;
        }
        if (value < DATEV_MIN_CLIENT_NUMBER || value > DATEV_MAX_CLIENT_NUMBER || !Number.isInteger(value)) {
            return message;
        }
    };
}

const DATE_NOT_AFTER = "invalid_date_not_after";
const INVALID_DATE_FORMAT = "invalid_date_format";

/**
 * Creates a curried validation function that checks if the passed `earlierDate` is before
 * the date passed to the returned function
 * @param earlierDate
 * @returns
 * @example
 * const validateIsAfter2020 = isDateAfter(new Date("2020-31-01"))
 * validateIsAfter2020(new Date("2025-01-01")) // undefined
 * @example
 * const validateIsAfter2021 = isDateAfter(new Date("2021-31-01"))
 * validateIsAfter2021(new Date("2005-10-06")) // "invalid_date_not_after"
 */
export function isDateAfter(earlierDate?: Date | string | number | null) {
    return (laterDate?: Date | string | number | null) => {
        const mustBeAfterDate = dayjs(earlierDate);
        if (!mustBeAfterDate.isValid()) {
            return;
        }

        const date = dayjs(laterDate);
        if (!date.isValid()) {
            return INVALID_DATE_FORMAT;
        }

        if (!date.isAfter(mustBeAfterDate)) {
            return DATE_NOT_AFTER;
        }
    };
}

const NUMBER_NOT_GREATER_THAN = "number_not_greater_than";

export function isNumberGreaterThan(greaterThan: number, equal = false) {
    return (value?: number) => {
        if (value === undefined) {
            return NUMBER_NOT_GREATER_THAN;
        }

        if (value === greaterThan) {
            return equal ? undefined : NUMBER_NOT_GREATER_THAN;
        }

        if (value < greaterThan) {
            return NUMBER_NOT_GREATER_THAN;
        }
    };
}

const INVALID_GEOLOCATION = "invalid_geolocation";

export function isInvalidGeolocation(latLng?: Vertex) {
    const isValid = !!latLng && latLng.lat >= -90 && latLng.lat <= 90 && latLng.lng >= -180 && latLng.lng <= 180;
    return isValid ? undefined : INVALID_GEOLOCATION;
}

// TODO: extract to utils / core package
// found here: https://stackoverflow.com/a/35599724/15960012
/*
 * Returns true if the IBAN is valid
 * Returns false if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
export function isValidIBANNumber(input: string) {
    const CODE_LENGTHS = {
        AD: 24,
        AE: 23,
        AT: 20,
        AZ: 28,
        BA: 20,
        BE: 16,
        BG: 22,
        BH: 22,
        BR: 29,
        CH: 21,
        CY: 28,
        CZ: 24,
        DE: 22,
        DK: 18,
        DO: 28,
        EE: 20,
        ES: 24,
        FI: 18,
        FO: 18,
        FR: 27,
        GB: 22,
        GI: 23,
        GL: 18,
        GR: 27,
        GT: 28,
        HR: 21,
        HU: 28,
        IE: 22,
        IL: 23,
        IS: 26,
        IT: 27,
        JO: 30,
        KW: 30,
        KZ: 20,
        LB: 28,
        LI: 21,
        LT: 20,
        LU: 20,
        LV: 21,
        MC: 27,
        MD: 24,
        ME: 22,
        MK: 19,
        MR: 27,
        MT: 31,
        MU: 30,
        NL: 18,
        NO: 15,
        PK: 24,
        PL: 28,
        PS: 29,
        PT: 25,
        QA: 29,
        RO: 24,
        RS: 22,
        SA: 24,
        SE: 24,
        SI: 19,
        SK: 24,
        SM: 27,
        TN: 24,
        TR: 26,
        AL: 28,
        BY: 28,
        CR: 22,
        EG: 29,
        GE: 22,
        IQ: 23,
        LC: 32,
        SC: 31,
        ST: 25,
        SV: 28,
        TL: 23,
        UA: 29,
        VA: 22,
        VG: 24,
        XK: 20,
    };
    const iban = input.toUpperCase().replace(/[^A-Z0-9]/g, ""); // keep only alphanumeric characters
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest
    // check syntax and length
    if (!code) {
        return false;
    }
    if (iban.length !== CODE_LENGTHS[code[1] as keyof typeof CODE_LENGTHS]) {
        return false;
    }
    // rearrange country code and check digits, and convert chars to ints
    const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, letter => (letter.charCodeAt(0) - 55).toString());
    // final check
    return mod97(digits);
}

function mod97(string: string) {
    let slicedString = string.slice(0, 2);
    let checksum = 0;
    for (let offset = 2; offset < string.length; offset += 7) {
        const fragment = slicedString + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
        slicedString = checksum.toString();
    }
    return checksum === 1;
}

// found here: https://gist.github.com/Fedik/f050c65fa6cc93973fc65df9d00357f5
export function isValidBic(bic: string) {
    return /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(bic.toUpperCase());
}

export function defined(value: unknown): ValidationError {
    if (!value) {
        return "must-be-defined";
    }
}
