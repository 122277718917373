import { IonAlert } from "@ionic/react";
import AlertStyles from "../../../../../theme/alert.module.css";

interface ILogoutAlertProps {
    show: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}

export const LogoutAlert = (props: ILogoutAlertProps) => {
    return (
        <IonAlert
            onDidDismiss={() => props.onCancel()}
            isOpen={props.show}
            onClick={e => e.stopPropagation()}
            header="Abmelden"
            message="Willst Du dich wirklich ausloggen?"
            buttons={[
                {
                    cssClass: AlertStyles.neutral,
                    handler: () => props.onCancel(),
                    role: "cancel",
                    text: "Nein",
                },
                {
                    cssClass: AlertStyles.delete,
                    handler: () => {
                        props.onConfirm();
                    },
                    role: "confirm",
                    text: "Ja",
                },
            ]}
        />
    );
};
