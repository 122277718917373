import { createContext, useContext } from "react";

interface HubspotConversationsContextType {
    isReady: boolean;
    toggleWidget: () => void;
    isOpen: boolean;
    unreadMessagesCount: number;
}

export const HubspotConversationsContext = createContext<HubspotConversationsContextType | null>(null);

export function useHubspotConversationsContext() {
    const context = useContext(HubspotConversationsContext);

    if (context === null) {
        throw new Error("useHubspotConversations must be used within HubspotConversationsProvider");
    }

    return context;
}
