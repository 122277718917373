import { Order } from "./Order";
import { AlertNotification } from "./AlertNotification";

export interface BasePushNotificationData {
    type: PushNotificationDataType;
}

export interface NewOrderPushNotificationData extends BasePushNotificationData {
    type: PushNotificationDataType.NEW_ORDER;
    orderId: Order["id"];
    notificationId: AlertNotification["id"];
}

export enum PushNotificationDataType {
    NEW_ORDER = "NEW_ORDER",
}

export type AnyPushNotificationData = NewOrderPushNotificationData;
