// rewrite OpenAPI BASE URL during development
import { OpenAPI as NotificationApi } from "@farmact/notification-app-client";
import { environment } from "../main/config/config";

// must be equal to the value in vite.config.ts
const localNotificationApiPath = "/localApi/notifications";

export function setupNotificationApi() {
    if (environment == "local") {
        NotificationApi.BASE = localNotificationApiPath;
    }

    if (environment === "dev" || environment === "alpha") {
        NotificationApi.BASE = "https://dev.api.notifications.farmact.de";
    }

    if (environment === "beta") {
        NotificationApi.BASE = "https://beta.api.notifications.farmact.de";
    }

    if (environment === "prod") {
        NotificationApi.BASE = "https://api.notifications.farmact.de";
    }
}
