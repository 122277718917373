import { ActivityType, Employee } from "@farmact/model/src/model/Employee";
import { CommonInternalWorkType } from "@farmact/model/src/model/workTypes/CommonInternalWorkType";
import dayjs from "dayjs";
import { useMemo } from "react";
import { Firebase } from "../../../firebase";
import { useDocumentData } from "../../../firebase/dataHooks";
import { useUserContext } from "../../authentication/Session/UserContext";
import { useStopInternalWork } from "../../internalWork/hooks/useStopInternalWork";
import { useStopOrderModal } from "../../orders/Tasks/StopOrderModal/useStopOrderModal";
import { useToastContext } from "../../ToastContext";

export function useControlWorkday(options?: { waitForDatabaseCommit?: boolean; employee?: Employee }) {
    const { employee: uncontrolledEmployee } = useUserContext();
    const { onMessage } = useToastContext();

    const employee = options?.employee ?? uncontrolledEmployee;

    const orderId = useMemo(
        () => (employee?.currentActivity?.type === ActivityType.ORDER ? employee.currentActivity.orderId : undefined),
        [employee?.currentActivity]
    );

    const [order] = useDocumentData(orderId !== undefined ? Firebase.instance().getOrderRef(orderId) : undefined, [
        orderId,
    ]);

    const stopOrderWorkModal = useStopOrderModal(order, {
        waitForDatabaseCommit: options?.waitForDatabaseCommit,
        employee,
    });
    const stopInternalWorkModal = useStopInternalWork({
        waitForDatabaseCommit: options?.waitForDatabaseCommit,
        employee
    });

    const start = () => {
        if (!employee) {
            onMessage("Konnte Mitarbeiter nicht identifizieren.", "warning");
            return;
        }
        Firebase.instance().startNewActivityForEmployee(
            {
                type: ActivityType.INTERNAL,
                startDateTime: dayjs().toISOString(),
                internalWorkType: CommonInternalWorkType.USE,
            },
            employee,
            false
        );
    };

    const stop = async () => {
        if (employee?.currentActivity?.type === ActivityType.INTERNAL) {
            await stopInternalWorkModal.stop();
        }
        if (employee?.currentActivity?.type === ActivityType.ORDER) {
            await stopOrderWorkModal.stop();
        }
    };

    return {
        start,
        stop,
        stopOrderModalProps: stopOrderWorkModal.modalProps,
        stopInternalWorkModalProps: stopInternalWorkModal.modalProps,
    };
}
