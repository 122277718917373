import { useEffect, useState } from "react";
import { Employee } from "@farmact/model/src/model/Employee";
import { AppUser } from "@farmact/model/src/model/AppUser";

export function useLoggedInEmployee(employees: Employee[], appUserId?: AppUser["id"]) {
    const [loggedInEmployee, setLoggedInEmployee] = useState<Employee>();
    useEffect(() => {
        if (!appUserId || employees.length === 0) {
            setLoggedInEmployee(undefined);
            return; // not yet ready
        }

        const foundEmployees = employees.filter(employee => employee.appUserId === appUserId);
        if (foundEmployees.length === 1) {
            setLoggedInEmployee(foundEmployees[0]);
        } else {
            setLoggedInEmployee(undefined);
        }
    }, [appUserId, employees]);
    return loggedInEmployee;
}
