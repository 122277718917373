import { AnyDriverQuery, DriverQuery } from "@farmact/model/src/model/Order";
import {
    DriverQueryInputTime,
    DriverQueryType,
    getDriverQueryName,
} from "@farmact/model/src/model/services/DriverQuery";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { IonItemDivider } from "@ionic/react";
import {
    filterDriverQueriesByInputTime,
    filterDriverQueriesByType,
    isDriverQueryRequired,
} from "../../../../util/orderUtils";
import { IconWithTooltip } from "../../../IconWithTooltip";
import { BeforeAfterInputItem } from "../driverQueries/BeforeAfterInputItem/BeforeAfterInputItem";
import { DriverQueryResourceInputItem } from "../driverQueries/DriverQueryResourceInputItem/DriverQueryResourceInputItem";
import { SingleValueInputItem } from "../driverQueries/SingleValueInputItem/SingleValueInputItem";
import { YesNoInputItem } from "../driverQueries/YesNoInputItem/YesNoInputItem";
import "./orderDriverQueriesModalContent.scss";
import { DriverQueryResourceOnlyInputItem } from "../driverQueries/DriverQueryResourceOnlyInputItem/DriverQueryResourceOnlyInputItem";
import { Textarea } from "../../../inputs/Textarea/Textarea";
import * as Item from "../../../FaItem";

interface IOrderDriverQueriesModalContentProps {
    /**
     * Based on this prop it is decided if a "required" mark is shown
     */
    trigger: DriverQueryInputTime.START | DriverQueryInputTime.STOP;

    queryNote?: boolean;
    note?: string;
    onNoteChange?: (newNote: string) => void;
    allDriverQueries: AnyDriverQuery[];
    onDriverQueriesChange: (driverQueries: AnyDriverQuery[]) => void;
}

function OrderDriverQueriesModalContent(props: IOrderDriverQueriesModalContentProps) {
    const requiredDriverQueries = filterDriverQueriesByInputTime(props.allDriverQueries, props.trigger);

    const driverQueriesYesNo = filterDriverQueriesByType(requiredDriverQueries, DriverQueryType.YES_NO);
    const driverQueriesSingleValue = filterDriverQueriesByType(requiredDriverQueries, DriverQueryType.VALUE);
    const driverQueriesBeforeAfter = filterDriverQueriesByType(requiredDriverQueries, DriverQueryType.BEFORE_AFTER);
    const driverQueriesResourceWithValue = filterDriverQueriesByType(
        requiredDriverQueries,
        DriverQueryType.RESOURCE_WITH_AMOUNT
    );
    const driverQueriesResourceOnly = filterDriverQueriesByType(requiredDriverQueries, DriverQueryType.RESOURCE_ONLY);

    const getChangeHandler = (driverQueryId: DriverQuery["id"]) => {
        return (updateData: Partial<DriverQuery>) => {
            props.onDriverQueriesChange(
                props.allDriverQueries.map<AnyDriverQuery>(driverQuery => {
                    if (driverQuery.id === driverQueryId) {
                        return {
                            ...driverQuery,
                            ...updateData,
                        } as AnyDriverQuery;
                    }

                    return driverQuery;
                })
            );
        };
    };

    const getRequiredIcon = (driverQuery: AnyDriverQuery) => {
        if (isDriverQueryRequired(driverQuery, props.trigger)) {
            return (
                <IconWithTooltip icon={faAsterisk}>Die Eingabe dieser Fahrerabfrage ist verpflichtend</IconWithTooltip>
            );
        }
        return null;
    };

    return (
        <div className="order-driver-queries-modal-content">
            <p className="order-driver-queries-modal-content__hint">
                Folgende Fahrerabfragen müssen beantwortet werden um fortzufahren
            </p>

            <div className="order-driver-queries-modal-content__inputs">
                {driverQueriesYesNo.map(driverQuery => (
                    <div key={driverQuery.id} className="order-driver-queries-modal-content-inputs__group">
                        <IonItemDivider color="dark">
                            {getRequiredIcon(driverQuery)}
                            {getDriverQueryName(driverQuery)}
                        </IonItemDivider>
                        {driverQuery.info && (
                            <Item.Root>
                                <Item.Label>Info</Item.Label>
                                <Item.Content>
                                    <span>{driverQuery.info}</span>
                                </Item.Content>
                            </Item.Root>
                        )}
                        <YesNoInputItem driverQuery={driverQuery} onChange={getChangeHandler(driverQuery.id)} />
                    </div>
                ))}
                {driverQueriesSingleValue.map(driverQuery => (
                    <div key={driverQuery.id} className="order-driver-queries-modal-content-inputs__group">
                        <IonItemDivider color="dark">
                            {getRequiredIcon(driverQuery)}
                            {getDriverQueryName(driverQuery)}
                        </IonItemDivider>
                        {driverQuery.info && (
                            <Item.Root>
                                <Item.Label>Info</Item.Label>
                                <Item.Content>
                                    <span>{driverQuery.info}</span>
                                </Item.Content>
                            </Item.Root>
                        )}
                        <SingleValueInputItem
                            driverQuery={driverQuery}
                            onChange={getChangeHandler(driverQuery.id)}
                            label={driverQuery.unit}
                        />
                    </div>
                ))}
                {driverQueriesBeforeAfter.map(driverQuery => (
                    <div key={driverQuery.id} className="order-driver-queries-modal-content-inputs__group">
                        <IonItemDivider color="dark">
                            {getRequiredIcon(driverQuery)}
                            {getDriverQueryName(driverQuery)}
                            {props.trigger === DriverQueryInputTime.STOP && (
                                <div
                                    className="order-driver-queries-modal-content-inputs__before-after-previous-value"
                                    slot="end">
                                    Vorher: {driverQuery.value.before}
                                </div>
                            )}
                        </IonItemDivider>
                        {driverQuery.info && (
                            <Item.Root>
                                <Item.Label>Info</Item.Label>
                                <Item.Content>
                                    <span>{driverQuery.info}</span>
                                </Item.Content>
                            </Item.Root>
                        )}
                        <BeforeAfterInputItem
                            driverQuery={driverQuery}
                            onChange={getChangeHandler(driverQuery.id)}
                            features={{
                                showBefore: props.trigger === DriverQueryInputTime.START,
                                showAfter: props.trigger === DriverQueryInputTime.STOP,
                            }}
                        />
                    </div>
                ))}
                {driverQueriesResourceWithValue.map(driverQuery => (
                    <div key={driverQuery.id} className="order-driver-queries-modal-content-inputs__group">
                        <IonItemDivider color="dark">
                            {getRequiredIcon(driverQuery)}
                            {getDriverQueryName(driverQuery)}
                        </IonItemDivider>
                        {driverQuery.info && (
                            <Item.Root>
                                <Item.Label>Info</Item.Label>
                                <Item.Content>
                                    <span>{driverQuery.info}</span>
                                </Item.Content>
                            </Item.Root>
                        )}
                        <DriverQueryResourceInputItem
                            driverQuery={driverQuery}
                            onChange={getChangeHandler(driverQuery.id)}
                        />
                    </div>
                ))}
                {driverQueriesResourceOnly.map(driverQuery => (
                    <div key={driverQuery.id} className="order-driver-queries-modal-content-inputs__group">
                        <IonItemDivider color="dark">
                            {getRequiredIcon(driverQuery)}
                            {getDriverQueryName(driverQuery)}
                        </IonItemDivider>
                        {driverQuery.info && (
                            <Item.Root>
                                <Item.Label>Info</Item.Label>
                                <Item.Content>
                                    <span>{driverQuery.info}</span>
                                </Item.Content>
                            </Item.Root>
                        )}
                        <DriverQueryResourceOnlyInputItem
                            driverQuery={driverQuery}
                            onChange={getChangeHandler(driverQuery.id)}
                        />
                    </div>
                ))}

                {props.queryNote && props.onNoteChange && (
                    <div className="order-driver-queries-modal-content-inputs__group">
                        <Item.Root>
                            <Item.Label>
                                Notiz
                            </Item.Label>
                            <Item.Content>
                                <Textarea
                                    placeholder="Notizen"
                                    value={props.note}
                                    onChange={event => props.onNoteChange?.(event.target.value)}
                                />
                            </Item.Content>
                        </Item.Root>
                    </div>
                )}
            </div>
        </div>
    );
}

interface IStartOrderDriverQueriesModalContentProps {
    allDriverQueries: AnyDriverQuery[];
    onDriverQueriesChange: (driverQueries: AnyDriverQuery[]) => void;
}

export function StartOrderDriverQueriesModalContent(props: IStartOrderDriverQueriesModalContentProps) {
    return <OrderDriverQueriesModalContent {...props} trigger={DriverQueryInputTime.START} />;
}

interface IStopOrderDriverQueriesModalContentProps {
    queryNote: boolean;
    note: string;
    onNoteChange: (newNote: string) => void;
    allDriverQueries: AnyDriverQuery[];
    onDriverQueriesChange: (driverQueries: AnyDriverQuery[]) => void;
}

export function StopOrderDriverQueriesModalContent(props: IStopOrderDriverQueriesModalContentProps) {
    return <OrderDriverQueriesModalContent {...props} trigger={DriverQueryInputTime.STOP} />;
}
