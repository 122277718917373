import { withAuthorization } from "../../authentication/Session/withAuthorization";
import { loggedIn } from "../../authentication/authorizations";
import { DefaultPage } from "../../structure/Page/DefaultPage/DefaultPage";
import { lazy } from "react";

const UpdateDetailsPageContent = lazy(() => import("./UpdateDetailsPageContent"));

function UpdateDetailsPageInternal() {
    return (
        <DefaultPage>
            <UpdateDetailsPageContent />
        </DefaultPage>
    );
}

export const UpdateDetailsPage = withAuthorization(loggedIn)(UpdateDetailsPageInternal);
