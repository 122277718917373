import { withAuthorization } from "../../../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../../../authentication/authorizations";
import { Role } from "@farmact/model/src/model/Role";
import { DefaultPage } from "../../../structure/Page/DefaultPage/DefaultPage";
import { lazy } from "react";

const RentalsOverviewPageContent = lazy(() => import("./RentalsOverviewPageContent"));

function RentalsOverviewPageInternal() {
    return (
        <DefaultPage>
            <RentalsOverviewPageContent />
        </DefaultPage>
    );
}

export const RentalsOverviewPage = withAuthorization(
    getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.PLANNER, Role.EMPLOYEE)
)(RentalsOverviewPageInternal);
