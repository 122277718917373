import { withAuthorization } from "../../../../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../../../../authentication/authorizations";
import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { DefaultPage } from "../../../../structure/Page/DefaultPage/DefaultPage";

const BillRemindersForBillPageContent = lazy(() => import("./BillRemindersForBillPageContent"));

function BillRemindersForBillPageInternal() {
    return (
        <DefaultPage>
            <BillRemindersForBillPageContent />
        </DefaultPage>
    );
}

export const BillRemindersForBillPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN))(
    BillRemindersForBillPageInternal
);
