import { Firebase } from "../../firebase";
import { useCollectionData } from "../../firebase/dataHooks";
import { useCount } from "./dataHooks/useCount";
import { IOrganizationContext } from "../../components/organization/context/OrganizationContext";
import { useCompanyId } from "./useCompanyId";

export function useFetchOrganizationContextData(): IOrganizationContext {
    const companyId = useCompanyId();
    const [numberOfCustomers, numberOfCustomersLoading] = useCount(
        companyId ? Firebase.instance().getAllCustomers() : undefined,
        [companyId]
    );
    const [machines, machinesLoading] = useCollectionData(
        companyId ? Firebase.instance().getAllMachines() : undefined,
        [companyId]
    );
    const [services, servicesLoading] = useCollectionData(
        companyId ? Firebase.instance().getAllServices() : undefined,
        [companyId]
    );
    const [resources, resourcesLoading] = useCollectionData(
        companyId ? Firebase.instance().getAllResources() : undefined,
        [companyId]
    );
    const [gasStations, gasStationsLoading] = useCollectionData(
        companyId ? Firebase.instance().getAllGasStations() : undefined,
        [companyId]
    );
    const [employees, employeesLoading] = useCollectionData(
        companyId ? Firebase.instance().getAllEmployees() : undefined,
        [companyId]
    );
    const [loadingPoints, loadingPointsLoading] = useCollectionData(
        companyId ? Firebase.instance().getAllCommonLoadingOrUnloadingPoints() : undefined,
        [companyId]
    );

    return {
        numberOfCustomers,
        machines,
        services,
        resources,
        gasStations,
        employees,
        loadingOrUnloadingPoints: loadingPoints,
        numberOfCustomersLoading,
        machinesLoading,
        servicesLoading,
        resourcesLoading,
        gasStationsLoading,
        employeesLoading,
        loadingPointsLoading,
    };
}
