import { ActivityType, Activity } from "@farmact/model/src/model/Employee";
import { AgriculturalContractorOrderWorkType } from "@farmact/model/src/model/workTypes/AgriculturalContractorOrderWorkType";
import { AgriculturalContractorInternalWorkType } from "@farmact/model/src/model/workTypes/AgriculturalContractorInternalWorkType";
import { VertexWithTime } from "@farmact/model/src/model/GeolocationTracking";
import { CommonOrderWorkType } from "@farmact/model/src/model/workTypes/CommonOrderWorkType";
import { LogisticsOrderWorkType } from "@farmact/model/src/model/workTypes/LogisticsOrderWorkType";
import { CommonInternalWorkType } from "@farmact/model/src/model/workTypes/CommonInternalWorkType";
import { LogisticsInternalWorkType } from "@farmact/model/src/model/workTypes/LogisticsInternalWorkType";

export const THRESHOLD_SUFFICIENT_ACCURACY = 100;
export const GEOLOCATION_OPTIONS = {
    timeout: 10000,
    maximumAge: 1000,
    enableHighAccuracy: true,
};

export function isLocationTrackedActivity(activity: Activity | null | undefined): boolean {
    if (!activity) {
        return false;
    }

    if (activity.type === ActivityType.INTERNAL) {
        return [
            CommonInternalWorkType.OTHER,
            CommonInternalWorkType.REPAIR,
            CommonInternalWorkType.USE,
            AgriculturalContractorInternalWorkType.MOUNT,
            AgriculturalContractorInternalWorkType.WASH,
            LogisticsInternalWorkType.LOAD,
            LogisticsInternalWorkType.UNLOAD,
        ].includes(activity.internalWorkType);
    } else if (activity.type === ActivityType.ORDER) {
        return [
            CommonOrderWorkType.OTHER,
            CommonOrderWorkType.REPAIR,
            CommonOrderWorkType.RIDE,
            CommonOrderWorkType.USE,
            CommonOrderWorkType.WAIT,
            AgriculturalContractorOrderWorkType.MOUNT,
            AgriculturalContractorOrderWorkType.WASH,
            LogisticsOrderWorkType.LOAD,
            LogisticsOrderWorkType.UNLOAD,
        ].includes(activity.orderWorkType);
    }

    return false;
}

export function computeAveragePoint(points: VertexWithTime[]) {
    const numberOfPoints = points.length;
    if (numberOfPoints < 1) {
        return;
    }
    return points.reduce(
        (prev, curr) => ({
            lat: prev.lat + curr.lat / numberOfPoints,
            lng: prev.lng + curr.lng / numberOfPoints,
            time: prev.time + curr.time / numberOfPoints,
        }),
        { lat: 0, lng: 0, time: 0 }
    );
}
