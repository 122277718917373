import { Capacitor } from "@capacitor/core";
import { IonToast, isPlatform } from "@ionic/react";
import { useWatchNewVersion } from "../util/customHooks/useWatchNewVersion";

export function NewVersionToast() {
    const { dismiss, outdated, dismissed, update } = useWatchNewVersion();

    return (
        <IonToast
            isOpen={!dismissed && outdated}
            message="Es gibt eine neue Version von FarmAct!"
            color="primary"
            duration={10_000}
            buttons={[
                {
                    text: "Ignorieren",
                    handler: dismiss,
                },
                {
                    text: getNewVersionActionText(),
                    handler: update,
                },
            ]}
        />
    );
}

function getNewVersionActionText(): string | undefined {
    if (Capacitor.isNativePlatform()) {
        if (isPlatform("ios")) {
            return "Zum App Store";
        } else if (isPlatform("android")) {
            return "Zum Play Store";
        }

        return;
    }

    return "Neu laden";
}
