import { IonButton, IonContent } from "@ionic/react";
import { StandardModalHeader } from "../../structure/StandardModalHeader/StandardModalHeader";
import "./registrationModal.scss";
import { openLink } from "../../../util/openLink";
import { FarmActModal } from "../../FarmActModal/FarmActModal";
import { isIOSApp } from "../../../util/isIOSApp";

interface IRegistrationModalProps {
    isOpen: boolean;
    onDidDismiss: () => void;
}

const contactUrl = "https://www.farmact.de/kontakt";

export const RegistrationModal = (props: IRegistrationModalProps) => {
    return (
        <FarmActModal isOpen={props.isOpen} onDidDismiss={props.onDidDismiss} className="registration-modal">
            <div className="default-modal-container">
                <StandardModalHeader title="Registrieren" onCloseClick={props.onDidDismiss} />
                <IonContent>
                    <div className="registration-modal__content">
                        <p className="registration-modal__text ion-margin-bottom">
                            Gehörst du zu einem Betrieb, der bereits FarmAct nutzt? Dann bitte deinen Administrator,
                            dich einzuladen.
                        </p>
                        {!isIOSApp() && (
                            <p className="registration-modal__text ion-margin-top">
                                Ganz neu bei FarmAct?
                                <br /> Kontaktiere uns für einen kostenlosen Testzugang!
                            </p>
                        )}
                        {!isIOSApp() && (
                            <IonButton
                                className="registration-modal__button"
                                color="primary"
                                onClick={() => openLink(contactUrl)}>
                                Jetzt testen
                            </IonButton>
                        )}
                    </div>
                </IonContent>
            </div>
        </FarmActModal>
    );
};
