import "./supportUserWarning.scss";
import { useUserContext } from "../../authentication/Session/UserContext";
import { useAppCompanyContext } from "../../authentication/AppCompanyContext";

export function SupportUserWarning() {
    const { employee: loggedInEmployee } = useUserContext();
    const { companyMeta } = useAppCompanyContext();

    if (!companyMeta || companyMeta?.supportEmployeeId !== loggedInEmployee?.id) {
        return null;
    }

    return <div className="support-user-warning">
        <div>Achtung! Du bist in einem Kundenaccount angemeldet!</div>
    </div>;
}
