import { Employee } from "@farmact/model/src/model/Employee";
import { Order } from "@farmact/model/src/model/Order";
import { useRef, useState } from "react";
import { Deferred } from "../../../../util/async";
import { isOrderActive } from "../../../../util/orderUtils";
import { useToastContext } from "../../../ToastContext";
import { useOrderValidation } from "../../OrdersAdministration/utils/useOrderValidation";
import { IStopOrderModalProps } from "./StopOrderModal";

interface UseStopOrderReturn {
    stop: () => Promise<void>;
    modalProps: IStopOrderModalProps;
}

export function useStopOrderModal(order: Order | undefined, options?: {
    waitForDatabaseCommit?: boolean;
    employee?: Employee;
}): UseStopOrderReturn {
    const { onMessage: showMessage } = useToastContext();

    const deferred = useRef<Deferred>();
    const [showModal, setShowModal] = useState(false);

    const orderValidation = useOrderValidation(order, { needsEmployee: true });

    const handleStopOrder = async () => {
        if (!order || !isOrderActive(order)) {
            return;
        }

        if (!orderValidation.canSubmit()) {
            showMessage(
                "Der Auftrag kann nicht gestoppt werden. Bitte überprüfe die Auftragskonfiguration.",
                "warning"
            );
            return;
        }

        deferred.current = new Deferred();

        setShowModal(true);

        return deferred.current.promise;
    };

    return {
        stop: handleStopOrder,
        modalProps: {
            isOpen: showModal,
            onDismiss: () => setShowModal(false),
            order,
            askForConfirmation: false,
            onStop: () => {
                deferred.current?.done();
            },
            options: options
        },
    };
}
