import { environment } from "../../config/config";

export function getAppBaseUrl() {
    switch (environment) {
        case "prod":
            return "https://app.farmact.de";
        case "beta":
            return "https://beta.app.farmact.de";
        case "alpha":
            return "https://alpha.app.farmact.de";
        case "dev":
            return "https://dev.app.farmact.de";
        case "local":
            return "http://localhost:3000";
        default:
            return "";
    }
}
