import { lazy } from "react";
import { DefaultPage } from "../structure/Page/DefaultPage/DefaultPage";
import { withAuthorization } from "../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../authentication/authorizations";
import { Role } from "@farmact/model/src/model/Role";

const ReportsPageContent = lazy(() => import("./ReportsPageContent"));

function ReportsPageInternal() {
    return (
        <DefaultPage>
            <ReportsPageContent />
        </DefaultPage>
    );
}

export const ReportsPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN))(ReportsPageInternal);
