export interface RoundingSettings {
    roundingDirection: RoundingDirection;
    roundingPrecision: RoundingPrecision;
}

export interface Rounding {
    /**
     * null means no rounding in place
     */
    hours: RoundingSettings | null;
}

export enum RoundingDirection {
    UP = "UP",
    DOWN = "DOWN",
    NEAREST = "NEAREST",
}

export enum RoundingPrecision {
    QUARTER = "0.25",
    HALF = "0.5",
    FULL = "1",
}

export function getRoundingDirection(value: string) {
    if (value === RoundingDirection.UP) {
        return RoundingDirection.UP;
    } else if (value === RoundingDirection.DOWN) {
        return RoundingDirection.DOWN;
    } else {
        return RoundingDirection.NEAREST;
    }
}

export function getRoundingPrecision(value: string) {
    if (value === RoundingPrecision.FULL) {
        return RoundingPrecision.FULL;
    } else if (value === RoundingPrecision.HALF) {
        return RoundingPrecision.HALF;
    } else {
        return RoundingPrecision.QUARTER;
    }
}
