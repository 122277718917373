import React from "react";
import "./subMenu.scss";
import cn from "clsx";

interface ISubMenuProps {
    isOpen?: boolean;
    cssClass?: string;
}

export const SubMenu: React.FC<React.PropsWithChildren<ISubMenuProps>> = props => {
    return (
        <div className={cn(["sub-menu", props.isOpen && "sub-menu--open", props.cssClass])}>
            {props.children}
        </div>
    );
};