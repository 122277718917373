import * as Routes from "@farmact/model/src/constants/farmActAppRoutes";
import { Employee } from "@farmact/model/src/model/Employee";
import { IonApp, IonRouterOutlet, IonToast } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import { MantineProvider } from "@mantine/core";
import { DatesProvider } from "@mantine/dates";
import * as Tooltip from "@radix-ui/react-tooltip";
import { useJsApiLoader } from "@react-google-maps/api";
import dayjs from "dayjs";
import "dayjs/locale/de";
import customParseFormatPlugin from "dayjs/plugin/customParseFormat";
import relativeTimePlugin from "dayjs/plugin/relativeTime";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { useCallback, useEffect, useRef, useState } from "react";
import { Route } from "react-router-dom";
import { firebaseConfig } from "../../config/config";
import { Firebase } from "../firebase";
import { useDocumentData } from "../firebase/dataHooks";
import { GeolocationContext } from "../services/geolocation/GeolocationContext";
import "../styles/index.scss";
import "../styles/reset.scss";
import "../theme/variables.css";
import { useGeolocation } from "../util/customHooks/geolocationHooks/useGeolocation";
import { useBaseData } from "../util/customHooks/useBaseData";
import { useLoggedInEmployee } from "../util/customHooks/useLoggedInEmployee";
import { useNoSleep } from "../util/customHooks/useNoSleep";
import { usePageIsVisible } from "../util/customHooks/usePageIsVisible";
import { useReportAppVersion } from "../util/customHooks/useReportAppVersion";
import { DeviceSettings, DeviceSettingsContext } from "../util/deviceSettings/DeviceSettingsContext";
import { getDeviceSettings } from "../util/deviceSettings/getDeviceSettings";
import { computed } from "../util/functions";
import { AccountPage } from "./Account/AccountPage/AccountPage";
import { StripeErrorPage } from "./Account/StripeErrorPage/StripeErrorPage";
import { RevenueDevelopmentPage } from "./Analyse/RevenueDevelopment/RevenueDevelopmentPage";
import AppCompanyContext from "./authentication/AppCompanyContext";
import { UserContext } from "./authentication/Session/UserContext";
import { SignInPage } from "./authentication/SignIn/SignInPage";
import { SignUpPage } from "./authentication/SignUp/SignUpPage";
import { useConfigureApiClients } from "./authentication/useConfigureApiClients";
import { useCustomClaims } from "./authentication/useCustomClaims";
import { useWatchEmployeeDeactivation } from "./authentication/useWatchEmployeeDeactivation";
import { CollaborationPage } from "./Contractors/CollaborationPage/CollaborationPage";
import { ContractorsPage } from "./Contractors/ContractorsPage/ContractorsPage";
import { MapStructuresSharingPage } from "./MapStructuresSharingPage/MapStructuresSharingPage";
import { GlobalSettingsPage } from "./GlobalSettingsPage/GlobalSettingsPage";
import { GoogleMapsContext } from "./GoogleMapsContext";
import { HomePage } from "./Home/HomePage";
import { InternalWorkPage } from "./internalWork/InternalWorkPage";
import { LandingPage } from "./Landing";
import { LocationsMapPage } from "./LocationsMapPage/LocationsMapPage";
import { LocationTracking } from "./LocationTracking";
import { mapsLibraries } from "./maps/mapConfig";
import { NewVersionToast } from "./NewVersionToast";
import { NotFoundPage } from "./NotFound/NotFoundPage";
import { Notification } from "./Notification/Notification";
import { DispositionPage } from "./orders/disposition/DispositionPage";
import { CreateOrderPage } from "./orders/OrdersAdministration/CreateOrderPage/CreateOrderPage";
import { EditOrderPage } from "./orders/OrdersAdministration/EditOrderPage/EditOrderPage";
import { OrdersAdministrationPage } from "./orders/OrdersAdministration/OrdersAdministrationPage/OrdersAdministrationPage";
import { OrdersArchivePage } from "./orders/OrdersAdministration/OrdersArchivePage/OrdersArchivePage";
import { OrderSettingsPage } from "./orders/OrdersAdministration/OrderSettingsPage/OrderSettingsPage";
import { TaskDetailsPage } from "./orders/Tasks/TaskDetailsPage/TaskDetailsPage";
import { TasksPage } from "./orders/Tasks/TasksPage/TasksPage";
import { OrdersProgressSharingPage } from "./OrdersProgressSharingPage/OrdersProgressSharingPage";
import { CompanyPage } from "./organization/CompanyPage/CompanyPage";
import OrganizationContext from "./organization/context/OrganizationContext";
import { CustomerDetailsPage } from "./organization/customers/CustomerDetailsPage/CustomerDetailsPage";
import { CustomersPage } from "./organization/customers/CustomersPage/CustomersPage";
import { CustomersSettingsPage } from "./organization/customers/CustomersSettingsPage/CustomerSettingsPage";
import { EmployeeDetailsPage } from "./organization/employees/EmployeeDetailsPage/EmployeeDetailsPage";
import { EmployeesPage } from "./organization/employees/EmployeesPage";
import { EmployeesTimeTrackingsPage } from "./organization/employees/EmployeesTimeTrackingsPage/EmployeesTimeTrackingsPage";
import { GasStationsPage } from "./organization/gasStations/GasStationsPage";
import { LoadingOrUnloadingPointsPage } from "./organization/loadingOrUnloadingPoints/LoadingOrUnloadingPointsPage";
import { MachineDetailsPage } from "./organization/machines/MachineDetailsPage/MachineDetailsPage";
import { MachineHistoryPage } from "./organization/machines/MachineDetailsPage/MachineHistoryPage/MachineHistoryPage";
import { MachinesPage } from "./organization/machines/MachinesPage/MachinesPage";
import { OrganizationPage } from "./organization/overview/OrganizationPage";
import { ResourceDetailsPage } from "./organization/resources/ResourceDetailsPage/ResourcesDetailsPage";
import { ResourceHistoryPage } from "./organization/resources/ResourceHistoryPage/ResourceHistoryPage";
import { ResourcesPage } from "./organization/resources/ResourcesPage";
import { RentalsOverviewPage } from "./organization/services/rental/RentalsOverviewPage";
import { ServiceCatalogPage } from "./organization/services/ServiceCatalogPage/ServiceCatalogPage";
import { ServiceDetailsPage } from "./organization/services/ServiceDetailPage/ServiceDetailsPage";
import { ServicesPage } from "./organization/services/ServicesPage/ServicesPage";
import { PushNotifications } from "./PushNotifications";
import { CreateBillPage } from "./receipts/bills/BillDetail/CreateBillPage/CreateBillPage";
import { EditBillPage } from "./receipts/bills/BillDetail/EditBillPage/EditBillPage";
import { BillReminderDetailsPage } from "./receipts/bills/billReminders/BillReminderDetailsPage/BillReminderDetailsPage";
import { BillRemindersOverviewPage } from "./receipts/bills/billReminders/BillRemindersOverviewPage/BillRemindersOverviewPage";
import { BillRemindersForBillPage } from "./receipts/bills/billReminders/BillRemindersPage/BillRemindersForBillPage";
import { BillsArchivePage } from "./receipts/bills/BillsArchive/BillsArchivePage";
import { BillSettingsPage } from "./receipts/bills/BillSettingsPage/BillSettingsPage";
import { BillsPage } from "./receipts/bills/BillsPage/BillsPage";
import { CreateCreditNotePage } from "./receipts/CreditNotes/CreateCreditNotePage/CreateCreditNotePage";
import { CreditNotesSettingsPage } from "./receipts/CreditNotes/CreditNoteSettingsPage/CreditNotSettingsPage";
import { CreditNotesPage } from "./receipts/CreditNotes/CreditNotesPage/CreditNotesPage";
import { EditCreditNotePage } from "./receipts/CreditNotes/EditCreditNotePage/EditCreditNotePage";
import { CreateDeliveryNotePage } from "./receipts/deliveryNotes/CreateDeliveryNotePage/CreateDeliveryNotePage";
import { DeliveryNotesArchivePage } from "./receipts/deliveryNotes/DeliveryNotesArchivePage/DeliveryNotesArchivePage";
import { DeliveryNoteSettingsPage } from "./receipts/deliveryNotes/DeliveryNoteSetttingsPage/DeliveryNoteSettingsPage";
import { DeliveryNotesPage } from "./receipts/deliveryNotes/DeliveryNotesPage/DeliveryNotesPage";
import { EditDeliveryNotePage } from "./receipts/deliveryNotes/EditDeliveryNotePage/EditDeliveryNotePage";
import { CreateOfferPage } from "./receipts/offers/CreateOfferPage/CreateOfferPage";
import { EditOfferPage } from "./receipts/offers/EditOfferPage/EditOfferPage";
import { OfferSettingsPage } from "./receipts/offers/OfferSettingsPage/OfferSettingsPage";
import { OffersPage } from "./receipts/offers/OffersPage/OffersPage";
import { ReceiptMenuPage } from "./receipts/ReceiptDetailsPage/ReceiptMenuPage";
import { AddRentalOrderPage } from "./rentalOrders/AddRentalOrderPage/AddRentalOrderPage";
import { RentalOrderDetailsPage } from "./rentalOrders/RentalOrderDetailsPage/RentalOrderDetailsPage";
import { ReportsPage } from "./ReportsPage/ReportsPage";
import { StandardHeader } from "./structure/header/StandardHeader";
import { MenuPage } from "./structure/Page/MenuPage";
import { SupportUserWarning } from "./structure/SupportUserWarning/SupportUserWarning";
import { MyWorkTimesPage } from "./timeTracking/MyWorkTimesPage";
import { Color, IMessageProps, ToastContext } from "./ToastContext";
import { UnsupportedVersionModal } from "./UnsupportedVersionModal/UnsupportedVersionModal";
import { UpdateDetailsPage } from "./updatesInfo/UpdateDetailsPage/UpdateDetailsPage";
import { UpdateInfoPage } from "./updatesInfo/UpdateInfoPage/UpdateInfoPage";
import { DatevConnectionCheck } from "./utils/DatevConnectionCheck/DatevConnectionCheck";
import { ProjectsPage } from "./projects/ProjectsPage/ProjectsPage";
import { ProjectDetailsPage } from "./projects/ProjectDetailsPage/ProjectDetailsPage";
import { ThemeProvider, createTheme } from "@mui/material";

dayjs.locale("de");
dayjs.extend(relativeTimePlugin);
dayjs.extend(customParseFormatPlugin);

const App = () => {
    useNoSleep();
    useConfigureApiClients();

    const [message, setMessage] = useState<IMessageProps>();

    const [authUser, setAuthUser] = useState<undefined | null | User>(undefined);
    useEffect(() => {
        return onAuthStateChanged(getAuth(), user => {
            setAuthUser(user);
            Firebase.instance().reflectAuthChange(user);
        });
    }, []);

    const [deviceSettings, setDeviceSettings] = useState<DeviceSettings>({});
    const pageIsVisible = usePageIsVisible();
    useEffect(() => {
        if (!pageIsVisible) {
            return;
        }
        getDeviceSettings().then(setDeviceSettings);
    }, [pageIsVisible]);

    const [appUser] = useDocumentData(authUser?.uid ? Firebase.instance().getAppUser(authUser.uid) : undefined, [
        authUser?.uid,
    ]);
    useReportAppVersion(appUser?.id);

    const [customClaims] = useCustomClaims(authUser);
    useWatchEmployeeDeactivation(authUser);

    const {
        organizationContext,
        appCompanyContext,
        loading: baseDataLoading,
        error: appCompanyDataError,
    } = useBaseData();

    const { isLoaded: googleLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: firebaseConfig.apiKey,
        libraries: mapsLibraries,
    });

    const loggedInEmployee = useLoggedInEmployee(organizationContext.employees, authUser?.uid);

    const employeesForContext = computed((): Employee[] => {
        if (loggedInEmployee?.id === appCompanyContext.companyMeta?.supportEmployeeId) {
            return organizationContext.employees;
        }

        return organizationContext.employees.filter(employee => {
            return employee.id !== appCompanyContext.companyMeta?.supportEmployeeId;
        });
    });

    const onMessageCallback = useCallback((message: string, color?: Color) => {
        setMessage({ message, color });
    }, []);

    const sideNavRef = useRef<HTMLDivElement>(null);

    const handleSideNavClick = () => {
        sideNavRef.current?.click();
    };

    const { watchGeolocation, openDeviceGeolocationSettings } = useGeolocation(
        !!deviceSettings.trackBackgroundGeolocation
    );

    const ionicFontFamily = getComputedStyle(document.body).getPropertyValue("--ion-font-family");

    return (
        <MantineProvider
            theme={{
                fontFamily: ionicFontFamily,
                fontSizes: { sm: "1rem" },
            }}>
            <ThemeProvider
                theme={createTheme({
                    typography: {
                        fontFamily: ionicFontFamily,
                    },
                })}>
                <Tooltip.Provider>
                    <DatesProvider settings={{ locale: "de", firstDayOfWeek: 1 }}>
                        <DeviceSettingsContext.Provider value={{ deviceSettings, setDeviceSettings }}>
                            <UserContext.Provider
                                value={{
                                    authUser,
                                    appUser,
                                    employee: loggedInEmployee,
                                    customClaims,
                                    isLoading: loggedInEmployee === undefined,
                                }}>
                                <AppCompanyContext.Provider value={appCompanyContext}>
                                    <OrganizationContext.Provider
                                        value={{
                                            ...organizationContext,
                                            employees: employeesForContext,
                                        }}>
                                        <ToastContext.Provider
                                            value={{
                                                onMessage: onMessageCallback,
                                                dismissMessage: () => setMessage(undefined),
                                            }}>
                                            <GeolocationContext.Provider
                                                value={{ watchGeolocation, openDeviceGeolocationSettings }}>
                                                <IonApp>
                                                    <GoogleMapsContext.Provider value={{ googleLoaded, loadError }}>
                                                        <IonReactRouter>
                                                            {Firebase.instance().getCompanyId() && <Notification />}
                                                            <StandardHeader onMenuToggleClicked={handleSideNavClick} />
                                                            <MenuPage
                                                                contentId="main"
                                                                ref={sideNavRef}
                                                                appCompanyDataError={appCompanyDataError}
                                                                baseDataLoading={baseDataLoading}>
                                                                <IonRouterOutlet id="main">
                                                                    <Route component={NotFoundPage} />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORDERS_PROGRESS_SHARING}
                                                                        component={OrdersProgressSharingPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.MAP_STRUCTURES_SHARING}
                                                                        component={MapStructuresSharingPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.HOME}
                                                                        component={HomePage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ACCOUNT}
                                                                        component={AccountPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.SETTINGS}
                                                                        component={GlobalSettingsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.REPORTS}
                                                                        component={ReportsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORGANIZATION_CUSTOMERS}
                                                                        component={CustomersPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORGANIZATION_CUSTOMERS_SETTINGS}
                                                                        component={CustomersSettingsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORGANIZATION_CUSTOMERS_DETAILS}
                                                                        component={CustomerDetailsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ANALYSIS_REVENUE}
                                                                        component={RevenueDevelopmentPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORGANIZATION_LOADING_POINTS}
                                                                        component={LoadingOrUnloadingPointsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORGANIZATION_EMPLOYEES}
                                                                        component={EmployeesPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={
                                                                            Routes.ORGANIZATION_EMPLOYEES_TIME_TRACKING
                                                                        }
                                                                        component={EmployeesTimeTrackingsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORGANIZATION_EMPLOYEES_DETAILS}
                                                                        component={EmployeeDetailsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.EMPLOYEES_MAP}
                                                                        component={LocationsMapPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORGANIZATION_MACHINES}
                                                                        component={MachinesPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORGANIZATION_MACHINE_DETAILS}
                                                                        component={MachineDetailsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORGANIZATION_MACHINE_HISTORY}
                                                                        component={MachineHistoryPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORGANIZATION_SERVICES}
                                                                        component={ServicesPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORGANIZATION_SERVICES_DETAILS}
                                                                        component={ServiceDetailsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORGANIZATION_SERVICES_CATALOG}
                                                                        component={ServiceCatalogPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORGANIZATION_RESOURCES}
                                                                        component={ResourcesPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORGANIZATION_RESOURCES_HISTORY}
                                                                        component={ResourceHistoryPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORGANIZATION_RESOURCES_DETAILS}
                                                                        component={ResourceDetailsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORGANIZATION_GASSTATIONS}
                                                                        component={GasStationsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORGANIZATION_COMPANY}
                                                                        component={CompanyPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORGANIZATION}
                                                                        component={OrganizationPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORDER_DISPOSITION}
                                                                        component={DispositionPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORDERS}
                                                                        component={OrdersAdministrationPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORDER_SETTINGS}
                                                                        component={OrderSettingsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.TIME_TRACKING}
                                                                        component={MyWorkTimesPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.INTERNAL_WORK}
                                                                        component={InternalWorkPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.TASKS}
                                                                        component={TasksPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.TASK_DETAILS}
                                                                        component={TaskDetailsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORDER_DETAILS}
                                                                        component={EditOrderPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORDER_CREATE}
                                                                        component={CreateOrderPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORDERS_ARCHIVE}
                                                                        component={OrdersArchivePage}
                                                                    />
                                                                    {/*<Route exact path={Routes.NEWS} component={NewsPage} />*/}
                                                                    <Route
                                                                        exact
                                                                        path={Routes.RECEIPTS}
                                                                        component={ReceiptMenuPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.BILLS}
                                                                        component={BillsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.BILL_SETTINGS}
                                                                        component={BillSettingsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.BILL_CREATE}
                                                                        component={CreateBillPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.BILL_DETAILS}
                                                                        component={EditBillPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.BILL_ARCHIVE}
                                                                        component={BillsArchivePage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.BILL_REMINDERS_FOR_BILL}
                                                                        component={BillRemindersForBillPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.BILL_REMINDERS}
                                                                        component={BillRemindersOverviewPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.BILL_REMINDER_DETAILS}
                                                                        component={BillReminderDetailsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.DELIVERY_NOTES}
                                                                        component={DeliveryNotesPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.DELIVERY_NOTES_ARCHIVE}
                                                                        component={DeliveryNotesArchivePage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.DELIVERY_NOTES_CREATE}
                                                                        component={CreateDeliveryNotePage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.DELIVERY_NOTES_DETAIL}
                                                                        component={EditDeliveryNotePage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.DELIVERY_NOTES_SETTINGS}
                                                                        component={DeliveryNoteSettingsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.CREDIT_NOTES}
                                                                        component={CreditNotesPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.CREDIT_NOTES_CREATE}
                                                                        component={CreateCreditNotePage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.CREDIT_NOTES_DETAIL}
                                                                        component={EditCreditNotePage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.CREDIT_NOTES_SETTINGS}
                                                                        component={CreditNotesSettingsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.OFFERS}
                                                                        component={OffersPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.OFFERS_CREATE}
                                                                        component={CreateOfferPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.OFFERS_DETAIL}
                                                                        component={EditOfferPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.OFFERS_SETTINGS}
                                                                        component={OfferSettingsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.SIGNIN}
                                                                        component={SignInPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.SIGNUP}
                                                                        component={SignUpPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.UPDATES_INFO}
                                                                        component={UpdateInfoPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.UPDATES_INFO_DETAIL}
                                                                        component={UpdateDetailsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.CONTRACTORS}
                                                                        component={ContractorsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.CONTRACTOR_DETAILS}
                                                                        component={CollaborationPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.RENTAL_ORDER_CREATE}
                                                                        component={AddRentalOrderPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.RENTAL_ORDER_DETAILS}
                                                                        component={RentalOrderDetailsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.ORGANIZATION_RENTAL_SERVICES}
                                                                        component={RentalsOverviewPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.PROJECTS}
                                                                        component={ProjectsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.PROJECT_DETAILS}
                                                                        component={ProjectDetailsPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.STRIPE_ERROR}
                                                                        component={StripeErrorPage}
                                                                    />
                                                                    <Route
                                                                        exact
                                                                        path={Routes.LANDING}
                                                                        component={LandingPage}
                                                                    />
                                                                </IonRouterOutlet>
                                                            </MenuPage>
                                                            <PushNotifications />
                                                        </IonReactRouter>
                                                    </GoogleMapsContext.Provider>
                                                </IonApp>
                                                <LocationTracking />
                                            </GeolocationContext.Provider>
                                        </ToastContext.Provider>

                                        <IonToast
                                            cssClass={"ion-text-center"}
                                            isOpen={!!message}
                                            onDidDismiss={() => setMessage(undefined)}
                                            message={message?.message}
                                            color={message?.color}
                                            duration={5000}
                                            buttons={[
                                                {
                                                    text: "Schließen",
                                                    role: "cancel",
                                                },
                                            ]}
                                        />

                                        <UnsupportedVersionModal />
                                        <SupportUserWarning />
                                        <NewVersionToast />
                                    </OrganizationContext.Provider>
                                    <DatevConnectionCheck />
                                </AppCompanyContext.Provider>
                            </UserContext.Provider>
                        </DeviceSettingsContext.Provider>
                    </DatesProvider>
                </Tooltip.Provider>
            </ThemeProvider>
        </MantineProvider>
    );
};

export default App;
