import { withAuthorization } from "../../../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../../../authentication/authorizations";
import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { DefaultPage } from "../../../structure/Page/DefaultPage/DefaultPage";

const DeliveryNotesPageContent = lazy(() => import("./DeliveryNotesPageContent"));

function DeliveryNotesPageInternal() {
    return (
        <DefaultPage>
            <DeliveryNotesPageContent />
        </DefaultPage>
    );
}

export const DeliveryNotesPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN))(
    DeliveryNotesPageInternal
);
