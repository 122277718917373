import { withAuthorization } from "../../authentication/Session/withAuthorization";
import { onlyCustomer } from "../../authentication/authorizations";
import { lazy } from "react";
import { DefaultPage } from "../../structure/Page/DefaultPage/DefaultPage";

const ContractorsPageContent = lazy(() => import("./ContractorsPageContent"));

function ContractorsPageInternal() {
    return (
        <DefaultPage>
            <ContractorsPageContent />
        </DefaultPage>
    );
}

export const ContractorsPage = withAuthorization(onlyCustomer)(ContractorsPageInternal);
