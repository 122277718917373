import { produce } from "immer";
import { Employee } from "@farmact/model/src/model/Employee";
import { MachineCounterTrackingSource, MachineCounterType } from "@farmact/model/src/model/MachineCounterTracking";
import { AnyDriverQuery, Order, TaskRecord } from "@farmact/model/src/model/Order";
import { DriverQueryType } from "@farmact/model/src/model/services/DriverQuery";
import { getDocs, query, where } from "firebase/firestore";
import { Firebase } from "../../../../firebase";
import {
    currentlyActiveTime,
    filterDriverQueriesByType,
    mergeDriverQueries,
    mergeTaskRecords,
    updateBeforeAfterHistory,
    updateResourceOnlyHistory,
    updateResourceWithValueHistory,
    updateValueHistory,
    updateYesNoHistory,
} from "../../../../util/orderUtils";
import { recordError } from "../../../../util/recordError";
import { useUserContext } from "../../../authentication/Session/UserContext";
import { useToastContext } from "../../../ToastContext";
import { MachineCounterValue } from "../MachineCounters/useMachineCounterGroups";

interface UseStopOrderReturn {
    stop: (
        payload: StopOrderPayload,
        options?: {
            waitForDatabaseCommit?: boolean;
            employee?: Employee;
        }
    ) => Promise<void>;
}

/**
 * Hook to stop an order.
 * @param order - Order that should be stopped. May only be `undefined` while it is loaded.
 */
export function useStopOrder(order: Order | undefined): UseStopOrderReturn {
    const { onMessage: showMessage } = useToastContext();
    const { employee: uncontrolledEmployee } = useUserContext();

    const stop = async (
        payload: StopOrderPayload,
        options?: {
            waitForDatabaseCommit?: boolean;
            employee?: Employee;
        }
    ) => {
        if (!order) {
            showMessage("Konnte Auftrag nicht finden. Bitte versuche es später erneut.", "warning");
            recordError("Could not identify employee when stopping order.");
            return;
        }

        const employee = options?.employee ?? uncontrolledEmployee;

        if (!employee) {
            showMessage("Konnte Mitarbeiter nicht identifizieren. Bitte versuche es später erneut.", "warning");
            recordError("Could not identify employee when stopping order.", {
                orderId: order.id,
                orderEmployeeId: order.employeeId,
            });
            return;
        }

        try {
            await stopOrder(order, payload, {
                employee,
                waitForDatabaseCommit: options?.waitForDatabaseCommit,
            });
        } catch (error) {
            recordError("Failed to stop order", {
                orderId: order.id,
                employee,
                error,
            });
        }
    };

    return {
        stop,
    };
}

export interface StopOrderPayload {
    taskRecords: TaskRecord[];
    note: string | null;
    driverQueries: AnyDriverQuery[];
    machineCounterValues: MachineCounterValue[];
}

interface StopOrderParams {
    employee: Employee;
    waitForDatabaseCommit?: boolean;
}

async function stopOrder(order: Order, payload: StopOrderPayload, params: StopOrderParams): Promise<void> {
    const batch = Firebase.instance().createWriteBatch();

    const yesNoDriverQueries = filterDriverQueriesByType(payload.driverQueries, DriverQueryType.YES_NO);
    const singleValueDriverQueries = filterDriverQueriesByType(payload.driverQueries, DriverQueryType.VALUE);
    const beforeAfterDriverQueries = filterDriverQueriesByType(payload.driverQueries, DriverQueryType.BEFORE_AFTER);
    const resourceWithValueDriverQueries = filterDriverQueriesByType(
        payload.driverQueries,
        DriverQueryType.RESOURCE_WITH_AMOUNT
    );
    const resourceOnlyDriverQueries = filterDriverQueriesByType(payload.driverQueries, DriverQueryType.RESOURCE_ONLY);

    const activeTime = currentlyActiveTime(order);
    const currentlyActiveCustomerId = activeTime?.customerId ?? null;

    const nextOrder = new Order({
        ...order,
        other: order.serviceSnapshot?.queryNote && payload.note !== null ? payload.note : order.other,
        driverQueriesYesNo: updateYesNoHistory(
            mergeDriverQueries(
                order.driverQueriesYesNo,
                yesNoDriverQueries.map(driverQuery => {
                    return produce(driverQuery, draft => {
                        draft.value.customerId = currentlyActiveCustomerId;
                    });
                })
            )
        ),
        driverQueriesSingleValue: updateValueHistory(
            mergeDriverQueries(
                order.driverQueriesSingleValue,
                singleValueDriverQueries.map(driverQuery => {
                    return produce(driverQuery, draft => {
                        draft.value.customerId = currentlyActiveCustomerId;
                    });
                })
            )
        ),
        driverQueriesBeforeAfter: updateBeforeAfterHistory(
            mergeDriverQueries(
                order.driverQueriesBeforeAfter,
                beforeAfterDriverQueries.map(driverQuery => {
                    return produce(driverQuery, draft => {
                        draft.value.customerId = currentlyActiveCustomerId;
                    });
                })
            )
        ),
        driverQueriesResourceWithAmount: updateResourceWithValueHistory(
            mergeDriverQueries(
                order.driverQueriesResourceWithAmount,
                resourceWithValueDriverQueries.map(driverQuery => {
                    return produce(driverQuery, draft => {
                        draft.value.customerId = currentlyActiveCustomerId;
                        if (draft.value.usage) {
                            draft.value.usage.customerId = currentlyActiveCustomerId;
                        }
                    });
                })
            )
        ),
        driverQueriesResourceOnly: updateResourceOnlyHistory(
            mergeDriverQueries(
                order.driverQueriesResourceOnly,
                resourceOnlyDriverQueries.map(driverQuery => {
                    return produce(driverQuery, draft => {
                        draft.value.customerId = currentlyActiveCustomerId;
                    });
                })
            )
        ),
        taskRecords: mergeTaskRecords(
            order.taskRecords,
            payload.taskRecords.map(taskRecord => {
                return produce(taskRecord, draft => {
                    draft.customerId = currentlyActiveCustomerId;
                });
            })
        ),
    });

    await Firebase.instance().stopOrderOfEmployee(nextOrder, params.employee, batch);

    if (payload.machineCounterValues.length > 0) {
        const existingTrackings = (
            await getDocs(
                query(
                    Firebase.instance().getAllMachineCounterTrackings(),
                    where("employeeId", "==", params.employee.id),
                    where("orderId", "==", order.id)
                )
            )
        ).docs.map(doc => doc.data());

        for (const machineCounterValue of payload.machineCounterValues) {
            const unfinishedTrackings = existingTrackings.filter(tracking => {
                return (
                    tracking.source.type === machineCounterValue.source.type &&
                    tracking.source.machineId === machineCounterValue.source.machineId &&
                    tracking.type === machineCounterValue.type &&
                    !tracking.endValue &&
                    !tracking.endDate
                );
            });

            // Finish existing machine counter trackings
            for (const tracking of unfinishedTrackings) {
                Firebase.instance().updateMachineCounterTracking(
                    tracking.id,
                    {
                        endDate: new Date().toISOString(),
                        endValue: machineCounterValue.value,
                    },
                    batch
                );
            }

            // Update machine operating hours
            if (
                machineCounterValue.type === MachineCounterType.OPERATING_HOURS &&
                machineCounterValue.value !== null &&
                machineCounterValue.source.type === MachineCounterTrackingSource.MACHINE
            ) {
                Firebase.instance().updateMachine(
                    machineCounterValue.source.machineId,
                    {
                        currentOperatingHours: machineCounterValue.value,
                    },
                    batch
                );
            }
        }
    }

    if (params.waitForDatabaseCommit) {
        await batch.commit();
    } else {
        batch.commit();
    }
}
