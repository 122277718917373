import { withAuthorization } from "../../../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../../../authentication/authorizations";
import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { DefaultPage } from "../../../structure/Page/DefaultPage/DefaultPage";

const OfferSettingsPageContent = lazy(() => import("./OfferSettingsPageContent"));

function OfferSettingsPageInternal() {
    return (
        <DefaultPage>
            <OfferSettingsPageContent />
        </DefaultPage>
    );
}

export const OfferSettingsPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN))(
    OfferSettingsPageInternal
);
