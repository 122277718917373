import { ActivityType, Employee } from "@farmact/model/src/model/Employee";
import { InternalWorkType } from "@farmact/model/src/model/workTypes/InternalWorkType";
import { produce } from "immer";
import { useRef, useState } from "react";
import { Firebase } from "../../../firebase";
import { Deferred } from "../../../util/async";
import { useAppCompanyContext } from "../../authentication/AppCompanyContext";
import { useUserContext } from "../../authentication/Session/UserContext";
import { useToastContext } from "../../ToastContext";
import { IInternalWorkNoteModalProps } from "../InternalWorkGrid/InternalWorkCard/InternalWorkNoteModal/InternalWorkNoteModal";

interface StopInternalWorkReturn {
    stop: (endTime?: string) => Promise<void>;
    /**
     * Props that should be passed to `<InternalWorkNoteModal>`
     */
    modalProps: IInternalWorkNoteModalProps;
}

export function useStopInternalWork(options?: {
    waitForDatabaseCommit?: boolean,
    employee?: Employee
}): StopInternalWorkReturn {
    const { appCompany } = useAppCompanyContext();
    const { employee: uncontrolledEmployee } = useUserContext();
    const { onMessage } = useToastContext();

    const employee = options?.employee ?? uncontrolledEmployee;

    const deferred = useRef<Deferred>();

    const [showInternalNoteModal, setShowInternalNoteModal] = useState(false);

    const stop = async (endTime?: string) => {
        if (!employee) {
            onMessage("Konnte Mitarbeiter nicht identifizieren.", "danger");
            return;
        }

        const shouldAskForNote = ((): boolean => {
            if (!employee.currentActivity) {
                return false;
            }
            if (employee.currentActivity.type !== ActivityType.INTERNAL) {
                return false;
            }
            if (!appCompany?.settings.showNoteQueryForInternalWork) {
                return false;
            }
            if (employee.currentActivity.note) {
                return false;
            }
            if (employee.currentActivity.internalWorkType === InternalWorkType.PAUSE) {
                return false;
            }

            return true;
        })();

        if (shouldAskForNote) {
            deferred.current = new Deferred();
            setShowInternalNoteModal(true);
            return deferred.current.promise;
        }

        if (employee.currentActivity) {
            await Firebase.instance().endCurrentActivityForEmployee(employee, {
                endTimeOfPreviousActivity: endTime,
                waitForServerResponse: options?.waitForDatabaseCommit,
            });
        }
    };

    const handleSubmit = async (note: string) => {
        setShowInternalNoteModal(false);
        if (!employee) {
            onMessage("Konnte Mitarbeiter nicht identifizieren.", "danger");
            return;
        }

        const localEmployee = produce({ ...employee }, draft => {
            if (!draft.currentActivity) {
                return;
            }
            draft.currentActivity.note = note;
        });

        await Firebase.instance().endCurrentActivityForEmployee(localEmployee, {
            waitForServerResponse: options?.waitForDatabaseCommit,
        });

        deferred.current?.done();
    };

    const handleDismiss = () => {
        setShowInternalNoteModal(false);
    };

    return {
        stop,
        modalProps: {
            isOpen: showInternalNoteModal,
            onSubmit: handleSubmit,
            onDismiss: handleDismiss,
        },
    };
}
