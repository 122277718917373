import { IonButton, IonButtons, IonContent, IonFooter, IonToolbar } from "@ionic/react";
import { useState } from "react";
import { Resource } from "@farmact/model/src/model/Resource";
import {
    ResourcePrice,
    ResourcePriceCalculationType,
    ResourceVariantIdentifier,
    VariantsResourcePrice,
} from "@farmact/model/src/model/ResourcePrice";
import { isSimpleResourcePrice, isVariantsResourcePrice } from "../../../../util/resourceUtils";
import { StandardModalHeader } from "../../../structure/StandardModalHeader/StandardModalHeader";
import "./selectVariantModal.scss";
import { FarmActModal } from "../../../FarmActModal/FarmActModal";

interface ISelectVariantModalProps {
    isOpen: boolean;
    onDismiss: () => void;
    resource: Resource;
    onVariantSelect: (selection: ResourceVariantIdentifier) => void;
    allowSelectionOfBaseVariant?: boolean;
}

export const SelectVariantModal = (props: ISelectVariantModalProps) => {
    const [selectedVariantWithVariations, setSelectedVariantWithVariations] =
        useState<VariantsResourcePrice<ResourcePriceCalculationType> | null>(null);

    const handleLetDriverSelectVariant = () => {
        props.onVariantSelect({ resourceId: props.resource.id, variantId: props.resource.price.id });
        handleDismiss();
    };

    const handleVariantSelect = (variant: ResourcePrice<ResourcePriceCalculationType>) => {
        if (isSimpleResourcePrice(variant)) {
            props.onVariantSelect({ resourceId: props.resource.id, variantId: variant.id });
            handleDismiss();
            return;
        }
        if (isVariantsResourcePrice(variant)) {
            setSelectedVariantWithVariations(variant);
        }
    };
    const handleCancelClick = () => {
        if (selectedVariantWithVariations) {
            setSelectedVariantWithVariations(null);
        } else {
            handleDismiss();
        }
    };

    const handleDismiss = () => {
        setSelectedVariantWithVariations(null);
        props.onDismiss();
    };

    return (
        <FarmActModal isOpen={props.isOpen} onDidDismiss={handleDismiss} className="select-variant-modal stacked-modal">
            <div className="default-modal-container">
                <StandardModalHeader title="Variante auswählen" onCloseClick={handleDismiss} />
                <IonContent>
                    <div className="select-variant-modal__content">
                        {isSimpleResourcePrice(props.resource.price) && (
                            <IonButton color="primary" onClick={() => handleVariantSelect(props.resource.price)}>
                                {props.resource.name}
                            </IonButton>
                        )}
                        {!selectedVariantWithVariations && isVariantsResourcePrice(props.resource.price) && (
                            <>
                                {props.allowSelectionOfBaseVariant && (
                                    <IonButton className="select-variant-modal__option-button" onClick={handleLetDriverSelectVariant}>
                                        Auswahl dem Fahrer überlassen
                                    </IonButton>
                                )}
                                {props.resource.price.variants.map(variant => (
                                    <IonButton
                                        className="select-variant-modal__option-button"
                                        key={variant.id}
                                        onClick={() => handleVariantSelect(variant)}>
                                        {variant.name}
                                    </IonButton>
                                ))}
                            </>
                        )}
                        {selectedVariantWithVariations &&
                            selectedVariantWithVariations.variants.map(variation => (
                                <IonButton
                                    className="select-variant-modal__option-button"
                                    key={variation.id}
                                    onClick={() => handleVariantSelect(variation)}>
                                    {variation.name}
                                </IonButton>
                            ))}
                    </div>
                </IonContent>

                <IonFooter>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton color="dark" onClick={handleCancelClick}>
                                {selectedVariantWithVariations ? "Zurück" : "Abbrechen"}
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonFooter>
            </div>
        </FarmActModal>
    );
};
