export class Deferred {
    public readonly promise: Promise<void>;
    private resolve: () => void = () => {};

    constructor() {
        this.promise = new Promise(resolve => {
            this.resolve = resolve;
        });
    }

    /**
     * Call to resolve the promise
     */
    done() {
        this.resolve();
    }
}
