import React, { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router";
import "./sideMenuTile.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import cn from "clsx";

interface ISubMenuProps {
    isOpen?: boolean;
    isInSplitPane: boolean;
}

interface ISideMenuTileProps {
    clickTarget: string;
    name: string;
    icon: any;
    badgeNumber?: number;
    isInSplitPane?: boolean;
    menuIsOpen?: boolean;
    pathMatch?: string;

    children?: React.ReactElement<ISubMenuProps> | React.ReactElement<ISubMenuProps>[];
}

export function SideMenuTile(props: ISideMenuTileProps) {
    const history = useHistory();
    const location = useLocation();
    const [subMenuIsOpen, setSubMenuIsOpen] = useState<boolean>(
        matchPath(location.pathname, {
            path: props.pathMatch ?? props.clickTarget,
            exact: false,
        }) !== null
    );

    useEffect(() => {
        if (matchPath(location.pathname, { path: props.pathMatch ?? props.clickTarget, exact: false })) {
            setSubMenuIsOpen(true);
        } else {
            setSubMenuIsOpen(false);
        }
    }, [location.pathname, props.clickTarget, props.pathMatch]);

    return (
        <>
            <div
                className={cn([
                    "side-menu-tile",
                    matchPath(location.pathname, {
                        path: props.pathMatch ?? props.clickTarget,
                        exact: false,
                    }) && "side-menu-tile--active",
                ])}
                onClick={e => {
                    if (props.isInSplitPane) {
                        e.stopPropagation();
                    }
                    history.push(props.clickTarget);
                }}>
                {props.isInSplitPane ? (
                    <div className="side-menu-tile__item">
                        <FontAwesomeIcon icon={props.icon} className="side-menu-tile__icon" />
                        {props.badgeNumber !== undefined && props.badgeNumber > 0 && (
                            <div
                                className={cn([
                                    "side-menu-tile__news",
                                    props.menuIsOpen && "side-menu-tile__news--hidden",
                                ])}
                            />
                        )}
                        <p>{props.name}</p>
                        {props.badgeNumber !== undefined && props.badgeNumber > 0 && (
                            <div className="side-menu-tile__badge">{props.badgeNumber}</div>
                        )}
                    </div>
                ) : (
                    <div className="side-menu-tile__item">
                        <FontAwesomeIcon icon={props.icon} className="side-menu-tile__icon" />
                        <p>{props.name}</p>
                        {props.badgeNumber !== undefined && props.badgeNumber > 0 && (
                            <div className="side-menu-tile__badge">{props.badgeNumber}</div>
                        )}
                    </div>
                )}
                {props.children && (
                    <>
                        <div
                            className={cn(["side-menu-tile__toggle", subMenuIsOpen && "side-menu-tile__toggle--open"])}
                            onClick={e => {
                                e.stopPropagation();
                                setSubMenuIsOpen(prevState => !prevState);
                            }}>
                            <FontAwesomeIcon icon={faCaretDown} />
                        </div>
                    </>
                )}
            </div>
            {React.Children.map(
                props.children,
                child =>
                    React.isValidElement(child) &&
                    React.cloneElement(child, {
                        isOpen: (!props.isInSplitPane || (props.isInSplitPane && !!props.menuIsOpen)) && subMenuIsOpen,
                    })
            )}
        </>
    );
}
