import { matchPath, useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import cn from "clsx";
import "./subMenuItem.scss";

interface ISubMenuItemProps {
    name: string;
    route: string;
    isInSplitPane: boolean;
}

export const SubMenuItem = (props: ISubMenuItemProps) => {
    const location = useLocation();
    const history = useHistory();
    return (
        <div
            className={cn([
                "sub-menu-item",
                matchPath(location.pathname, props.route) && "sub-menu-item--active"
            ])}
            onClick={e => {
                if (props.isInSplitPane) {
                    e.stopPropagation();
                }
                history.push(props.route);
            }}>
            {props.name}
        </div>
    );
};
