// rewrite OpenAPI BASE URL during development
import { OpenAPI as DatevApi } from "@farmact/datev-app-client";
import { environment } from "../main/config/config";

// must be equal to the value in vite.config.ts
const localDatevApiPath = "/localApi/datev";

export function setupDatevApi() {
    // set installed client version as default header for every request
    // this can be used on API side to allow or reject deprecated clients

    if (environment == "local") {
        // DatevApi.BASE = "https://dev.api.datev.farmact.de";
        DatevApi.BASE = localDatevApiPath;
    }

    if (environment === "dev" || environment === "alpha") {
        DatevApi.BASE = "https://dev.api.datev.farmact.de";
    }

    if (environment === "beta") {
        DatevApi.BASE = "https://beta.api.datev.farmact.de";
    }

    if (environment === "prod") {
        DatevApi.BASE = "https://api.datev.farmact.de";
    }
}
