import { withAuthorization } from "../../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../../authentication/authorizations";
import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { DefaultPage } from "../../structure/Page/DefaultPage/DefaultPage";

const RentalOrderDetailsPageContent = lazy(() => import("./RentalOrderDetailsPageContent"));

function RentalOrderDetailsPageInternal() {
    return (
        <DefaultPage>
            <RentalOrderDetailsPageContent />
        </DefaultPage>
    );
}

export const RentalOrderDetailsPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.PLANNER))(
    RentalOrderDetailsPageInternal
);
