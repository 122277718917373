import { Firebase } from "../../firebase";
import { useEffect, useState } from "react";

export function useCompanyId() {
    const [companyId, setCompanyId] = useState<string>();
    useEffect(() => {
        const timeout = setInterval(() => {
            const newCompanyId = Firebase.instance().getCompanyId();
            if (companyId !== newCompanyId) {
                setCompanyId(newCompanyId);
            }
        }, 500);
        return () => clearTimeout(timeout);
    }, [companyId]);
    return companyId;
}
