import { Machine } from "@farmact/model/src/model/Machine";
import { MachineCounterTrackingSource } from "@farmact/model/src/model/MachineCounterTracking";
import { v4 } from "uuid";
import { useOrganizationContext } from "../../../organization/context/OrganizationContext";
import { MachineCounterQueries } from "../MachineCounters/MachineCounterQueries/MachineCounterQueries";
import { MachineCountersGroup, MachineCounterValue } from "../MachineCounters/useMachineCounterGroups";
import "./orderMachineCountersModalContent.scss";

interface IOrderMachineCountersModalContentProps {
    machineCounters: MachineCounterValue[];
    onMachineCountersChange: (machineCounters: MachineCounterValue[]) => void;
}

export const OrderMachineCountersModalContent = (props: IOrderMachineCountersModalContentProps) => {
    const { machines } = useOrganizationContext();

    const groups = groupMachineCounters(props.machineCounters, machines);

    const handleValueChange = (machineCounterId: MachineCounterValue["id"], value: number | null) => {
        props.onMachineCountersChange(
            props.machineCounters.map(machineCounter => {
                if (machineCounter.id === machineCounterId) {
                    return {
                        ...machineCounter,
                        value,
                    };
                }

                return machineCounter;
            })
        );
    };

    return (
        <div className="order-machine-counters-modal-content">
            <p className="order-machine-counters-modal-content__hint">
                Folgende Maschinenzähler müssen beantwortet werden um fortzufahren
            </p>

            <MachineCounterQueries groups={groups} errors={[]} onChange={handleValueChange} />
        </div>
    );
};

const PRICE_TRACKING_MACHINE_COUNTER_ID = v4();

function groupMachineCounters(machineCounters: MachineCounterValue[], machines: Machine[]): MachineCountersGroup[] {
    const machinesAssoc: Record<Machine["id"], MachineCounterValue[]> = {};
    const priceTrackingMachineCounters: MachineCounterValue[] = [];

    for (const machineCounter of machineCounters) {
        if (machineCounter.source.type === MachineCounterTrackingSource.MACHINE) {
            const key = machineCounter.source.machineId;
            machinesAssoc[key] = [...(machinesAssoc[key] ?? []), machineCounter];
        } else if (machineCounter.source.type === MachineCounterTrackingSource.PRICE_BLOCK) {
            priceTrackingMachineCounters.push(machineCounter);
        }
    }

    const groups: MachineCountersGroup[] = [];

    for (const [machineId, machineCounters] of Object.entries(machinesAssoc)) {
        const machine = machines.find(machine => {
            return machine.id === machineId;
        });

        const groupName = machine?.name ?? "Unbekannte Maschine";

        groups.push({
            id: machine?.id ?? v4(),
            groupName,
            values: machineCounters,
        });
    }

    if (priceTrackingMachineCounters.length > 0) {
        groups.push({
            id: PRICE_TRACKING_MACHINE_COUNTER_ID,
            groupName: "Maschinenzähler ohne Maschine",
            values: priceTrackingMachineCounters,
        });
    }

    return groups;
}
