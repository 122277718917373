import dayjs from "dayjs";
import { Order } from "@farmact/model/src/model/Order";
import { useCallback, useContext } from "react";
import { useFormValidation } from "../../../../util/customHooks/useFormValidation";
import { useValidation } from "../../../../util/customHooks/validation/useValidation";
import { notEmptyString } from "../../../../util/validation";
import { ToastContext } from "../../../ToastContext";
import { useOrganizationContext } from "../../../organization/context/OrganizationContext";
import { OrderFormErrors } from "../orderForm/OrderForm";
import { useAppCompanyContext } from "../../../authentication/AppCompanyContext";
import { OrderStatus } from "@farmact/model/src/model/OrderStatus";
import { useOrderCustomers } from "../../../../util/customHooks/order/useOrderCustomers";

interface UseOrderValidationOptions {
    /**
     * @default false
     */
    needsEmployee?: boolean;
}

/**
 * Checks whether the order is valid. options parameter defaults to { needsEmployee: false }.
 * @param order
 * @param options @default { needsEmployee: false }
 */
export function useOrderValidation(order: Order | undefined, options: UseOrderValidationOptions = {}) {
    const { needsEmployee = false } = options;

    const { onMessage } = useContext(ToastContext);
    const { services, employees, machines, resources } = useOrganizationContext();
    const { operatingUnitsIncludingArchived } = useAppCompanyContext();

    const { customers: orderCustomers } = useOrderCustomers(order);

    const needsNoValidation =
        (order?.status === OrderStatus.BILLED || order?.status === OrderStatus.DONE) &&
        operatingUnitsIncludingArchived.find(unit => unit.id === order.operatingUnitId)?.archived;

    const formValidation = useFormValidation({
        endDate: useValidation(order?.plannedEndDateTime, endDate => {
            if (endDate && order?.plannedStartDateTime) {
                return !dayjs(endDate).isAfter(order.plannedStartDateTime) ? "invalid_end_date" : undefined;
            }
        }),
        serviceNotEmpty: useValidation(order?.serviceId, notEmptyString),
        serviceOperatingUnit: useValidation([order?.operatingUnitId, order?.serviceId], () => {
            if (!order?.operatingUnitId || !order.serviceId) {
                return;
            }
            const serviceInOrder = services.find(service => service.id === order.serviceId);
            if (!serviceInOrder) {
                return "invalid_service";
            }
            if (!serviceInOrder.operatingUnitIds.includes(order.operatingUnitId)) {
                return "invalid_operating_unit";
            }
        }),
        customerOperatingUnit: useValidation([order?.operatingUnitId, order?.customerIds], () => {
            const orderOperatingUnitId = order?.operatingUnitId;
            if (!orderOperatingUnitId) {
                return;
            }
            if (order.customerIds.length === 0) {
                return;
            }

            return orderCustomers.some(customer => !customer.operatingUnitIds.includes(orderOperatingUnitId))
                ? "invalid_operating_unit"
                : undefined;
        }),
        machinesOperatingUnit: useValidation([order?.operatingUnitId, order?.machineIds], () => {
            if (!order?.operatingUnitId) {
                return;
            }
            if (order.machineIds.length === 0) {
                return;
            }
            if (needsNoValidation) {
                return;
            }

            const usedMachines = machines.filter(machine => {
                return order.machineIds.includes(machine.id);
            });

            const machinesMatchOperatingUnit = usedMachines.every(machine => {
                return machine.operatingUnitIds.includes(order.operatingUnitId!);
            });

            return machinesMatchOperatingUnit ? undefined : "invalid_operating_unit";
        }),
        employeeOperatingUnit: useValidation([order?.operatingUnitId, order?.employeeId], () => {
            if (!order?.operatingUnitId) {
                return;
            }
            if (!order.employeeId) {
                return;
            }
            if (needsNoValidation) {
                return;
            }

            const employee = employees.find(employee => {
                return employee.id === order.employeeId;
            });
            if (!employee) {
                return "invalid_employee";
            }

            return employee.operatingUnitIds.includes(order.operatingUnitId) ? undefined : "invalid_operating_unit";
        }),
        employeeNotEmpty: useValidation([needsEmployee, order?.employeeId], () => {
            if (needsEmployee && !order?.employeeId) {
                return "no-employee-set";
            }
        }),
        resourceOperatingUnit: useValidation([order?.operatingUnitId, order?.resourceUsages], () => {
            if (!order?.operatingUnitId) {
                return;
            }
            if (order.resourceUsages.length === 0) {
                return;
            }

            const usedResourceIds = order.resourceUsages
                .filter(resourceUsage => !!resourceUsage.resourceId)
                .map(resourceUsage => resourceUsage.resourceId!);
            const usedResources = resources.filter(resource => {
                return usedResourceIds.includes(resource.id);
            });

            const resourcesMatchOperatingUnit = usedResources.every(resource => {
                return resource.operatingUnitIds.includes(order.operatingUnitId!);
            });
            return resourcesMatchOperatingUnit ? undefined : "invalid_operating_unit";
        }),
    });

    const canSubmit = useCallback(() => {
        formValidation.validateForm();

        if (needsNoValidation) {
            return true;
        }

        if (!formValidation.fields.employeeNotEmpty.validate()) {
            onMessage("Bitte gib einen Mitarbeiter ein.", "warning");
            return false;
        }
        if (!formValidation.fields.serviceNotEmpty.validate()) {
            onMessage("Bitte gib eine Dienstleistung ein.", "warning");
            return false;
        }
        if (!formValidation.fields.endDate.validate()) {
            onMessage(
                "Bitte trage den Endzeitpunkt ein. Das Enddatum darf nicht vor dem Startdatum liegen.",
                "warning"
            );
            return false;
        }
        if (!formValidation.fields.serviceOperatingUnit.validate()) {
            onMessage("Die Dienstleistung kann nicht zu dem ausgewählten Betrieb zugeordnet werden.", "warning");
            return false;
        }
        if (!formValidation.fields.customerOperatingUnit.validate()) {
            onMessage("Der Kunde kann nicht zu dem ausgewählten Betrieb zugeordnet werden.", "warning");
            return false;
        }
        if (!formValidation.fields.machinesOperatingUnit.validate()) {
            onMessage(
                "Eine oder mehrere Maschine(n) können nicht dem ausgewählten Betrieb zugeordnet werden.",
                "warning"
            );
            return false;
        }
        if (!formValidation.fields.employeeOperatingUnit.validate()) {
            onMessage("Der Mitarbeiter kann nicht zu dem ausgegwählten Betrieb zugeordnet werden.", "warning");
            return false;
        }
        if (!formValidation.fields.resourceOperatingUnit.validate()) {
            onMessage("Eine oder mehrere Artikel können nicht dem ausgewählten Betrieb zugeordnet werden.", "warning");
            return false;
        }

        return true;
    }, [formValidation, needsNoValidation, onMessage]);

    return {
        ...formValidation,
        needsNoValidation,
        canSubmit,
    };
}

export function getOrderFormErrors(validation: ReturnType<typeof useOrderValidation>): OrderFormErrors {
    return {
        service: validation.fields.serviceNotEmpty.error || validation.fields.serviceOperatingUnit.error,
        endDate: validation.fields.endDate.error,
        customer: validation.fields.customerOperatingUnit.error,
        resources: validation.fields.resourceOperatingUnit.error,
        employee: validation.fields.employeeNotEmpty.error || validation.fields.employeeOperatingUnit.error,
        machines: validation.fields.machinesOperatingUnit.error,
    };
}
