import "./stopOrderModal.scss";
import React from "react";
import { Order } from "@farmact/model/src/model/Order";
import { StopOrderPayload, useStopOrder } from "./useStopOrder";
import { ProvideStopOrderContext } from "./StopOrderContext/StopOrderContext";
import { StopOrderModalContent } from "./StopOrderModalContent/StopOrderModalContent";
import { ModalSpinner } from "../../../ModalSpinner/ModalSpinner";
import { OrderFlowReadyState } from "../orderFlowReadyState";
import { FarmActModal } from "../../../FarmActModal/FarmActModal";
import { Employee } from "@farmact/model/src/model/Employee";

export interface IStopOrderModalProps {
    isOpen: boolean;
    onDismiss: () => void;
    order: Order | undefined;
    onStop?: () => void;
    askForConfirmation: boolean;
    onClick?: React.MouseEventHandler<HTMLIonModalElement>;
    options?: {
        waitForDatabaseCommit?: boolean;
        employee?: Employee
    }
}

export const StopOrderModal = (props: IStopOrderModalProps) => {
    const stopOrder = useStopOrder(props.order);

    const handleStop = async (payload: StopOrderPayload) => {
        await stopOrder.stop(payload, props.options);
        props.onDismiss();
        props.onStop?.();
    };

    return (
        <FarmActModal isOpen={props.isOpen} onDidDismiss={props.onDismiss} onClick={props.onClick}>
            {props.order && (
                <ProvideStopOrderContext
                    enabled={props.isOpen}
                    orderId={props.order.id}
                    switch={{
                        [OrderFlowReadyState.INITIALIZING]: <ModalSpinner text="Auftrag stoppen wird vorbereitet" />,
                    }}>
                    <StopOrderModalContent
                        onDismiss={props.onDismiss}
                        onStop={handleStop}
                        askForConfirmation={props.askForConfirmation}
                    />
                </ProvideStopOrderContext>
            )}
        </FarmActModal>
    );
};
