import { Capacitor } from "@capacitor/core";
import { AppVersion } from "@farmact/model/src/model/AppMeta";
import { isPlatform } from "@ionic/react";
import { useState } from "react";
import { appVersion } from "../../../config/config";
import { appStoreLinks } from "../../constants/links";
import { Firebase } from "../../firebase";
import { useDocumentData } from "../../firebase/dataHooks";
import { computed } from "../functions";
import { openLink } from "../openLink";
import { compareVersions, getCurrentAppVersion } from "../version";

interface UseWatchNewVersionReturn {
    update: () => void;
    dismiss: () => void;
    dismissed: boolean;
    outdated: boolean;
}

export function useWatchNewVersion(): UseWatchNewVersionReturn {
    const [appMeta] = useDocumentData(Firebase.instance().getAppMeta());

    // used to force rerender
    const [, setRender] = useState(false);
    const version = appMeta ? getCurrentAppVersion(appMeta) : undefined;

    const outdated = computed((): boolean => {
        if (!version) {
            return false;
        }

        return compareVersions(appVersion, version) < 0;
    });

    const dismissed = computed((): boolean => {
        if (!version) {
            return false;
        }

        return !!localStorage.getItem(createLSKey(version));
    });

    const update = () => {
        dismiss();

        if (Capacitor.isNativePlatform()) {
            if (isPlatform("ios")) {
                return openLink(appStoreLinks.ios);
            } else if (isPlatform("android")) {
                return openLink(appStoreLinks.android);
            }

            return;
        }

        return location.reload();
    };

    const dismiss = () => {
        if (!version) {
            return;
        }

        localStorage.setItem(createLSKey(version), "true");

        // we "force" a rerender so `outdated` is reevaluated
        setRender(prev => !prev);
    };

    return {
        update,
        dismiss,
        //
        dismissed,
        outdated,
    };
}

function createLSKey(version: AppVersion) {
    const versionText = `${version.major}.${version.minor}.${version.patch}`;
    return `farmact.version.${versionText}.dismissed`;
}
