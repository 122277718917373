import { withAuthorization } from "../../../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../../../authentication/authorizations";
import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { DefaultPage } from "../../../structure/Page/DefaultPage/DefaultPage";

const BillsArchivePageContent = lazy(() => import("./BillsArchivePageContent"));

function BillsArchivePageInternal() {
    return (
        <DefaultPage>
            <BillsArchivePageContent />
        </DefaultPage>
    );
}

export const BillsArchivePage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN))(
    BillsArchivePageInternal
);
