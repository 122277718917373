import { useGeolocationForOrderTracking } from "../util/customHooks/geolocationHooks/useGeolocationForOrderTracking";
import { useGeolocationForEmployeesLocation } from "../util/customHooks/geolocationHooks/useGeolocationForEmployeesLocation";

// This component does track employees location, without causing a rerender of the whole tree
// It should be used at the top level of the application, without wrapping other components
export const LocationTracking = () => {
    useGeolocationForOrderTracking();
    useGeolocationForEmployeesLocation();
    return null;
};
