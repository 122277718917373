import { withAuthorization } from "../../../authentication/Session/withAuthorization";
import { getAuthorizationGuard } from "../../../authentication/authorizations";
import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { DefaultPage } from "../../../structure/Page/DefaultPage/DefaultPage";

const OrdersArchivePageContent = lazy(() => import("./OrdersArchivePageContent"));

function OrdersArchivePageInternal() {
    return (
        <DefaultPage>
            <OrdersArchivePageContent />
        </DefaultPage>
    );
}

export const OrdersArchivePage = withAuthorization(
    getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.PLANNER, Role.EMPLOYEE)
)(OrdersArchivePageInternal);
